import ConsultaPublica from '../views/public/ConsultaPublica.vue'
import UsuarioExterno from '../views/public/UsuarioExterno.vue'

const routes = [
    {
        path: '/public/consultapublica',
        name: 'consultapublica',
        component: ConsultaPublica,
        meta:{
            publica: true
        }
    },
    {
        path: '/public/usuarioexterno',
        name: 'usuarioexterno',
        component: UsuarioExterno,
        meta:{
            publica: true
        }
    },
]

export default routes