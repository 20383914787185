var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formDados",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"page"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTableProcessos),expression:"showTableProcessos"}]},[(_vm.loadedProcessos)?_c('Filtros',{attrs:{"ano":"","numero":"","data":"","meus_favoritos":"","processoAntigo":"","tipoprocesso":"","assunto":"","interessado":"","filterusuario":"","itemsArr":_vm.processos,"qtdFilters":4},on:{"input":_vm.applyInput,"export":_vm.applyExport,"pdf":_vm.applyPdf,"filter":_vm.applyFilter,"clear":_vm.applyClear}}):_vm._e(),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"7","lg":"8","xl":"9"}},[_c('h2',{staticClass:"mb-0 accent--text"},[_vm._v("Relacionar processos")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.processos,"loading":_vm.loading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.marcador_id",fn:function(ref){
var item = ref.item;
return [(item.marcador_id)?_c('v-icon',{attrs:{"color":(item.marcador_cor),"title":(item.marcador_nome)}},[_vm._v("mdi-tag")]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.marcador_id)?_c('v-chip',{staticClass:"white--text",attrs:{"color":(item.marcador_cor)}},[_vm._v(" "+_vm._s(item.numero)+"/"+_vm._s(item.ano)+" ")]):_vm._e(),(!item.marcador_id)?_c('v-chip',{staticClass:"black--text",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(item.numero)+"/"+_vm._s(item.ano)+" ")]):_vm._e()]}},{key:"item.situacao",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.colorSituacao(item.situacao_id)}},[_vm._v(" "+_vm._s(item.situacao)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                                    el: '#personalizar',
                                    easing: [.6, .80, .30, 1.9],
                                    duration: 2000
                                }),expression:"{\n                                    el: '#personalizar',\n                                    easing: [.6, .80, .30, 1.9],\n                                    duration: 2000\n                                }"}],attrs:{"icon":""},on:{"click":function($event){return _vm.getDocs(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-folder-open")])],1)]}}],null,true)},[_c('span',[_vm._v("Gerenciar documentos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.getProcesso(item.id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalhes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.pdfCapa(item.id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Capa do processo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.getModalRelacionar(item.id),
                                    _vm.dadosRelacionar.processo_x      = item.id,
                                    _vm.dadosRelacionar.numero          = item.numero,
                                    _vm.dadosRelacionar.ano             = item.ano,
                                    _vm.dadosRelacionar.usuario_id      = null,
                                    _vm.dadosRelacionar.processo_y      = null,
                                    _vm.dadosRelacionar.find_numero     = null,
                                    _vm.dadosRelacionar.find_ano        = null,
                                    _vm.dadosRelacionar.observacao      = null,
                                    _vm.dadosRelacionar.show            = true
                                ]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-ray-start-end")])],1)]}}],null,true)},[_c('span',[_vm._v("Relacionar")])])]}}],null,true)})],1),_c('RelacionarModalProcesso',{ref:"relacionarModalProcesso",attrs:{"registro":_vm.dadosRelacionar},on:{"relacionarProcesso":_vm.relacionarProcesso}}),_c('VerModalProcesso',{attrs:{"processo":_vm.verProcesso}}),_c('div',{attrs:{"id":"personalizar"}},[_c('PersonalizarProcesso',{attrs:{"processo":_vm.personalizarProcesso},on:{"tramitarProcessoDocs":_vm.enviarProcessoDocs,"arvoreDocs":_vm.getDocs,"limparDocs":_vm.getProcessos}})],1)],1),_c('Snackbar',{attrs:{"snackbar":_vm.snackbar}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }