<template>
  <v-form ref="formDados" lazy-validation v-model="formValid">
    <div class="page">
      <v-container fluid>
        <v-row class="mb-2">
          <v-col xs="12" sm="6" md="7" lg="8" xl="9">
            <h2 class="mb-0 accent--text">Fundamentação legal</h2>
          </v-col>
        </v-row>

        <v-row class="mb-2">
          <v-col cols="12" md="6" sm="4">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-feature-search-outline"
              label="Pesquisar"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="4">
            <v-btn
              block
              color="accent"
              @click="[zerarCampos(), (viewRegistro = true)]"
              dark
              class="mb-2"
            >
              <v-icon left>mdi-plus</v-icon>
              Novo
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" sm="4">
              <v-btn
                  block
                  color="accent"
                  @click="[xls()]"
                  dark
                  class="mb-2"
              >
                  <v-icon left>mdi-database-export</v-icon>
                  Exportar
              </v-btn>
          </v-col>
          <v-col cols="12" md="2" sm="4">
              <v-btn
                  block
                  color="accent"
                  @click="[pdf()]"
                  dark
              >
                  <v-icon left>mdi-printer</v-icon>
                  PDF
              </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="registros"
          :search="search"
          class="elevation-1"
          :loading="loading"
          loading-text="Carregando..."
          :custom-filter="this.$normalizedSearch"
        >
          <template v-slot:[`item.ativo`]="{ item }">
            <v-chip v-if="item.ativo == 1" class="green" dark>Ativo</v-chip>
            <v-chip v-else class="red" dark>Inativo</v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="[loadRegistro(item), (viewRegistro = true)]"
                >
                  <v-icon color="blue">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.ativo ? false : true"
                  icon
                  @click="[inactiveRegistro(item.id)]"
                >
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog
          v-model="viewRegistro"
          width="40%"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card>
            <v-card-title class="text-h6 grey lighten-2">
              Gerenciar fundamentação legal
              <v-spacer></v-spacer>
              <v-btn icon @click="[(viewRegistro = false)]">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="mt-2">
                <v-col cols="12" sm="10" xs="12">
                  <v-text-field
                    v-model="registro.nome"
                    label="Nome"
                    required
                    :rules="[rules.required]"
                    counter
                    maxlength="60"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" xs="12">
                  <v-checkbox
                    v-model="registro.ativo"
                    v-if="registro.id"
                    label="Ativo"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="registro.id == null"
                color="primary"
                text
                @click="[create()]"
              >
                Criar
              </v-btn>
              <v-btn v-else color="primary" text @click="[update()]">
                Atualizar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <Snackbar :snackbar="snackbar"></Snackbar>
    </div>
  </v-form>
</template>
<script>
import Snackbar from "../../components/Snackbar.vue";
export default {
  layout: "sidebar",
  components: {
    Snackbar,
  },
  data: () => ({
    snackbar: {
      show: false,
      timeout: 6000,
      align: "right",
    },
    search: "",
    loading: false,
    viewRegistro: false,
    organizacao_id: null,
    registro: {
      id: null,
      nome: null,
      ativo: true,
      organizacao_id: null,
    },
    headers: [
      { text: "Nome", value: "nome", width: "60%" },
      { text: "Situação", value: "ativo", width: "20%" },
      { text: "Ações", value: "actions", width: "20%", align: "right" },
    ],
    formValid: false,
    rules: {
      required: (value) => !!value || "O preenchimento é obrigatório",
    },
    registros: [],
  }),
  methods: {
    getAll() {
      this.loading = true;
      this.$axios
        .get(`processo/fundamentacaolegal/${this.organizacao_id}/org`)
        .then((res) => {
          this.registros = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      if (!this.$refs.formDados.validate()) return false;
      this.$axios
        .post("processo/fundamentacaolegal", this.registro)
        .then((res) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: res.data.message,
            color: "green",
          };
          this.viewRegistro = false;
          this.getAll();
        })
        .catch((e) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: e.response.data[Object.keys(e.response.data)[0]][0],
            color: "red",
          };
        });
    },
    loadRegistro(item) {
      this.registro.id = item.id;
      this.registro.nome = item.nome;
      this.registro.ativo = item.ativo;
    },
    update() {
      if (!this.$refs.formDados.validate()) return false;
      this.$axios
        .put(`processo/fundamentacaolegal/${this.registro.id}`, this.registro)
        .then((res) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: res.data.message,
            color: "green",
          };
          this.viewRegistro = false;
          this.getAll();
        })
        .catch((e) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: e.response.data[Object.keys(e.response.data)[0]][0],
            color: "red",
          };
        });
    },
    inactiveRegistro(id) {
      let con = confirm("Deseja excluir esse registro?");
      if (con) {
        this.$axios
          .patch(`processo/fundamentacaolegal/${id}`)
          .then((res) => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: res.data.message,
              color: "green",
            };
            this.getAll();
          })
          .catch((e) => {
            this.snackbar = {
              ...this.snackbar,
              show: true,
              text: e.response.data[Object.keys(e.response.data)[0]][0],
              color: "red",
            };
          });
      }
    },
    zerarCampos() {
      this.registro.id = null
      this.registro.nome = null
      this.registro.ativo = true
      this.registro.organizacao_id = this.organizacao_id
    },
    xls () {
      this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}fundamentacaolegal/export/${this.organizacao_id}`,
    {file_name: 'temp.xlsx'},
            {responseType: 'blob'}
            ).then((response) => {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', "fundamentacao.xlsx")
                document.body.appendChild(link)
                link.click()
        }).catch(e => {
            this.snackbar = {
                ...this.snackbar,
                show: true,
                text: e.response.data[Object.keys(e.response.data)[0]][0],
                color: 'red',
            }
        })
    },
    pdf () {
        this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}fundamentacaolegal/pdf/${this.organizacao_id}`,
            {file_name: 'temp.pdf'},
            {responseType: 'blob'}
            ).then((response) => {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: 'application/pdf'
                }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', "fundamentacao.pdf")
                document.body.appendChild(link)
                link.click()
        }).catch(e => {
            this.snackbar = {
                ...this.snackbar,
                show: true,
                text: e.response.data[Object.keys(e.response.data)[0]][0],
                color: 'red',
            }
        })
    },
  },
  mounted() {
    this.organizacao_id = this.$store.state.organizacao.id;
    this.getAll();
    this.$refs.formDados.validate();
  },
};
</script>
