<template>
    <div class="page">        
        <v-container fluid>
            <Filtros
                ano
                numero
                data
                meus_favoritos
                processoAntigo
                tipoprocesso
                assunto
                nivel
                interessado
                :itemsArr="processos"
                :qtdFilters="5"
                @input="applyInput"
                @export="applyExport"
                @pdf="applyPdf"
                @filter="applyFilter"
                @clear="applyClear"
                v-if="loadedProcessos"
            />
            <v-row class="mb-2">
                <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                    <h2 class="mb-0 accent--text">Processos com recebimento pendente</h2>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="processos"
                class="elevation-1"
                :loading="loading"
                loading-text="Carregando..."
            >
                <template v-slot:[`item.id`]="{ item }">
                    {{ item.numero }}/{{ item.ano }}
                </template>
                <template v-slot:[`item.dataEnvio`]="{ item }">
                    {{ $moment(item.dataEnvio).format('DD/MM/YYYY') }}
                </template>
                <template v-slot:[`item.tempo`]="{ item }">
                    {{ $moment(item.dataEnvio).startOf('minute').fromNow() }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="[getProcesso(item.id)]"
                            >
                                <v-icon color="accent">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver detalhes</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="[getProcessoModal(item.id), dadosProcesso.id = item.id, dadosProcesso.numero = item.numero, dadosProcesso.show = true]"
                            >
                                <v-icon color="accent">mdi-arrow-left</v-icon>
                            </v-btn>
                        </template>
                        <span>Resgatar processo</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <ResgatarModalProcesso :processo="dadosProcesso" @processo="resgatarProcesso"></ResgatarModalProcesso>
            <VerModalProcesso :processo="verProcesso"></VerModalProcesso>
        </v-container>
        <Snackbar :snackbar="snackbar"></Snackbar>
    </div>
</template>
<script>

import { processoMixin, filterMixin } from '../../mixins';
import ResgatarModalProcesso from '../../components/Modais/ResgatarModalProcesso.vue'
import VerModalProcesso from '../../components/Modais/VerModalProcesso.vue'
import Snackbar from '../../components/Snackbar.vue'

export default {
    name:'tramitacao-recebimento-pendente',
    mixins: [processoMixin,filterMixin],
    components: {
        Snackbar,
        VerModalProcesso,
        ResgatarModalProcesso,
    },
    data: () => ({
        loadedProcessos: false,
        verProcesso: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_atual: null,
            usuario_id: null,
            observacao: null,
            especificacao: null,
            processo_antigo: null,    
            tramitacoes: [],
            situacao_id: null,
            assunto_id: null,
            organizacao_id: null,
        },
        dadosProcesso: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: null,
            observacao: null,
            setores: [],
        },
        processos: [],
        loading: false,
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        itemsPerRow: 3,
        headers: [
            { text: 'Processo', value: 'id', width: '12%' },
            { text: 'Setor origem', value: 'setorOrigem', width: '15%' },
            { text: 'Setor destino', value: 'setorDestino', width: '15%' },
            { text: 'Tipo de processo', value: 'tipoProcesso', width: '18%' },
            { text: 'Data envio', value: 'dataEnvio', width: '10%' },
            { text: 'Tempo decorrido', value: 'tempo', width: '10%' },
            { text: 'Ações', value: 'actions', width: '25%', align: 'right' },
        ],
    }),
    watch: {
        tipoprocesso_id () {
            this.getProcessos()
        },
        assunto_id () {
            this.getProcessos()
        },
        nivel_id () {
            this.getProcessos()
        },
        situacao_id () {
            this.getProcessos()
        },
        interessado () {
            this.getProcessos()
        },
        ano () {
            this.getProcessos()
        },
    },
    methods: {
         applyInput (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.getProcessos()
        },
        applyExport (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.exportar()
        },
        applyPdf (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.pdf()
        },
        getProcessos () {
            const filters = {
                'ano': this.ano ,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.loading = true
            this.axios.post(`processo/consulta/${this.$store.state.usuario.id}/recebimentopendente`, filters).then((res) => {
                this.processos = res.data
            }).finally(() => {
                this.loading = false
                this.loadedProcessos = true;
            })
        },
        exportar () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.organizacao.id}/exportrecebimentopendente`, 
                filters,
                //{file_name: 'temp.xlsx'},
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "tramitacaopendente.xlsx")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        pdf () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/pdfrecebimentopendente`, 
                filters,
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "tramitacaopendente.pdf")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        getProcessoModal (id) {
            this.axios.get(`processo/processo/${id}`).then((res) => {
                const p = res.data[0]
                var origem
                var destino
                if (p.tramitacoes.length > 0) {
                    origem = p.tramitacoes[p.tramitacoes.length - 1].setor_id_origem
                    destino = p.tramitacoes[p.tramitacoes.length - 1].setor_id_destino
                } else {
                    origem = p.setor_id_origem
                    destino = p.setor_id_atual
                }
                this.dadosProcesso = {
                    ...this.dadosProcesso,
                    id: p.id,
                    numero: p.numero,
                    ano: p.ano,
                    setor_id_origem: origem,
                    setor_id_destino: destino,
                    usuario_id: this.$store.state.usuario.id,
                    observacao: null,
                }
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        resgatarProcesso (value) {
            this.dadosProcesso = value.processo
            this.axios.post(`processo/processo/${this.dadosProcesso.id}/resgatar`, this.dadosProcesso).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        resetProcesso () {
            this.verProcesso = {
                ...this.verProcesso,
                id: null,
                ano: null,
                setor_id_origem: null,
                setor_id_atual: null,
                usuario_id: null,
                observacao: null,
                especificacao: null,
                processo_antigo: null,
                situacao_id: null,
                assunto_id: null,
                organizacao_id: null,
                tramitacoes: []
            }
        }
    },
    mounted () {
        this.getProcessos()
    }
}
</script>