<template>
    <div>
        <v-dialog width="55%" v-model="processo.show" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    Processo {{ processo.numero }}/{{ processo.ano }}
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="[processo.show = false]"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12">
                            <v-stepper v-model="e1" elevation="0">
                                <v-stepper-header>
                                    <v-stepper-step
                                        color="accent"
                                        :complete="e1 > 1"
                                        step="1"
                                    >
                                        Origem: {{ processo.setor_id_origem }}
                                    </v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step
                                        color="accent"
                                        :complete="e1 > 1"
                                        step="2"
                                    >
                                        Atual: {{ processo.setor_id_atual }}
                                    </v-stepper-step>
                                </v-stepper-header>
                            </v-stepper>
                        </v-col>
                    </v-row>
                    <v-row v-if="processo.processosRelacionados.length > 0">
                        <v-col cols="12">
                            <v-label>Processos relacionados: </v-label>
                            <v-chip
                                v-for="item in processo.processosRelacionados"
                                :key="item.id"
                                :title="item.observacao"
                            >
                            {{ item.processo_relacionado[0].numero }}/{{ item.processo_relacionado[0].ano }}
                            </v-chip>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                    <v-row v-if="processo.processosJuntados.length > 0">
                        <v-col cols="12">
                            <v-label>Processos juntados: </v-label>
                            <v-chip
                                v-for="item in processo.processosJuntados"
                                :key="item.id"
                                :title="(item.tipo == 1 ? 'Por anexação: ' : 'Por apensação: ') + ' ' + item.observacao"
                            >
                            {{ item.processo_filho[0].numero }}/{{ item.processo_filho[0].ano }}
                            </v-chip>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-list-item one-line>
                                <v-list-item-content>
                                    <v-card-subtitle>{{ processo.especificacao }}</v-card-subtitle>
                                    <v-list-item-subtitle>Especificação</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-list-item one-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ processo.tipo }}</v-list-item-title>
                                    <v-list-item-subtitle>Tipo de processo</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">                    
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ processo.assunto }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Assunto
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ processo.interessado }}</v-list-item-title>
                                    <v-list-item-subtitle>Interessado</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ processo.nivel }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Nível de acesso
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                        <v-col cols="6">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ processo.observacao }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Observação
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ processo.processo_antigo }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Número antigo
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item two-line>
                                <v-list-item-content>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>

                    <v-timeline
                        v-if="processo.tramitacoes.length > 0"
                        align-top
                        :dense="$vuetify.breakpoint.smAndDown"
                    >
                        <v-timeline-item
                            v-for="(item, i) in processo.tramitacoes"
                            :key="i"
                            :color="colorTramitacao(item.situacao_id)"
                            :icon="iconTramitacao(item.situacao_id)"
                            fill-dot>
                            <v-card
                                :color="colorTramitacao(item.situacao_id)"
                                class="white--text"
                            >
                                <v-card-title class="text-h6 pt-2">
                                    {{ item.nome.toLowerCase() }}
                                </v-card-title>
                                <v-card-text class="white text--primary">
                                    <v-row class="pt-1">
                                        <v-col cols="12"
                                            class="pt-0 pb-0">
                                        </v-col>
 
                                        <v-col cols="12"
                                            class="pt-0 pb-0"
                                            >
                                            <v-list-item single-line>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        Origem: {{ item.setor_id_origem }}</v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        Destino: {{ item.setor_id_destino }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>

                                        <v-col cols="12" v-if="item.observacao"
                                            class="pt-0 pb-0">
                                            <v-list-item single-line>
                                                <v-list-item-content>
                                                    <v-list-item-title>Observações informadas:</v-list-item-title>
                                                    <v-card-subtitle>{{ item.observacao }}</v-card-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        
                                        <v-col cols="12"
                                            class="pt-0 pb-0">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        Por {{ item.usuario }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle>
                                                        Em {{ $moment(item.data).format('DD/MM/YYYY') }} às {{ $moment(item.data).format('HH:mm') }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>

                                        <v-col cols="12" v-if="item.anotacoes.length > 0"
                                            class="pt-0 pb-0">
                                            <v-list-item single-line>
                                                <v-list-item-content>
                                                    <v-list-item-title>Anotações registradas:</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <template v-for="i in item.anotacoes">
                                            <v-col cols="12"
                                                class="pt-0 pb-0"
                                                :key="i.index"
                                            >
                                                <v-list-item two-line>
                                                    <v-list-item-content>
                                                        <v-card-subtitle>{{ i.anotacao }}</v-card-subtitle>
                                                        <v-list-item-subtitle>
                                                            Registrada por {{ i.usuario }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            Em {{ $moment(i.data).format('DD/MM/YYYY') }} às {{ $moment(i.data).format('HH:mm') }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default{
    name: 'ver-processo',
        props:{
            processo:{
                show: Boolean,
                id: Number,
                ano: Number,
                numero: Number,
                setor_id_origem: String,
                setor_id_atual: String,
                usuario_id: String,
                observacao: String,
                tramitacoes: Array,
                processosRelacionados: Array,
                processosJuntados: Array,
                situacao: String,
                assunto: String,
                organizacao: String,
                tipodoc: String,
                nivel: String,
                interessado: String,
                especificacao: String,
                processo_antigo: String,
                usuarios: Array,
            },
        },
        data: () => ({
            e1: 2
        }),
        methods: {
            colorTramitacao (value) {
                switch (value) {
                    case 'ARQ':
                        return 'blue'
                    case 'DEV':
                        return 'blue'
                    case 'ENV':
                        return 'accent'
                    case 'RAS':
                        return 'blue'
                    case 'REC':
                        return 'green'
                    case 'RES':
                        return 'blue'
                }
            },
            iconTramitacao (value) {
                switch (value) {
                    case 'ARQ':
                        return 'mdi-file'
                    case 'DEV':
                        return 'mdi-file'
                    case 'ENV':
                        return 'mdi-arrow-right'
                    case 'RAS':
                        return 'mdi-file'
                    case 'REC':
                        return 'mdi-archive-arrow-down'
                    case 'RES':
                        return 'mdi-file'
                }
            }
        }
    }
</script>