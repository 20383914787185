"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '../store/index'
import router from '../router'

// Full config:  https://github.com/axios/axios#request-config
//axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || 'http://localhost:8000/api/v1/';
//axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || 'http://api.docs.dti.systems/api/v1/';
//axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || 'http://localhost:3001/';
axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || process.env.VUE_APP_BASE_URL;
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }, null, { synchronous: true });

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
		const {
			response: { status }
			} = error
			if (status === 401) {
				localStorage.clear()
				store.commit('DESLOGAR_USUARIO')
				router.push({ name: 'login' })
				// location.href = '/'
			}
		return Promise.reject(error);
  }
);

// eslint-disable-next-line no-unused-vars
Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
