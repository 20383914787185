var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('v-container',{attrs:{"fluid":""}},[(_vm.loadedProcessos)?_c('Filtros',{attrs:{"ano":"","numero":"","data":"","meus_favoritos":"","processoAntigo":"","tipoprocesso":"","assunto":"","nivel":"","interessado":"","itemsArr":_vm.processos,"qtdFilters":5},on:{"input":_vm.applyInput,"export":_vm.applyExport,"pdf":_vm.applyPdf,"filter":_vm.applyFilter,"clear":_vm.applyClear}}):_vm._e(),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"7","lg":"8","xl":"9"}},[_c('h2',{staticClass:"mb-0 accent--text"},[_vm._v("Processos com recebimento pendente")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.processos,"loading":_vm.loading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numero)+"/"+_vm._s(item.ano)+" ")]}},{key:"item.dataEnvio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.dataEnvio).format('DD/MM/YYYY'))+" ")]}},{key:"item.tempo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.dataEnvio).startOf('minute').fromNow())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.getProcesso(item.id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalhes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.getProcessoModal(item.id), _vm.dadosProcesso.id = item.id, _vm.dadosProcesso.numero = item.numero, _vm.dadosProcesso.show = true]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-arrow-left")])],1)]}}],null,true)},[_c('span',[_vm._v("Resgatar processo")])])]}}],null,true)}),_c('ResgatarModalProcesso',{attrs:{"processo":_vm.dadosProcesso},on:{"processo":_vm.resgatarProcesso}}),_c('VerModalProcesso',{attrs:{"processo":_vm.verProcesso}})],1),_c('Snackbar',{attrs:{"snackbar":_vm.snackbar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }