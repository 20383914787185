<template>
    <div>
        <v-dialog width="40%" v-model="processo.show" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    Arquivar processo {{ processo.numero }}/{{ processo.ano }}
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="[processo.show = false]"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col
                            cols="12"
                            sm="12"
                            xs="12"
                        >
                            <v-textarea
                                label="Observação"
                                auto-grow
                                v-model="processo.observacao"
                                hint="Escreva alguma observação para ser anexada ao arquivamento"
                                color="accent"
                                rows="1"
                                row-height="12"
                                shaped
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="accent"
                        dense
                        @click="[arquivarProcesso()]"
                    >
                        Arquivar processo
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default{
    name: 'receber-processo',
        props:{
            processo:{
                id: Number,
                numero: Number,
                ano: Number,
                setor_id_origem: String,
                setor_id_destino: String,
                usuario_id: String,
                observacao: String,
                show: Boolean,
                setores: Array,
            },
        },
        methods: {
            arquivarProcesso () {
                this.$emit('processo', {
                    processo: this.processo,
                })
                this.processo.show = false
            },
        },
    }
</script>