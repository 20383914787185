<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-container v-show="showTableProcessos">
                <Filtros
                    ano
                    numero
                    data
                    meus_favoritos
                    processoAntigo
                    tipoprocesso
                    assunto
                    interessado
                    filterusuario
                    :itemsArr="processos"
                    :qtdFilters="4"
                    @input="applyInput"
                    @export="applyExport"
                    @pdf="applyPdf"
                    @filter="applyFilter"
                    @clear="applyClear"
                    v-if="loadedProcessos"
                />
                
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Relacionar processos</h2>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="processos"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >
                    <template v-slot:[`item.marcador_id`]="{ item }">
                        <v-icon 
                            v-if="item.marcador_id"
                            :color="(item.marcador_cor)"
                            :title="(item.marcador_nome)"
                            >mdi-tag</v-icon>
                    </template>
                    
                    <template v-slot:[`item.id`]="{ item }">
                        <v-chip
                            v-if="item.marcador_id"
                            :color="(item.marcador_cor)"
                            class="white--text"
                        >
                            {{ item.numero }}/{{ item.ano }}
                        </v-chip>

                        <v-chip
                            v-if="!item.marcador_id"
                            color="white"
                            class="black--text"
                        >
                            {{ item.numero }}/{{ item.ano }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.situacao`]="{ item }">
                        <v-chip
                            :color="colorSituacao(item.situacao_id)"
                            class="white--text"
                        >
                            {{ item.situacao }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    v-scroll-to="{
                                        el: '#personalizar',
                                        easing: [.6, .80, .30, 1.9],
                                        duration: 2000
                                    }"
                                    @click="getDocs(item)"
                                >
                                    <v-icon color="accent">mdi-folder-open</v-icon>
                                </v-btn>
                            </template>
                            <span>Gerenciar documentos</span>
                        </v-tooltip>
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[getProcesso(item.id)]"
                                >
                                    <v-icon color="accent">mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver detalhes</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[pdfCapa(item.id)]"
                                >
                                    <v-icon color="accent">mdi-printer</v-icon>
                                </v-btn>
                            </template>
                            <span>Capa do processo</span>
                        </v-tooltip>
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[getModalRelacionar(item.id),
                                        dadosRelacionar.processo_x      = item.id,
                                        dadosRelacionar.numero          = item.numero,
                                        dadosRelacionar.ano             = item.ano,
                                        dadosRelacionar.usuario_id      = null,
                                        dadosRelacionar.processo_y      = null,
                                        dadosRelacionar.find_numero     = null,
                                        dadosRelacionar.find_ano        = null,
                                        dadosRelacionar.observacao      = null,
                                        dadosRelacionar.show            = true
                                    ]"
                                >
                                    <v-icon color="accent">mdi-ray-start-end</v-icon>
                                </v-btn>
                            </template>
                            <span>Relacionar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                </v-container>
                <RelacionarModalProcesso ref="relacionarModalProcesso" :registro="dadosRelacionar" @relacionarProcesso="relacionarProcesso"></RelacionarModalProcesso>
                <VerModalProcesso :processo="verProcesso"></VerModalProcesso>
                <div id="personalizar">
                    <PersonalizarProcesso :processo="personalizarProcesso" @tramitarProcessoDocs="enviarProcessoDocs" @arvoreDocs="getDocs" @limparDocs="getProcessos"></PersonalizarProcesso>
                </div>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import { processoMixin, filterMixin } from '../../mixins'
import RelacionarModalProcesso from '../../components/Modais/RelacionarModalProcesso.vue'
import VerModalProcesso from '../../components/Modais/VerModalProcesso.vue'
import PersonalizarProcesso from '../../components/PersonalizarProcesso.vue'
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'enviar-processo',
    mixins: [processoMixin, filterMixin],
    components: {
        Snackbar,
        RelacionarModalProcesso,
        PersonalizarProcesso,
        VerModalProcesso,
    },
    data: () => ({
        loadedProcessos:false,
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
        personalizarProcesso: {
            show: false,
            gerenciar: false,
            relacionar: true,
            id: null,
            docs: [],
            filename: null,
            numero: null,
            ano: null,
            nivel_id: null,
            setor_id_atual: null,
            enableEditor: null
        },
        verProcesso: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_origem: null,
            setor_id_atual: null,
            usuario_id: null,
            observacao: null,
            especificacao: null,
            processo_antigo: null,    
            tramitacoes: [],
            situacao_id: null,
            assunto_id: null,
            organizacao_id: null
        },
        dadosProcesso: {
            show: false,
            id: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: null,
            observacao: null,
            setores: null,
            usuarios: null,
            unidades: null
        },
        dadosRelacionar: {
            show: false,
            processo_x: null,
            processo_y: null,
            ano: null,
            numero: null,
            usuario_id: null
        },
        relacionar: {
            processo_x: null,
            processo_y: null,
            usuario_id: null,
            observacao: null
        },
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        loading: false,
        showTableProcessos : true,
        processos: [],
        id_atribuicao : null,
        usuarios_setor: [],
        usuario_filtro:null,
        QtdDocumentos:null,
        QtdAgrupamentos:null,
        headers: [
            { text: '', value: 'marcador_id' },
            { text: 'Processo', value: 'id', width: '10%' },
            { text: 'Setor atual', value: 'setor_atual.nome', width: '13%' },
            { text: 'Tipo de processo', value: 'tipo_processo.nome', width: '15%' },
            { text: 'Assunto', value: 'assunto.nome', width: '15%' },
            { text: 'Atribuído para', value: 'usuario_atribuido', width: '15%' },
            { text: 'Situação', value: 'situacao', width: '7%' },
            { text: 'Ações', value: 'actions', width: '40%', align: 'right' },
        ],
        processo_doc:[]
    }),
    watch: {
        tipoprocesso_id () {
            this.getProcessos()
        },
        assunto_id () {
            this.getProcessos()
        },
        nivel_id () {
            this.getProcessos()
        },
        situacao_id () {
            this.getProcessos()
        },
        interessado () {
            this.getProcessos()
        },
        ano () {
            this.getProcessos()
        },
        filterusuario () {
            this.getProcessos()
        },
    },
    methods: {
        applyInput (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.getProcessos()
        },
        applyExport (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.exportar()
        },
        applyPdf (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.pdf()
        },
        exportar () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
                'usuario_id': this.filterusuario
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/exportenviar`, 
                filters,
                //{file_name: 'temp.xlsx'},
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "processonosetor.xlsx")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        pdf () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/pdfenviar`, 
                filters,
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "processonosetor.pdf")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        getProcessos () {
            const filters = {
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
                'usuario_id': this.filterusuario
            }
            this.loading = true
            this.showTableProcessos = true;
            this.personalizarProcesso.show = false;
            this.axios.post(`processo/consulta/${this.$store.state.usuario.id}/enviar`,filters).then((res) => {
                this.processos = res.data;
            }).finally(() => {
                this.loading = false
                this.loadedProcessos = true;
            })
        },
        getProcessoUser(id){
            this.loading = true
            if(id){
                this.axios.get(`processo/consulta/${id}/atribuicao`).then((res) => {
                    this.processos = res.data;
                }).finally(() => {
                    this.loading = false
                })
            }else{
                this.getProcessos();
            }
        },
        getProcessoModal (id) {
            this.axios.get(`processo/processo/${id}`).then((res) => {
                const p = res.data[0]
                var origem
                var destino
                if (p.tramitacoes.length > 0) {
                    origem  = p.tramitacoes[p.tramitacoes.length - 1].setor_id_origem
                    destino = p.tramitacoes[p.tramitacoes.length - 1].setor_id_destino
                } else {
                    origem  = p.setor_id_origem
                    destino = p.setor_id_atual
                }
                this.dadosProcesso = {
                    ...this.dadosProcesso,
                    id: p.id,
                    numero: p.numero,
                    ano: p.ano,
                    setor_id_origem: origem,
                    setor_id_atual: destino,
                    usuario_id: this.$store.state.usuario.id,
                    observacao: null,
                }
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        getDocs(item) {
            this.processo_doc = item
            this.axios.get(`processo/documento/${item.id}/documentos`).then((res) => {
                this.personalizarProcesso.docs = []
                this.QtdDocumentos = 10;
                this.QtdAgrupamentos = parseInt(res.data.length/ this.QtdDocumentos); 
                
                if (this.QtdAgrupamentos > 0){
                    for (var p = 1; p <= this.QtdAgrupamentos; p++) {
                        let QuantidadeInicio
                        let QuantidadeFinal 
                        if(p == 1){
                            QuantidadeInicio = p
                        }else{
                            QuantidadeInicio = ((p-1) * this.QtdDocumentos) + 1    
                        }

                        QuantidadeFinal = (p * this.QtdDocumentos);
                        
                        this.personalizarProcesso.docs.push({
                            id: p,
                            nome: QuantidadeInicio +"-"+ QuantidadeFinal,
                            icon: 'mdi-folder',
                            children: []
                            },);
                    }    
                }
                
                let contadorPasta = 0;
                let contadorDoc = 0;
                for (var d = 0; d < res.data.length; d++) {
                    
                    let icon
                    let nome

                    nome = '#' + res.data[d].numero_doc 
                           + ' ' + res.data[d].tipo_documento.nome

                    if (res.data[d].excluido == 0){
                        if (res.data[d].gerado == 0){
                            icon = 'mdi-download'
                            nome += ' ' + (res.data[d].nome_doc_upload 
                                ? res.data[d].nome_doc_upload
                                : '')
                        }else
                            if (res.data[d].assinado == 1)
                                icon = 'mdi-file-sign'
                            else
                                icon = 'mdi-file-document-edit'
                    } else
                        icon = 'mdi-file-remove'

                    //nome += ' (' + res.data[d].id + ')'

                    if(this.QtdAgrupamentos > 0 && contadorPasta != this.QtdAgrupamentos){
                         contadorDoc++;

                        this.personalizarProcesso.docs[contadorPasta].children.push({
                            id: res.data[d].id,                     // codigo do documento
                            processo_id: res.data[d].processo_id,   // codigo do processo
                            nome: nome,
                            gerado: res.data[d].gerado,
                            excluido: res.data[d].excluido,
                            body: res.data[d].body,
                            titulo: res.data[d].titulo,
                            filename: res.data[d].filename,
                            icon: icon,
                            criar: 0,
                            upload: 0,
                            bloco_id: res.data[d].bloco_id,
                            tipodocumento_id:res.data[d].tipo_documento.id,    
                        })
                       
                        if(contadorDoc == this.QtdDocumentos){
                            contadorDoc = 0;
                            contadorPasta++;
                        }

                    }else{
                    
                        this.personalizarProcesso.docs.push({
                            id: res.data[d].id,                     // codigo do documento
                            processo_id: res.data[d].processo_id,   // codigo do processo
                            nome: nome,
                            gerado: res.data[d].gerado,
                            excluido: res.data[d].excluido,
                            body: res.data[d].body,
                            titulo: res.data[d].titulo,
                            filename: res.data[d].filename,
                            icon: icon,
                            criar: 0,
                            upload: 0,
                            bloco_id: res.data[d].bloco_id,
                            tipodocumento_id:res.data[d].tipo_documento.id,                    
                        })
                    }
                }
                this.personalizarProcesso.id        = item.id
                this.personalizarProcesso.numero    = item.numero
                this.personalizarProcesso.ano       = item.ano
                this.personalizarProcesso.nivel_id  = item.nivel_id
                this.personalizarProcesso.setor_id_atual  = item.setor_id_atual
                this.showTableProcessos             = false
            })
            this.personalizarProcesso.show = true
            this.personalizarProcesso.enableEditor = false
        },
        getModalRelacionar(processo_id){
            this.$refs.relacionarModalProcesso.getRelacao(processo_id)
        },
        relacionarProcesso(item){
            this.relacionar.processo_x      = item.processo.processo_x
            this.relacionar.processo_y      = item.processo.processo_y
            this.relacionar.usuario_id      = this.$store.state.usuario.id
            this.relacionar.observacao      = item.processo.observacao
            
            this.axios.post('processo/relacao', this.relacionar).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        pdfCapa(id){
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}processo/pdfcapa/`+id, 
                {file_name: 'temp.pdf'}, 
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'capa.pdf');
                    document.body.appendChild(link);
                    link.click();
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response)[0]][0],
                        color: 'red',
                }
            })
        },
        colorSituacao (value) {
            switch (value) {
                case 'ARQ':
                    return 'blue'
                case 'DEV':
                    return 'blue'
                case 'ENV':
                    return 'accent'
                case 'RAS':
                    return 'blue'
                case 'REC':
                    return 'green'
                case 'RES':
                    return 'blue'
            }
        },
        resetProcesso () {
            this.verProcesso = {
                ...this.verProcesso,
                id: null,
                ano: null,
                setor_id_origem: null,
                setor_id_atual: null,
                usuario_id: null,
                usuario_id_sugestao: null,
                observacao: null,
                especificacao: null,
                processo_antigo: null,
                situacao_id: null,
                assunto_id: null,
                organizacao_id: null,
                tramitacoes: []
            }
        }
    },
    created () {
        this.getProcessos()
    },
    mounted () {
        this.$refs.formDados.validate()
    }
}
</script>