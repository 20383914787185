import Organizacao  from '../views/gerencial/Organizacao.vue'
import Instituicao  from '../views/gerencial/Instituicao.vue'
import Unidade      from '../views/gerencial/Unidade.vue'
import Setor        from '../views/gerencial/Setor.vue'
import Usuario      from '../views/gerencial/Usuario.vue'
import Perfil       from '../views/gerencial/Perfil.vue'
import Solicitacao  from '../views/gerencial/SolicitacaoAcesso.vue'

const routes = [
    {
        path: '/gerencial/organizacao',
        name: 'organizacao',
        component: Organizacao
    },
    {
        path: '/gerencial/instituicao',
        name: 'instituicao',
        component: Instituicao
    },
    {
        path: '/gerencial/unidade',
        name: 'unidade',
        component: Unidade
    },
    {
        path: '/gerencial/setor',
        name: 'setor',
        component: Setor
    },
    {
        path: '/gerencial/usuario',
        name: 'usuario',
        component: Usuario
    },
    {
        path: '/gerencial/solicitacao',
        name: 'solicitacao',
        component: Solicitacao
    },
    {
        path: '/gerencial/perfil',
        name: 'perfil',
        component: Perfil
    },
]

export default routes