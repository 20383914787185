import AssinaturaPendente from '../views/assinatura/Pendente.vue'
import ValidarDocumento from '../views/assinatura/Validar.vue'

const routes = [
    {
        path: '/assinatura/pendente',
        name: 'assinaturapendente',
        component: AssinaturaPendente
    },
    {
        path: '/assinatura/validar',
        name: 'assinaturavalidar',
        component: ValidarDocumento
    },
]

export default routes