<template>
	<div id="app">
		<v-app>
			<Sidebar v-if="stateSidebar">
			</Sidebar>
			<v-main>
				<router-view/>
			</v-main>
		</v-app>
	</div>
</template>

<script>
import Sidebar from './components/Sidebar'
export default {
	name: 'App',
	components: {
		Sidebar,
	},
	data: () => ({
		sidebarMenu: true,
		toggleMini: false,
	}),
	computed: {
		stateSidebar: {
			get: function () {
				return Boolean(this.$store.state.token)
			},
			set: function () {
			}
		},
		mini () {
			return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
		}
	},
	watch: {
        // '$route.params': function () {
        //     if (this.$router.history.current.path != '/' && !this.stateSidebar) {
		// 		this.$router.push({ name: 'login' })
        //     }
        // }
    },
	mounted () {
		// if (localStorage.getItem('token')) {
		// 	this.$store.commit('DEFINIR_USUARIO_LOGADO', {
		// 		token: localStorage.getItem('token'),
		// 		organizacao_nome: localStorage.getItem('organizacao_nome'),
        //         usuario_id: localStorage.getItem('usuario_id')
		// 	})
		// }
		// if (this.$router.history.current.path == '/' && this.stateSidebar) {
		// 	this.$router.push({ name: 'Dashboard' })
		// } else {
		// 	this.$router.push({ name: 'login' })
		// }
	}
};
</script>
<style>
	@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
	.v-application {
		font-family: Poppins !important;
	}

</style>
