<template>
    <div class="page">
        <v-container fluid>
            <v-row
                align="center"
                justify="center"
                class="mb-2 mt-2">

                <h2 class="accent--text">Assinatura do processo</h2>

            </v-row>
            <v-row align="center"
                justify="center">
                <v-col xl="8" lg="10" md="12" sm="12" xs="12">
                    <v-layout row>
                        <v-col xl="4" lg="4" md="4" sm="12" xs="12"
                            class="text-center"
                            v-show="!$vuetify.breakpoint.xs">
                            <vue-qrcode
                                v-bind:value="qrValue"
                                width="200"
                                margin="0"
                            />
                        </v-col>
                        <v-col xl="4" lg="4" md="4" sm="6" xs="12">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nam esse quae aspernatur excepturi molestiae nihil, deserunt praesentium dolores quas veritatis? Totam dolorem adipisci dicta laboriosam quam voluptatum at consequuntur officiis!
                        </v-col>
                        <v-col xl="4" lg="4" md="4" sm="6" xs="12">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nam esse quae aspernatur excepturi molestiae nihil, deserunt praesentium dolores quas veritatis? Totam dolorem adipisci dicta laboriosam quam voluptatum at consequuntur officiis!
                        </v-col>
                    </v-layout>
                </v-col>
            </v-row>
            <v-row
                align="center"
                justify="center"
                class="mt-2">

                <v-btn
                    class="ma-2"
                    :loading="loading"
                    :disabled="loading"
                    color="accent"
                    flat
                    @click="[loading = true]"
                >
                    <v-icon
                        dark
                        left
                    >
                        mdi-draw
                    </v-icon>
                    Assinar
                </v-btn>

            </v-row>
        </v-container>
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
export default {
    name: 'assinatura',
    components:{
        VueQrcode
    },
    data: () => ({
        qrValue: "My secret value",
        loading: false
    })

}
</script>