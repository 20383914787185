<template>
    <div>
        <v-snackbar v-if="snackbar.align=='right'" right v-model="snackbar.show" :color="snackbar.color" :multi-line="snackbar.mult">
            {{ snackbar.text }}
            <v-btn text @click="snackbar.show = false">
                Fechar
            </v-btn>
        </v-snackbar>

        <v-snackbar v-else v-model="snackbar.show" :color="snackbar.color" :multi-line="snackbar.mult">
            {{ snackbar.text }}
            <v-btn text @click="snackbar.show = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </div>
</template>
<script>
    export default{
    name: 'system-snackbar',
        props:{
            snackbar:{
                color: String,
                show: Boolean,
                mult: String,
                align: String
            }
        }
    }
</script>
