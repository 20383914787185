import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import './plugins/axios'
import VueApexCharts from 'vue-apexcharts'
import VueMoment from 'vue-moment'
import moment from 'moment'
import Vuex from 'vuex'
import VueScrollTo from 'vue-scrollto'
import CKEditor from 'ckeditor4-vue'
// import exportpdf from 'ckeditor4-plugin-exportpdf'
require('moment/locale/pt-br')

Vue.use( CKEditor );
// CKEditor.plugins.addExternal(exportpdf, './node_modules/ckeditor4-plugin-exportpdf/' );
Vue.use(VueScrollTo)
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueMask)
Vue.use(VueApexCharts)
Vue.use(VueMoment, {
	moment
})

Vue.component('apexchart', VueApexCharts)

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
