<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Validar autenticidade de documento</h2>
                    </v-col>
                </v-row>

                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <v-text-field
                            v-model="code"
                            required
                            :rules="[rules.required, rules.min30]"
                            counter
                            maxlength="30"
                            hint="O código do documento possui 30 caracteres"
                            label="Código do documento"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <v-text-field
                            v-model="verify"
                            required
                            :rules="[rules.required, rules.min6]"
                            counter
                            maxlength="6"
                            hint="O verificador do documento possui 6 caracteres"
                            label="Verificador do documento"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <v-btn
                            class="mt-3 accent"
                            @click="validarAssinatura()"
                        >
                            <v-icon medium dark>
                                mdi-check
                            </v-icon> Validar documento
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <v-btn v-if="filename"
                            class="mt-3 accent"
                            @click="[download()]"
                        >
                            <v-icon medium dark>
                                mdi-printer
                            </v-icon> Download do documento
                        </v-btn>
                    </v-col>
                </v-row>

            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'Validar',
    components: {
        Snackbar
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        loading:        false,
        code:           '',
        verify:         '',
        processo_id:    null,
        filename:       null,
        formValid:      false,
        rules: {
            required:   value => !!value || 'O preenchimento é obrigatório',
            min6:       v => v.length >= 6 || 'O verificador do documento possui 6 caracteres',
            min30:      v => v.length >= 30 || 'O código do documento possui 30 caracteres',
        },
    }),
    methods: {
        validarAssinatura () {
            if (!this.$refs.formDados.validate())
                return false
            this.processo_id    = null
            this.filename       = null
            let codeVerify = this.code + this.verify
            this.axios.get(`processo/assinatura/${codeVerify}`).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: "A autenticidade do documento foi verificada e confirmada!",
                    color: 'green'
                }
                this.processo_id    = res.data[0].processo_id
                this.filename       = res.data[0].filename
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        download () {
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}documento/download/${this.processo_id}/${this.filename}`, {
                responseType: 'blob'
            }).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', this.filename)
                document.body.appendChild(link)
                link.click()
            })
        },
    },
    mounted () {
        this.code    = this.$route.query.code
        this.verify  = this.$route.query.verify
        this.$refs.formDados.validate()
    }
}
</script>
