<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div>
            <v-dialog width="60%" v-model="registro.show" :fullscreen="$vuetify.breakpoint.xsOnly">
                <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                        Juntada de processo {{ registro.numero }}/{{ registro.ano }}
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            @click="[registro.show = false]"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="relacoes"
                            v-if="formulario.listar"
                        >
                            <template v-slot:[`item.id`]="{ item }">
                                {{ item.processo_filho[0].numero }}/{{ item.processo_filho[0].ano }}
                            </template>
                            <template v-slot:[`item.tipo`]="{ item }">
                                {{ item.tipo == 1 ? "Por anexação" : "Por apensação" }}
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ $moment(item.created_at).startOf('minute').fromNow() }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[inactive(item.id)]"
                                >
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                                </template>
                                <span>Excluir</span>
                            </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="accent"
                            @click="[novo()]"
                            dense
                            v-if="formulario.listar"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            Nova juntada
                        </v-btn>
                    </v-card-actions>
                    <v-card-text>
                        <v-row v-if="formulario.localizar">
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >
                            <span>Informe o processo a ser juntado</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="formulario.localizar">
                            <v-col
                                cols="2"
                                sm="2"
                                xs="2"
                            >
                                <v-text-field
                                    v-model="registro.find_numero"
                                    label="Número"
                                    required
                                    :rules="[rules.required]"
                                    maxlength="10"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="2"
                                sm="2"
                                xs="2"
                            >
                                <v-text-field
                                    v-model="registro.find_ano"
                                    label="Ano"
                                    required
                                    :rules="[rules.required]"
                                    maxlength="4"
                                ></v-text-field>
                                <v-text-field
                                    v-model="find.id"
                                    v-if="1>2"
                                ></v-text-field>
                                
                            </v-col>
                            <v-col
                                cols="2"
                                sm="2"
                                xs="2"
                            >
                                <v-btn
                                    color="accent"
                                    dense
                                    @click="[findProcesso()]"
                                >
                                    Localizar
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row 
                            v-if="formulario.naoAchou">
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >
                            <span>O processo informado não foi encontrado!</span>
                            </v-col>
                        </v-row>
                        <v-row 
                            v-if="formulario.adicionar"
                            >
                            <v-col
                                cols="3"
                                sm="3"
                                xs="3"
                            >   
                                <v-text-field
                                    v-model="find.processo"
                                    label="Processo a ser juntado"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="9"
                                sm="9"
                                xs="9"
                            >   
                                <v-text-field
                                    v-model="find.setorAtual"
                                    label="Setor atual"
                                    readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row 
                            v-if="formulario.adicionar"
                            >
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >   
                                <v-textarea
                                    label="Especificação"
                                    auto-grow
                                    v-model="find.especificacao"
                                    color="accent"
                                    rows="3"
                                    row-height="25"
                                    readonly
                                />
                                <v-text-field
                                    v-model="find.tipoProcesso"
                                    label="Tipo de processo"
                                    readonly
                                ></v-text-field>
                                <!--
                                <v-text-field
                                    v-model="find.assunto"
                                    label="Assunto"
                                    readonly
                                ></v-text-field>
                                -->
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-row 
                            v-if="formulario.adicionar"
                            >
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >
                                <v-text-field
                                    v-model="registro.observacao"
                                    label="Observação referente a juntada dos processos"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-row 
                            v-if="formulario.adicionar"
                            >
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >
                                <v-radio-group v-model="registro.tipo" 
                                    label="Tipo de juntada">
                                    <v-radio value="1" label="Juntada por anexação"></v-radio>
                                    <v-radio value="2" label="Juntada por apensação"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="accent"
                            v-if="formulario.adicionar"
                            dense
                            @click="[novo()]"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            color="accent"
                            v-if="formulario.adicionar"
                            dense
                            @click="[juntarProcesso()]"
                        >
                            Juntar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import Snackbar from '../../components/Snackbar.vue'
    export default{
        components: {
            Snackbar,
        },
        name: 'juntar-processo',
        props:{
            registro:{
                id: Number,
                ano: Number,
                numero: Number,
                usuario_id: String,
                processo_pai: Number,
                processo_filho: String,
                tipo: String,
                observacao: String,
                show: Boolean
            },
        },
        data: () => ({
            formValid: false,
            snackbar: {
                show : false,
                timeout: 6000,
                align: 'right'
            },
            headers: [
                { text: "Processo", value: "id", width: "15%", align: "right" },
                { text: 'Juntada', value: 'tipo', width: '15%' },
                { text: 'Observação', value: 'observacao', width: '20%' },
                { text: "Usuário", value: "usuario.nome", width: "30%" },
                { text: "Data", value: "created_at", width: "10%", align: "right" },
                { text: "Ações", value: "actions", width: "10%" },
            ],
            rules: {
                required: value => !!value || 'O preenchimento é obrigatório',
            },
            find: {
                id: null,
                assunto: null,
                tipoProcesso: null,
                setorAtual: null,
                especificacao: null
            },
            formulario: {
                listar: true,
                localizar: false,
                adicionar: false,
                naoAchou: false
            },
            relacoes: []    
        }),
        methods: {
            getRelacao(processo_id) {
                this.relacoes = []
                this.formulario.listar      = true
                this.formulario.localizar   = false
                this.formulario.adicionar   = false
                this.$axios.get(`processo/juntada/${processo_id}`)
                .then((res) => {
                    this.relacoes = res.data;
                })
                .finally(() => {
                });
            },
            juntarProcesso () {
                this.registro.processo_filho = this.find.id
                if (!this.$refs.formDados.validate())
                    return false
                this.$emit('juntarProcesso', {
                    processo: this.registro,
                })
                this.registro.show = false
            },
            findProcesso() {
                if (!this.$refs.formDados.validate())
                    return false
                this.find                   = []
                this.formulario.adicionar   = false
                const filter = {
                    ano:    this.registro.find_ano,
                    numero: this.registro.find_numero
                };
                this.axios.post(`processo/consulta/${this.$store.state.organizacao.id}/consultar`, filter).then((res) => {
                    if (res.data.length > 0) {
                        this.find                   = res.data[0]
                        this.find.processo          = res.data[0].numero + '/' + res.data[0].ano
                        this.formulario.adicionar   = true
                        this.formulario.localizar   = false
                        this.formulario.naoAchou    = false
                    }else{
                        this.formulario.naoAchou    = true
                    }
                }).finally(() => {
                });
            },
            inactive (id) {
                let con = confirm('Deseja realmente excluir este registro?')
                if (con) {    
                    this.axios.patch(`processo/juntada/${id}`).then((res) => {
                        this.snackbar = {
                            ...this.snackbar,
                            show: true,
                            text: res.data.message,
                            color: 'green'
                        }
                        this.getRelacao(this.registro.processo_pai)
                    }).catch(e => {
                        this.snackbar = {
                            ...this.snackbar,
                            show: true,
                            text: e.response.data[Object.keys(e.response.data)[0]][0],
                            color: 'red',
                        }
                    })
                }
            },
            novo() {
                this.find                   = []
                this.registro.find_numero   = null
                this.registro.find_ano      = null
                this.formulario.listar      = false
                this.formulario.localizar   = true
                this.formulario.adicionar   = false
                this.formulario.naoAchou    = false
            },      
        },
        mounted () {
            this.$refs.formDados.validate()
        }
    }
</script>