<template>
    <div class="page" v-show="processo.show">
        <v-container class="mt-6">
            <v-layout row>
                <v-row class="mb-6 pl-3">
                    <h2 class="mb-0 accent--text">
                        Processo {{processo.numero}}/{{processo.ano}}
                    </h2>
                </v-row>
            </v-layout>
            <v-layout row>
                <v-row class="mb-6 pl-3">
                    <v-btn
                        v-show="processo.gerenciar"
                        color="accent"
                        class="ma-2"
                        @click="[viewCriarUploadDoc('criar')]">
                        Novo documento
                        <v-icon right dark>
                            mdi-plus-circle
                        </v-icon>
                    </v-btn>
                    <v-btn
                        v-show="processo.gerenciar"
                        color="accent"
                        class="ma-2"
                        @click="[viewCriarUploadDoc('upload')]">
                        Upload
                        <v-icon right dark>
                            mdi-cloud-upload
                        </v-icon>
                    </v-btn>
                    <v-btn
                        v-show="processo.gerenciar"
                        color="accent"
                        class="ma-2"
                        @click="[impressaoCompleta()]">
                        Impressão
                        <v-icon right dark>
                           mdi-printer
                        </v-icon>
                    </v-btn>
                    <v-btn
                        color="accent"
                        class="ma-2"
                        @click="[listarProcessos()]">
                        <v-chip color="accent" v-if="processo.rascunho">Meus rascunhos</v-chip>
                        <v-chip color="accent" v-else-if="processo.receber">Processos à receber</v-chip>
                        <v-chip color="accent" v-else-if="processo.noSetor" dark>Processos no setor</v-chip>
                        <v-chip color="accent" v-else-if="processo.relacionar" dark>Relacionar processos</v-chip>
                        <v-chip color="accent" v-else-if="processo.juntada" dark>Juntada de processos</v-chip>
                        <v-chip color="accent" v-else-if="processo.arquivo" dark>Arquivo do setor</v-chip>
                        <v-chip color="accent" v-else-if="processo.notificacoes" dark>Minhas notificações</v-chip>
                        <v-chip color="accent" v-else-if="processo.consultar" dark>Consultar processos</v-chip>
                        <v-chip color="accent" v-else-if="processo.consultaPublica" dark>Consulta pública</v-chip>
                        <v-icon right dark>
                           mdi-format-list-bulleted-square
                        </v-icon>
                    </v-btn>
                    <v-btn
                        v-show="processo.gerenciar"
                        color="accent"
                        class="ma-2"
                        @click="[tramitarProcesso()]">
                        Enviar processo
                        <v-icon right dark>
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-row>
            </v-layout>
            <v-layout row>
                <v-col xl="3" lg="3" md="4" sm="4" xs="12">
                    <v-row>
                        <v-treeview
                            style="overflow-y: scroll;"
                            v-model="tree"
                            :open="processo.docs.criar"
                            :items="processo.docs"
                            activatable
                            item-key="id"
                            return-object
                            item-text="nome"
                            open-on-click
                            @update:active="onTreeChange($event)"
                        >
                            <!-- <template slot="label" slot-scope="{ item }">
                                <a @click="onTreeChange(item)">{{ item.name }}</a>
                            </template> -->
                            <template v-slot:prepend="{ item }">
                                <v-icon>
                                    {{ item.icon }}
                                </v-icon>
                            </template>
                        </v-treeview>
                    </v-row>
                </v-col>

                <v-col v-if="processo.enableEditor" xl="9" lg="9" md="8" sm="8" xs="12">
                    <div v-show="isUpload">
                        <v-card>
                            <v-card-title class="text-h6 grey lighten-2">
                                Upload de documento
                                <v-spacer></v-spacer>
                                <v-btn
                                    icon
                                    @click="[importCancel()]"
                                    >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-stepper vertical v-model="e6">
                                <v-stepper-step
                                    color="accent"
                                    :complete="e6 > 1"
                                    step="1"
                                >
                                    Selecione o arquivo para upload
                                    <small>O arquivo deve ser no formato .pdf</small>
                                </v-stepper-step>
                                <v-stepper-content step="1">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-file-input
                                                    color="accent"
                                                    counter
                                                    prepend-icon="mdi-paperclip"
                                                    outlined
                                                    small-chips
                                                    :show-size="1000"
                                                    label="Selecione o arquivo"
                                                    accept="application/pdf"
                                                    ref="files"
                                                    v-model="files"
                                                />
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="6">
                                                <v-autocomplete
                                                    label="Tipo de documento"
                                                    :items="tipodocs"
                                                    item-text="nome"
                                                    item-value="id"
                                                    v-model="tipodoc_selected"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <v-menu
                                                    ref="dialog"
                                                    v-model="menu2"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="data_doc_upload"
                                                        label="Data do documento"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                    v-model="data_doc_upload"
                                                    @input="menu2 = false"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field
                                                    label="Número do documento"
                                                    v-model="numero_doc_upload"
                                                    type="text"
                                                    color="accent"
                                                    maxlength="30"
                                                />
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field
                                                    label="Nome na árvore"
                                                    v-model="nome_doc_upload"
                                                    type="text"
                                                    color="accent"
                                                    maxlength="30"
                                                />
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12">
                                                <v-radio-group v-model="formato_doc_upload"
                                                    label="Formato do documento">
                                                    <v-radio value="nato-digital" label="nato-digital"></v-radio>
                                                    <v-radio value="digitalizado na unidade" label="digitalizado na unidade"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>

                                        <span class="caption grey--text text--darken-1">
                                            Informe o nível de acesso
                                        </span>
                                        <v-row>
                                            <v-flex md3 sm3  v-for="item in niveis_doc" :key="item.id">
                                                <v-radio-group
                                                    v-model="nivel_id_selected"
                                                    column>
                                                    <v-radio
                                                        :label="item.nome"
                                                        :value="item.id"
                                                        hide-details
                                                    />
                                                </v-radio-group>
                                            </v-flex>
                                        </v-row>

                                        <v-btn color="accent" @click="importDocumento()">
                                            <v-icon medium dark>
                                                mdi-upload
                                            </v-icon> Realizar upload
                                        </v-btn>
                                    </v-container>
                                </v-stepper-content>
                            </v-stepper>

                        </v-card>

                    </div>

                    <!-- Enquanto o documento nao tiver sido completamento assinado, exibir na parte superior --->
                    <v-layout v-if="signatures.length > 0 && !hasSignature" column class="ml-2 mb-3 pb-6">
                        <v-row>
                            <h3 class="mb-0 pb-6 accent--text">
                                Assinatura(s) solicitada(s):
                            </h3>
                        </v-row>
                        <v-card-text v-for="(s, index) in signatures" :key="index"
                            class="pa-0 ma-0">
                            <v-alert v-if="s.assinado"
                                  color="accent"
                                  dark
                                  dense
                                  icon="mdi-check"
                            >
                                {{ s.usuario_email }} - Em {{ $moment(s.updated_at).format('DD/MM/YYYY') }} às {{ $moment(s.updated_at).format('HH:mm') }}

                            </v-alert>

                            <v-alert v-else
                                  color="error"
                                  dark
                                  dense
                                  icon="mdi-close"
                            >
                                {{ s.usuario_email }}
                            </v-alert>

                        </v-card-text>
                    </v-layout>

                    <v-layout column class="ml-2 mb-8">
                        <v-row>

                            <v-btn
                                color="secondary"
                                v-if="documento.bloco_id && !hasInactive && isAssinante && processo.gerenciar"
                                class="ml-2 primary--text"
                                large
                                @click="[signDocumento()]"
                            >
                                Assinar documento
                                <v-icon
                                    right
                                    dark
                                >
                                    mdi-draw
                                </v-icon>
                            </v-btn>

                            <v-btn
                                color="secondary"
                                v-if="isExists && !hasAssigned && !hasInactive && processo.gerenciar"
                                class="ml-2 primary--text"
                                large
                                @click="[modalSign(isExists, processo.numero+'/'+processo.ano+'-'+documento.numero_doc )]"
                            >
                                Solicitar assinatura
                                <v-icon
                                    right
                                    dark
                                >
                                    mdi-pencil-plus-outline
                                </v-icon>
                            </v-btn>

                            <v-btn
                                color="secondary"
                                v-if="isExists && hasAssigned && !hasSignature && !hasInactive && processo.gerenciar"
                                class="ml-2 primary--text"
                                large
                                @click="[inactiveAssinatura(hasAssigned)]"
                            >
                                Inativar assinatura
                                <v-icon
                                    right
                                    dark
                                >
                                    mdi-close
                                </v-icon>
                            </v-btn>

                            <v-btn
                                color="secondary"
                                v-if="isExists && (!hasAssigned || hasSignature) && processo.gerenciar"
                                class="ml-2 primary--text"
                                large
                                @click="[inactiveDocumento(isExists)]"
                            >
                                Excluir documento
                                <v-icon
                                    right
                                    dark
                                >
                                    mdi-delete
                                </v-icon>
                            </v-btn>

                        </v-row>

                    </v-layout>

                    <div v-show="isViewPdf">
                        <h2 class="mb-0 accent--text pb-6">
                            Visualizar documento: #{{this.documento.numero_doc}} {{ this.documento.tipodocumento }} - {{ this.documento.nome_doc_upload }}
                        </h2>
                        <h3 class="mb-0 accent--text pb-6" v-show="!isFileGerado">
                            Número do documento: {{ this.documento.numero_doc_upload }}
                            <br>
                            Formato do documento: {{ this.documento.origem_doc_upload }}
                            <br>
                            Data do documento: {{ $moment(this.documento.data_doc_upload).format('DD/MM/YYYY') }}
                        </h3>
                        <div>
                            <input type="number" min="1" :max="numPages" v-model.number="page" style="width: 3em"> de {{numPages}}
                            <v-btn
                                small
                                color="primary"
                                class="ml-2 white--text"
                                @click="[downloadDocumento()]">
                                <v-icon left>mdi-download</v-icon>
                                  Download do documento
                            </v-btn>
                        </div>
                        <pdfview ref="docpdf" :src="src" :page="page"  @num-pages="numPages = $event">
                        </pdfview>
                        <input type="number" min="1" :max="numPages" v-model.number="page" style="width: 3em"> de {{numPages}}

                    </div>

                    <div v-show="!isViewPdf">
                        <div>
                            <v-row class="mb-6 ml-1">
                                <h2 v-if="isExists || hasInactive" class="mb-0 accent--text">
                                    Gerenciar documento: #{{this.documento.numero_doc}} {{ this.documento.tipodocumento }}
                                </h2>
                                <h2 v-else-if="isCreate" class="mb-0 accent--text">
                                    Criar novo documento
                                </h2>
                            </v-row>
                            <v-layout v-if="hasInactive" column class="ml-2 mb-3 pb-6">
                                <v-card-text class="pa-0 ma-0">
                                  <v-alert
                                      color="accent"
                                      class="ml-6 mr-6"
                                      dark
                                      icon="mdi-alert"
                                >
                                    Este documento foi excluído!
                                </v-alert>
                                </v-card-text>
                            </v-layout>
                        </div>

                        <div v-show="isCreate || isExists || hasInactive">

                            <v-layout column class="ml-2 mb-8">

                                <v-row class="pt-2">
                                    <v-autocomplete
                                        class="ma-2 pt-1"
                                        label="Tipo de documento"
                                        :items="tipodocs"
                                        item-text="nome"
                                        item-value="id"
                                        v-model="tipodoc_selected"
                                        @change="getModelos()"
                                    />

                                    <v-btn
                                        large
                                        v-if="isCreate && !hasInactive && processo.gerenciar"
                                        color="primary"
                                        class="ml-2 white--text"
                                        @click="createDocumento()"
                                    >
                                        <v-icon left>mdi-plus</v-icon>
                                        Criar documento
                                    </v-btn>

                                     <v-btn
                                        large
                                        v-if="isExists && !this.documento.bloco_id && !hasInactive && processo.gerenciar"
                                        color="primary"
                                        class="ml-2 white--text"
                                        @click="updateDocumento()"
                                    >
                                        <v-icon left>mdi-content-save</v-icon>
                                        Atualizar documento
                                    </v-btn>
                                </v-row>

                                <v-row class="pt-2" v-if="isCreate">
                                    <v-autocomplete
                                        class="ma-2 pt-1"
                                        label="Selecione o modelo de documento"
                                        :items="modelos"
                                        item-text="titulo"
                                        item-value="body"
                                        v-model=selModelo
                                    />

                                    <v-btn
                                        large
                                        color="primary"
                                        class="ml-2 white--text"
                                        @click="setModelo(selModelo)"
                                    >
                                        <v-icon left>mdi-check</v-icon>
                                        Utilizar
                                    </v-btn>
                                </v-row>

                            </v-layout>

                            <!-- Editor de texto para cricao/edicao do documento -->
                            <v-layout column class="ml-2 mb-8">
                                <v-row>
                                    <ckeditor
                                        v-model="editorData"
                                        :config="editorConfig"
                                        :readOnly="editorConfig.editorDisabled">
                                    </ckeditor>
                                </v-row>
                            </v-layout>

                            <v-layout column class="ml-2 mb-8">
                                <span class="caption grey--text text--darken-1 pt-6">
                                    Informe o nível de acesso do documento
                                </span>

                                <v-row>
                                    <v-flex md3 sm3  v-for="item in niveis_doc" :key="item.id">
                                        <v-radio-group
                                            v-model="nivel_id_selected"
                                            column>
                                            <v-radio
                                                :label="item.nome"
                                                :value="item.id"
                                                hide-details
                                            />
                                        </v-radio-group>
                                    </v-flex>
                                </v-row>
                            </v-layout>
                        </div>
                    </div>
                </v-col>
            </v-layout>
        </v-container>
        <AssinaturaDocumento :docs="documentoAssinatura"  @criarBloc="createBlock"  ></AssinaturaDocumento>
        <Snackbar :snackbar="snackbar"></Snackbar>
    </div>
</template>
<script>

import Snackbar from '../components/Snackbar.vue'
import AssinaturaDocumento from '../components/Modais/AssinaturaModalDocumento'
import pdfview from 'vue-pdf'

export default {
    name: 'personalizar-processo',
    components: {
        Snackbar,
        AssinaturaDocumento,
        pdfview
    },
    props:{
        processo: {
            show: Boolean,
            id: Number,
            docs: Array,
            ano: Number,
            numero: Number,
            setor_id_atual: String,
            nivel_id: Number,
            enableEditor: Boolean
        }
    },
    data: () => ({
        criarDoc: {
            processo_id: null,
            tipodocumento_id: null,
            usuario_id: null,
            titulo: null,
            body: null,
            filename: null,
            criar: null,
            upload: null,
            gerado: null,
            bloco_id: null,
            nivel_id: null
        },
        e6: 1,
        docsAdd: 1,
        step: 1,
        content: 'pronto para ser editado',
        editorData: '<p>Content of the editor.</p>',
        editorConfig: {
            extraPlugins: "language,font,image2,justify,exportpdf",
            allowedContent: true,
            editorDisabled: false,
            readOnly: true,
            filebrowserImageBrowseLinkUrl:
                "https://onedrive.live.com/?id=59FFBD6D7046F78D%21105&cid=59FFBD6D7046F78D",
            font_names:
                    "Arial/Arial, Helvetica, sans-serif;" +
                    "Times New Roman/Times New Roman, Times, serif;" +
                    "Verdana",
            toolbar: [
                { name: 'document', items: [ 'Print', 'exportpdf' ] },
                { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
                { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting' ] },
                { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                { name: 'links', items: [ 'Link', 'Unlink' ] },
                { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
                { name: 'insert', items: [ 'Image', 'Table' ] },
                { name: 'tools', items: [ 'Maximize' ] },
                { name: 'editing', items: [ 'Scayt' ] }
            ],
            removePlugins: 'autoembed,embedsemantic,image2,sourcedialog,showborders',
            disallowedContent: 'img{width,height,float}',
            extraAllowedContent: 'img[width,height,align]'
        },
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        initiallyOpen: ['public'],
        files: [],
        tree: [],
        tipodocs: [],               // relacao dos tipos de documentos
        modelos: [],                // relacao dos modelos para criar um novo documento
        selModelo: null,            // modelo selecionado para criar um novo documento
        tipodoc_selected: null,     // tipo de documento selecionado
        numero_doc_upload: null,    // numero do documento do arquivo importado
        data_doc_upload: null,      // data do documento do arquivo importado
        formato_doc_upload: null,   // formato do documento do arquivo importado
        nome_doc_upload: null,      // nome do documento do arquivo importado
        nivel_id_selected: null,    // nivel de acesso do documento
        isCreate: false,            // Saber se é para criar um novo documento
        isUpload: false,            // Saber se é para fazer upload de um arquivo
        isExists: false,            // Saber se o documento já existe
        hasInactive:false,          // Tratar se o documento foi inativado
        hasAssigned: false,         // Tratar se ja existe assinatura solicitada para o documento
        hasSignature: false,        // Tratar se o documento foi completamente assinado
        signatureCode: '',          // Documento ja assinado: codigo do documento
        signatureVerify: '',        // Documento ja assinado: verificador do documento
        documentQRcode: '',         // qrcode para o documento com assinatura completa
        signatures: [],             // Listagem com o bloco de assinantes do documento
        isViewPdf: false,           // Visualização do documento (gerado ou anexado)
        isFileGerado: false,        // Saber se foi um arquivo gerado no sistema ou externo, importado
        isAssinante: false,
        src:null,
        page: null,
        numPages: null,
        documentoAssinatura:{
            show: false,
            iddoc: null,
            usuario_assinatura: null,
            numero_doc: null
        },
        documento:[],
        niveis_doc:[],
    }),
    methods: {
        password: function(updatePassword) {
			updatePassword(prompt('password is "test"'));
		},
		error: function(err) {
			console.log(err);
		},
        // Criar bloco de assinatura para o documento
        createBlock(){
            this.axios.post(`processo/assinatura/${this.documentoAssinatura.iddoc}/create`, {
                "assinantes": this.documentoAssinatura.usuario_assinatura
            }).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getDocById(this.documento.id)
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        // Exibir modal para informar quem ira assinar
        modalSign (documento_id, numero_doc){
            this.documentoAssinatura.iddoc          = documento_id
            this.documentoAssinatura.numero_doc     = numero_doc
            this.documentoAssinatura.show           = true
        },
        // Criar novo documento no processo
        createDocumento() {
            this.criarDoc = {
                titulo: 'titulo teste',
                body: this.editorData,
                usuario_id: this.$store.state.usuario.id,
                processo_id: this.processo.id,
                tipodocumento_id: this.tipodoc_selected,
                nivel_id: this.nivel_id_selected
            }
            this.$emit('sendDoc', {
                doc: this.criarDoc
            })
            this.refreshTree()
            this.processo.enableEditor = false
        },
        // Atualizar arvore de documentos do processo
        refreshTree() {
            this.$emit('arvoreDocs', this.processo)
        },
        // Tramitar processo
        tramitarProcesso() {
            this.$emit('tramitarProcessoDocs', this.processo)
        },
        // Atualizar documento do processo
        updateDocumento() {
            this.criarDoc = {
                body: this.editorData,
                usuario_id: this.$store.state.usuario.id,
                processo_id: this.processo.id,
                tipodocumento_id: this.tipodoc_selected,
                nivel_id: this.nivel_id_selected
            }
            this.axios.put(`processo/documento/${this.documento.id}`, this.criarDoc).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.refreshTree()
                this.getDocById(this.documento.id);
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        // Cancelar importacao de arquivo
        importCancel(){
            this.isCreate           = false
            this.isUpload           = false
            this.hasInactive        = false
            this.isExists           = false
        },
        // Importar documento para o processo
        importDocumento() {
            let formData = new FormData();
            formData.append('processo_id', this.processo.id);
            formData.append('tipodocumento_id', this.tipodoc_selected);
            if (this.numero_doc_upload)
                formData.append('numero_doc_upload', this.numero_doc_upload);
            if (this.data_doc_upload)
                formData.append('data_doc_upload', this.data_doc_upload);
            if (this.formato_doc_upload)
                formData.append('origem_doc_upload', this.formato_doc_upload);
            if (this.nome_doc_upload)
                formData.append('nome_doc_upload', this.nome_doc_upload);
            formData.append('usuario_id', this.$store.state.usuario.id);
            formData.append('files', this.files);
            formData.append('nivel_id', this.nivel_id_selected);

            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}documento/import`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: res.data.message,
                        color: 'green'
                    }
                    this.refreshTree()
                    this.processo.enableEditor = false
                    this.isUpload = false
                    this.files = []
                }).catch((e) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    }
                })
        },
        // Inativar documento do processo
        inactiveDocumento(id) {
            this.axios.patch(`processo/documento/${id}`).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.refreshTree()
                this.getDocById(this.documento.id)
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        // Inativar assinatura do documento
        inactiveAssinatura(id) {
            this.axios.post(`processo/assinatura/inactive`,{
                "bloco_id" : id
            }).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.signatures = [] // limpar dados de assinantes solicitados
                this.getDocById(this.documento.id)
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        // Ação de assinar o documento
        signDocumento(){
            this.axios.post(`processo/assinatura/${this.documento.id}/assinar`, {
                'bloco_id':this.documento.bloco_id,
                'usuario_email':this.$store.state.usuario.email
            }).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.refreshTree()
                this.getDocById(this.documento.id)
                this.isExists = false
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })

        },

        // obter lista dos tipos de documentos da organizacao
        getTipoDocs(){
            this.axios.get(`processo/tipodocumento/${this.$store.state.organizacao.id}/org`, {
                params: { 'ativo': 1 },
            }).then((res) => {
                this.tipodocs = res.data
            })
        },

        getModelos() {
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}modelo/modelos`,{
                params: {
                    organizacao_id: this.$store.state.organizacao.id,
                    setor_id: this.processo.setor_id_atual,
                    tipodocumento_id: this.tipodoc_selected
                }
            }).then((res) => {
                this.modelos    = res.data
            })
        },

        setModelo(texto){
            this.editorData     = texto
        },

        // retornar dados de uma documento desejado
        getDocById(id){
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}documento/${id}`).then((res) => {

                this.documento                  = res.data[0]                       // recupera payload do endpoint
                this.isExists                   = this.documento.id                 // codigo do documento para atualizar
                this.tipodoc_selected           = this.documento.tipo_documento.id  // tipo de documento registrado
                this.nivel_id_selected          = this.documento.nivel_id           // nivel de acesso do documento
                this.documento.tipodocumento    = this.documento.tipo_documento.nome

                if (this.documento.excluido === 0) {                                // Verificar se o documento foi inativado

                    this.processo.enableEditor  = true

                    // Tratar documento foi gerado na aplicacao
                    this.hasAssigned            = this.documento.bloco_id           // codigo do bloco de assinatura

                    // verificar se ja existe solicitacao de assinatura
                    if (this.documento.bloco_id){

                        this.editorConfig.editorDisabled = true                 // nao permitir editar conteudo
                        this.signatures     = this.documento.bloco.assinaturas  // guardar os assinantes deste bloco

                        //Verificando se o usuário logado é um dos assinantes
                        var assinante = this.signatures.filter( signature  => (signature.usuario_email == this.$store.state.usuario.email));
                        if( assinante.length > 0 && assinante[0]['assinado'] == 0){
                            this.isAssinante = true
                        }else{
                            this.isAssinante = false
                        }
                        // verificar se existe assinatura pendente para o bloco/documento
                        this.axios.post('subscription/blocks/SignatureMissing',
                            { "code" : this.hasAssigned}).then((resSign) => {

                            if (resSign.data.data.length === 0){
                                // documento completamente assinado. Nao existe assinatura pendente para o bloco
                                this.hasSignature       = true;
                                this.signatureCode      = this.documento.bloco.codeVerify.slice(0, 30)
                                this.signatureVerify    = this.documento.bloco.codeVerify.slice(-6)
                                this.documentQRcode     = 'http://docs.dti.systems/assinatura/validar?code=' + this.signatureCode + '&verify=' + this.signatureVerify
                            }

                        }).finally(() => {

                        })
                    } else {
                        // O documento nao foi assinado
                        if (this.processo.gerenciar) //  Se processo permite gerenciar
                            //Permitir editar conteudo
                            this.editorConfig.editorDisabled = false
                    }


                    if (this.documento.filename) {

                        // Tratar arquivo que foi feito upload
                        this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}documento/download/${this.documento.processo_id}/${this.documento.filename}`, {
                            responseType: 'blob'
                        }).then((res) => {
                            this.isViewPdf          = true;
                            this.isFileGerado       = this.documento.gerado;
                            const url               = window.URL.createObjectURL(new Blob([res.data]))
                            this.src                = url
                            this.page               = 1
                        })
                    }
                }else{
                    this.isExists       = false
                    this.hasInactive    = true
                }

            }).finally(() => {

            })
        },
        listarProcessos(){
             this.$emit('limparDocs');
        },
        downloadDocumento(){
            console.log (this.documento)
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}documento/download/`+this.documento.processo_id+`/`+this.documento.filename,
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a');
                    const filename = 'processo'
                                        +'_'+this.processo.numero
                                        +'-'+this.processo.ano
                                        +'_documento_#'+this.documento.numero_doc
                                        +'_tipo_'+this.documento.tipo_documento.nome
                                        +'.pdf'
                    console.log (filename)
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response)[0]][0],
                        color: 'red',
                }
            })
        },
        impressaoCompleta(){
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}processo/print/`+this.processo.id,
                {file_name: 'temp.pdf'},
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a');
                    const filename = 'processo'
                                        +'_'+this.processo.numero
                                        +'-'+this.processo.ano
                                        +'_impressao_completa.pdf'
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response)[0]][0],
                        color: 'red',
                }
            })
        },
        // Visualização dos formularios de upload e criação de documento
        viewCriarUploadDoc(opcao){
            this.hasInactive        = false                 // checar se o item esta ativo/inativo
            this.isCreate           = false                 // habilitar criacao de novo documento
            this.isUpload           = false                 // habilitar upload de novo arquivo
            this.isExists           = false                 // limpar documento a ser atualizado
            this.hasAssigned        = false                 // limpar documento dos assinantes solicitados
            this.hasSignature       = false                 // limpar documento se foi assinado por completo
            this.processo.enableEditor = true               // habilitar visualizacao do editor de texto
            this.editorData         = ''                    // preencher campo body
            this.editorConfig.editorDisabled = false        // permitir editar conteudo do documento
            this.tipodoc_selected   = ''                    // preencher campo tipo de documento
            this.numero_doc_upload  = ''                    // preencher campo numero_doc_upload
            this.formato_doc_upload = ''                    // preencher campo formato_doc_upload
            this.nome_doc_upload    = ''                    // preencher campo nome_doc_upload
            this.data_doc_upload    = ''                    // preencher campo data_doc_upload
            this.nivel_id_selected  = 1                     // preencher campo nivel de acesso do documento, por padrao, conforme o processo
            this.documento          = []                    // limpar dados do documento
            this.signatures         = []                    // limpar dados de assinantes
            this.signatureCode      = ''                    // codigo do documento
            this.signatureVerify    = ''                    // verificador do documento
            this.documentQRcode     = ''                    // qrcode do documento
            this.isViewPdf          = false                 // limpar dados de assinantes
            this.isAssinante        = false                 // verificar se o usuario logado esta na listagem dos assinantes

            if(opcao === 'criar'){
                // Tratar criacao de novo documento - limpar campos
                this.isCreate   = true
            }else if (opcao === 'upload') {
                // Tratar upload de novo arquivo - limpar campos
                this.isUpload   = true
            }

        },
        // Acao de clicar numa opcao da arvore de documentos
        onTreeChange(arr){
            let item                = arr[0];
            this.hasInactive        = item.excluido         // checar se o item esta ativo/inativo
            this.isCreate           = false                 // habilitar criacao de novo documento
            this.isUpload           = false                 // habilitar upload de novo arquivo
            this.isExists           = false                 // limpar documento a ser atualizado
            this.hasAssigned        = false                 // limpar documento dos assinantes solicitados
            this.hasSignature       = false                 // limpar documento se foi assinado por completo
            this.processo.enableEditor = true               // habilitar visualizacao do editor de texto
            this.editorData         = item.body             // preencher campo body
            this.tipodoc_selected   = item.tipodocumento_id // preencher campo tipo de documento
            this.nivel_id_selected  = this.processo.nivel_id // preencher campo nivel de acesso do documento, por padrao, conforme o processo
            this.documento          = []                    // limpar dados do documento
            this.signatures         = []                    // limpar dados de assinantes
            this.signatureCode      = ''                    // codigo do documento
            this.signatureVerify    = ''                    // verificador do documento
            this.documentQRcode     = ''                    // qrcode do documento
            this.isViewPdf          = false                 // limpar dados de assinantes
            this.isAssinante        = false                 // verificar se o usuario logado esta na listagem dos assinantes
            if (item.excluido === 1) {
                // Tratar visualizacao de documento inativado
                this.editorData         = item.body
                this.tipodoc_selected   = item.tipodocumento_id
                this.editorConfig.editorDisabled = true     // nao permitir editar conteudo do documento
            }else{
                if(item.criar === 1){
                    // Tratar criacao de novo documento - limpar campos
                    this.isCreate   = true
                }else if (item.upload === 1) {
                    // Tratar upload de novo arquivo - limpar campos
                    this.isUpload = true
                }else{
                    // Visualizar dados de um documento ativo para gerenciar (editar, excluir, assinar, etc)
                    this.getDocById(item.id);
                }
            }
        },
        // Realizar download do arquivo
        fileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        getNiveis () {
            this.axios.get('processo/nivel').then((res) => {
                this.niveis_doc = res.data
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
                this.step--
            })
        },
    },
    created(){
        this.getNiveis()
        this.getTipoDocs()
    }
}
</script>
