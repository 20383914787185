var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTableProcessos),expression:"showTableProcessos"}]},[(_vm.loadedProcessos)?_c('Filtros',{attrs:{"ano":"","numero":"","data":"","meus_favoritos":"","processoAntigo":"","tipoprocesso":"","assunto":"","nivel":"","interessado":"","itemsArr":_vm.processos,"qtdFilters":5},on:{"input":_vm.applyInput,"export":_vm.applyExport,"pdf":_vm.applyPdf,"filter":_vm.applyFilter,"clear":_vm.applyClear}}):_vm._e(),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"7","lg":"8","xl":"9"}},[_c('h2',{staticClass:"mb-0 accent--text"},[_vm._v("Processos arquivados no setor")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.processos,"loading":_vm.loading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.marcador_id",fn:function(ref){
var item = ref.item;
return [(item.marcador_id)?_c('v-icon',{attrs:{"color":(item.marcador_cor),"title":(item.marcador_nome)}},[_vm._v("mdi-tag")]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.marcador_id)?_c('v-chip',{staticClass:"white--text",attrs:{"color":(item.marcador_cor)}},[_vm._v(" "+_vm._s(item.numero)+"/"+_vm._s(item.ano)+" ")]):_vm._e(),(!item.marcador_id)?_c('v-chip',{staticClass:"black--text",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(item.numero)+"/"+_vm._s(item.ano)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                                    el: '#personalizar',
                                    easing: [.6, .80, .30, 1.9],
                                    duration: 2000
                                }),expression:"{\n                                    el: '#personalizar',\n                                    easing: [.6, .80, .30, 1.9],\n                                    duration: 2000\n                                }"}],attrs:{"icon":""},on:{"click":function($event){return _vm.getDocs(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-folder-open")])],1)]}}],null,true)},[_c('span',[_vm._v("Gerenciar documentos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.getProcesso(item.id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalhes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.isJuntado},on:{"click":function($event){[_vm.getProcessoModal(item.id, 'resgatar'), 
                                _vm.dadosProcessoResgatar.id = item.id, 
                                _vm.dadosProcessoResgatar.numero = item.numero, 
                                _vm.dadosProcessoResgatar.show = true
                                ]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-arrow-left")])],1)]}}],null,true)},[_c('span',[_vm._v("Resgatar processo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.isJuntado},on:{"click":function($event){[_vm.getProcessoModal(item.id, 'enviar'), 
                                _vm.dadosProcessoEnviar.id = item.id, 
                                _vm.dadosProcessoEnviar.numero = item.numero, 
                                _vm.dadosProcessoEnviar.show = true]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Enviar processo")])]),(!item.marcador_id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.getModalMarcador(item),
                                    _vm.dadosMarcador.id            = item.id,
                                    _vm.dadosMarcador.numero        = item.numero,
                                    _vm.dadosMarcador.ano           = item.ano,
                                    _vm.dadosMarcador.setor_id      = item.setor_id_atual,
                                    _vm.dadosMarcador.usuario_id    = null,
                                    _vm.dadosMarcador.marcador_id   = null,
                                    _vm.dadosMarcador.show          = true
                                ]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-tag-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Marcadores")])]):_vm._e(),(item.marcador_id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.removerMarcador(item.marcador_id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-tag-remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Remover marcador")])]):_vm._e()]}}],null,true)})],1),_c('EnviarModalProcesso',{attrs:{"processo":_vm.dadosProcessoEnviar},on:{"processo":_vm.enviarProcesso}}),_c('ResgatarModalProcesso',{attrs:{"processo":_vm.dadosProcessoResgatar},on:{"processo":_vm.resgatarProcesso}}),_c('MarcadorModalProcesso',{ref:"marcadorModalProcesso",attrs:{"processo":_vm.dadosMarcador},on:{"adicionarMarcador":_vm.adicionarMarcador}}),_c('VerModalProcesso',{attrs:{"processo":_vm.verProcesso}}),_c('div',{attrs:{"id":"personalizar"}},[_c('PersonalizarProcesso',{attrs:{"processo":_vm.personalizarProcesso},on:{"arvoreDocs":_vm.getDocs,"limparDocs":_vm.getProcessos}})],1)],1),_c('Snackbar',{attrs:{"snackbar":_vm.snackbar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }