import TipoDocumento from '../views/administrativo/TipoDocumento.vue'
import Assunto from '../views/administrativo/Assunto.vue'
import GrupoAssunto from '../views/administrativo/GrupoAssunto.vue'
import TipoProcesso from '../views/administrativo/TipoProcesso.vue'
import Modelo from '../views/administrativo/Modelo.vue'
import Marcadores from '../views/administrativo/Marcadores.vue'
import Fundamentacao from '../views/administrativo/Fundamentacao.vue'

const routes = [
    {
        path: '/documentos',
        name: 'documentos',
        component: TipoDocumento
    },
    {
        path: '/assuntos',
        name: 'assuntos',
        component: Assunto
    },
    {
        path: '/grupoassuntos',
        name: 'grupoassuntos',
        component: GrupoAssunto
    },
    {
        path: '/processos',
        name: 'processos',
        component: TipoProcesso
    },
    {
        path: '/modelos',
        name: 'modelos',
        component: Modelo
    },
    {
        path: '/marcadores',
        name: 'marcadores',
        component: Marcadores
    },
    {
        path: '/fundamentacao',
        name: 'fundamentacao',
        component: Fundamentacao
    }
]

export default routes