import Vuex from 'vuex'
import Vue from 'vue'
// import http from '@/http'

Vue.use(Vuex)

const estado = {
    token: localStorage.getItem('token') || '',
    organizacao: JSON.parse(localStorage.getItem('organizacao')) || '',
    usuario: JSON.parse(localStorage.getItem('usuario')) || '',
    setores: JSON.parse(localStorage.getItem('setores')) || '',
    expToken: JSON.parse(localStorage.getItem('expToken')) || '',
}

const mutations = {
    DEFINIR_USUARIO_LOGADO (state, { token, organizacao, usuario, setores, expToken }) {
        state.token = token
        state.organizacao = organizacao
        state.usuario = usuario
        state.setores = setores
        state.expToken = expToken
    },
    DESLOGAR_USUARIO (state) {
        state.token = null
        state.organizacao = null
        state.usuario = null
        state.setores = null
        state.expToken = null
    }
}

export default new Vuex.Store({
    state: estado,
    mutations: mutations
})