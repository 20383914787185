var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formDados",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"page"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"7","lg":"8","xl":"9"}},[_c('h2',{staticClass:"mb-0 accent--text"},[_vm._v("Organizações")])])],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"xs":"7"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-feature-search-outline","label":"Pesquisar","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.organizacoes,"search":_vm.search,"loading":_vm.loading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-chip',{staticClass:"green",attrs:{"dark":""}},[_vm._v("Ativo")]):_c('v-chip',{staticClass:"red",attrs:{"dark":""}},[_vm._v(" Inativo ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.copyUrl(1, item.id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-file-find")])],1)]}}],null,true)},[_c('span',[_vm._v("Link da consulta pública")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.copyUrl(2, item.id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-clipboard-account")])],1)]}}],null,true)},[_c('span',[_vm._v("Link do cadastro de usuário externo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.loadOrganizacao(item), _vm.viewOrganizacoes = true]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.active ? false : true,"icon":""},on:{"click":function($event){[_vm.inativar(item)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Inativar")])])]}}],null,true)}),_c('v-dialog',{attrs:{"width":"40%","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.viewOrganizacoes),callback:function ($$v) {_vm.viewOrganizacoes=$$v},expression:"viewOrganizacoes"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 grey lighten-2"},[_vm._v(" Gerenciar organização "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){[_vm.viewOrganizacoes = false]}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","xs":"12"}},[_c('v-text-field',{attrs:{"label":"Nome","required":"","rules":[_vm.rules.required],"counter":"","maxlength":"100"},model:{value:(_vm.organizacao.name),callback:function ($$v) {_vm.$set(_vm.organizacao, "name", $$v)},expression:"organizacao.name"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.organizacao.id==null)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){[_vm.create()]}}},[_vm._v(" Criar ")]):_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){[_vm.update()]}}},[_vm._v(" Atualizar ")])],1)],1)],1)],1),_c('Snackbar',{attrs:{"snackbar":_vm.snackbar}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }