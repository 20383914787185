<template>
    <div class="page">
        <v-container fluid>
            <Filtros
                tipodoc
                nivel
                numero_doc_upload
                origem_doc_upload
                data_doc_upload
                body
                :itemsArr="documentos"
                :qtdFilters="5"
                @input="applyInput"
                @filter="applyFilter"
                @clear="applyClear"
                v-if="loadedDocumentos"
            />
            <v-row class="mb-2">
                <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                    <h2 class="mb-0 accent--text">Consultar Documentos</h2>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="documentos"
                class="elevation-1"
                :loading="loading"
                loading-text="Carregando..."
            >
                <template v-slot:[`item.id`]="{ item }">
                    {{ item.processo.numero
                    }}/{{ item.processo.ano
                    }}-{{ item.numero_doc
                    }}
                </template>
                <template v-slot:[`item.processo_id`]="{ item }">
                    {{ item.processo.numero }}/{{ item.processo.ano }}
                </template>
                <template v-slot:[`item.situacao`]="{ item }">
                    <v-chip
                        :color="colorSituacao(item.excluido)"
                        class="white--text"
                    >
                        {{ item.excluido }}
                    </v-chip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="[loadDocumento(item)]"
                            >
                                <v-icon color="blue" v-if="item.gerado">
                                    mdi-file-sign
                                </v-icon>
                                <v-icon color="blue" v-else>
                                    mdi-download
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Visualizar documento</span>
                    </v-tooltip>
                </template>

            </v-data-table>

            <v-dialog width="60%" v-model="viewPDF" :fullscreen="$vuetify.breakpoint.xsOnly">
                <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                        Visualizar documento {{ bloco.numero_doc }}
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            @click="[viewPDF = false]"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <div>
                            <input type="number" min="1" :max="numPages" v-model.number="page"  style="width: 3em"> de {{numPages}}
                            <v-btn
                                small
                                color="primary"
                                class="ml-2 white--text"
                                @click="[downloadDocumento()]">
                                <v-icon left>mdi-download</v-icon>
                                  Download do documento
                            </v-btn>
                        </div>
                        <pdfview ref="docpdf" :src="src" :page="page"  @num-pages="numPages = $event">
                        </pdfview>
                        <input type="number" v-model.number="page" min="1" :max="numPages" style="width: 3em"> de {{numPages}}
                    </v-card-text>
                </v-card>
            </v-dialog>

        </v-container>
        <Snackbar :snackbar="snackbar"></Snackbar>
    </div>
</template>
<script>
import { processoMixin, filterMixin } from '../../mixins'
import Snackbar from '../../components/Snackbar.vue'
import pdfview from 'vue-pdf'

export default {
    name:'consultar-documentos',
    mixins: [
        processoMixin,
        filterMixin
    ],
    components: {
        Snackbar,
        pdfview
    },
    data: () => ({
        viewPDF: false,           // Visualizacao do documento anexado
        src: null,
        page: null,
        numPages: null,
        editorData: '',
        editorConfig: {
            extraPlugins: "language,font,image2,justify,exportpdf",
            allowedContent: true,
            readOnly: true,
            filebrowserImageBrowseLinkUrl:
                "https://onedrive.live.com/?id=59FFBD6D7046F78D%21105&cid=59FFBD6D7046F78D",
            font_names:
                    "Arial/Arial, Helvetica, sans-serif;" +
                    "Times New Roman/Times New Roman, Times, serif;" +
                    "Verdana",
            toolbar: [],
            removePlugins: 'autoembed,embedsemantic,image2,sourcedialog,showborders',
            disallowedContent: 'img{width,height,float}',
            extraAllowedContent: 'img[width,height,align]'
        },
        loadedDocumentos: false,
        bloco: {
            bloco_id:           null,
            usuario_email:      null,
        },
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        loading: false,
        documentos: [],
        documento: [],
        headers: [
            { text: 'Processo', value: 'processo_id', width: '10%' },
            { text: 'Documento', value: 'id', width: '10%' },
            { text: 'Número externo', value: 'numero_doc_upload', width: '10%' },
            { text: 'Origem', value: 'origem_doc_upload', width: '10%' },
            { text: 'Setor de criação', value: 'setor.nome', width: '25%' },
            { text: 'Tipo de documento', value: 'tipo_documento.nome', width: '25%' },
            { text: 'Nível de acesso', value: 'nivel.nome', width: '10%' },
            { text: 'Ações', value: 'actions', width: '10%', align: 'right' },
        ],
    }),
    watch: {
        tipodocumento_id () {
            this.getDocumentos()
        },
        nivel_id () {
            this.getDocumentos()
        },
    },
    methods: {
        applyInput (inputed) {
            if (inputed.numero_doc_upload) this.numero_doc_upload = inputed.numero_doc_upload
                else this.numero_doc_upload = null
            if (inputed.origem_doc_upload) this.origem_doc_upload = inputed.origem_doc_upload
                else this.origem_doc_upload = null
            if (inputed.body) this.body = inputed.body
                else this.body = null
            this.getDocumentos()
        },
        getDocumentos () {
            this.documento = []
            const filters = {
                'tipodocumento_id':     this.tipodocumento_id,
                'nivel_id':             this.nivel_id,
                'data_doc_upload':      this.data_doc_upload,
                'numero_doc_upload':    this.numero_doc_upload,
                'origem_doc_upload':    this.origem_doc_upload,
                'body':                 this.body,
            }
            this.loading = true
            this.axios.post(`processo/documento/${this.$store.state.organizacao.id}/consultar`, filters).then((res) => {
                this.documentos = res.data
            }).finally(() => {
                this.loading = false
                this.loadedDocumentos = true
            })
        },
        loadDocumento (item) {

            this.documento          = item
            this.bloco.bloco_id     = item.id
            this.bloco.numero_doc   = item.processo.numero
                                        +'/'+item.processo.ano
                                        +'-'+item.numero_doc

            // Arquivo importado para o docs
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}documento/download/${item.processo.id}/${item.filename}`, {
                responseType: 'blob'
            }).then((res) => {
                this.viewPDF= true;
                const url   =  window.URL.createObjectURL(new Blob([res.data]))
                this.src    = url
                this.page   = 1
            })
        },
        downloadDocumento(){
            this.viewPDF  = null
            this.src      = null
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}documento/download/`+this.documento.processo_id+`/`+this.documento.filename,
                {responseType: 'blob'}
                ).then((response) => {
                    const url   = URL.createObjectURL(new Blob([response.data]))
                    const link  = document.createElement('a');
                    const filename = 'processo'
                                        +'_'+this.documento.processo.numero
                                        +'-'+this.documento.processo.ano
                                        +'_documento_#'+this.documento.numero_doc
                                        +'_tipo_'+this.documento.tipo_documento.nome
                                        +'.pdf'
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response)[0]][0],
                        color: 'red',
                }
            })
        },
    },
    mounted () {
        this.getDocumentos()
    }
}
</script>
