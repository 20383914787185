<template>
  <div class="page">
    <template>
      <div cols="12" style="width: 100%" class="container-fluid pl-12 pt-6 pb-2">
        <span>Minha conta</span>
        <v-divider vertical class="mx-4" color="black" style="display: inline; opacity: 0.3"></v-divider>
        <v-icon color="primary" class="pb-1" dense>
          mdi-home-outline
        </v-icon>
        <v-icon class="pb-1 mx-2">
          mdi-chevron-right
        </v-icon>
        <span color="primary">Meus dados</span>
        <v-icon class="pb-1 mx-2">
          mdi-chevron-right
        </v-icon>
        <span color="primary">{{ textoTelaAtual }}</span>
      </div>

      <v-card
        style="float:left"
        class="pa-12 pt-0 mt-2 col-md-4 col-sm-5 col-xs-12 col-xl-3 col-lg-3"
        flat
      >
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <!--
        <v-img
          height="260"
          src="https://cdn.vuetifyjs.com/images/john.jpg"
          alt="John"
        >
        <div class="fill-height bottom-gradient"></div>
        </v-img>
        -->
        <v-card-title>Meus dados</v-card-title>

        <v-card-text>
          <v-row
            align="center"
            class="mx-0">
            <v-chip-group
              v-for="item in items"
              :key="item.title"
              @change="changeTela(item)"
            >
              <v-chip class="primary white--text" v-if="verificaTela() == item.title">{{item.title}}</v-chip>
              <v-chip class="primary--text" v-else>{{item.title}}</v-chip>
            </v-chip-group>
          </v-row>
        </v-card-text>

      </v-card>

      <v-card
              style="float:left"
              class="pa-2 pt-0 col-md-7 mt-1 col-sm-6 col-xs-12 col-xl-8 col-lg-8"
              elevation="2"
      >
        <v-form v-show="views[0].isTrue"
                ref="formEmail"
                lazy-validation
                v-model="validEmail">
          <v-container>
            <v-row v-if="dados.id==null">
              <v-col cols="2">
                <v-avatar
                    class="profile"
                    color="grey"
                    size="64"
                    tile
                >
                <!--
                  <v-img v-if="this.dados.IdPessoaFisica!=null" :src="`http://sis.macaiba.dti.systems/sis/cliente/fotos/${this.dados.IdPessoaFisica}.jpg`" @onerror="target.src='https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png'"></v-img>
                  <v-img v-else src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"></v-img>
                -->
                </v-avatar>
              </v-col>
              <v-col cols="10">
                <v-row class="mt-2 pl-2 pl-xl-0 pl-sm-6">
                  <v-btn cols="6"
                      style="text-transform: none;"
                      color="primary"
                      small
                      :loading="isSelecting"
                      elevation="2"
                      class="mr-4 ml-4 ml-sm-4 ml-xl-0"
                      @click="onPickFile"
                  >
                    {{ buttonText }}
                    <v-icon
                        right
                        dark
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </v-btn>
                  <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      id="uploader"
                      @change="onFileChanged"
                  />
                  <v-btn
                      style="text-transform: none"
                      elevation="2"
                      class="ml-sm-4 hidden-xs-only"
                      small
                      outlined
                      color="primary"
                      @click="refreshImage()"
                  >
                    <span v-if="!isTruncate">Atualizar</span>
                    <v-icon v-if="!isTruncate"
                        right
                        dense
                    >
                      mdi-sync
                    </v-icon>
                    <v-icon v-else
                        dense
                    >
                      mdi-sync
                    </v-icon>
                  </v-btn>
                </v-row>
                <v-row class="mt-6 pl-2 pl-xl-0 pl-sm-6">
                  <span class="text--secondary ml-4 ml-xl-0" style="font-size: 0.9em"> Fotos permitidas com extensão .jpg ou .jpeg </span>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-2 pb-0">
                <v-text-field
                    label="Nome completo"
                    disabled
                    v-model="dados.name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0 pb-0">
                <v-text-field
                    label="Email"
                    disabled
                    :rules="[rules.required]"
                    v-model="dados.email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dados.id==null" class="pt-0 pb-2">
              <v-btn
                  color="primary"
                  small
                  class="mr-4 ml-2"
                  :disabled="!validEmail"
                  @click="[]"
              >
                Salvar alterações
              </v-btn>
              <v-btn
                  class="ml-sm-2"
                  small
                  outlined
                  color="primary"
                  @click="[resetValidationEmail()]"
              >
                Limpar campos
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
        <v-form v-show="views[1].isTrue"
                ref="formSenha"
                lazy-validation
                v-model="validSenha">
          <v-container>
            <!--
            <v-row>
              <v-col cols="6" class="pt-2 pb-0">
                <v-text-field
                    label="Senha atual"
                    :rules="[rules.required]"
                    v-model="senhaAntiga"
                    :append-icon="show1Password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1Password = !show1Password"
                    :type="show1Password ? 'text' : 'password'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            -->
            <v-row>
              <v-col cols="6" class="pt-2 pb-0">
                <v-text-field
                    label="Nova senha"
                    required
                    placeholder="Digite a sua nova senha"
                    :rules="[rules.required, rules.min, rules.number, rules.specialCharacter]"
                    v-model="dados.password"
                    :append-icon="show2Password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show2Password = !show2Password"
                    :type="show2Password ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-2 pb-0">
                <v-text-field
                    label="Confirme sua nova senha"
                    required
                    placeholder="Confirme a sua nova senha"
                    :rules="[rules.required, rules.min, rules.number, rules.specialCharacter, passwordConfirmationRule]"
                    v-model="confirmarNovaSenha"
                    :append-icon="show3Password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show3Password = !show3Password"
                    :type="show3Password ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 pb-2">
              <v-btn
                  color="primary"
                  small
                  class="mr-4 ml-2"
                  :disabled="!validSenha"
                  @click="updateTelaSenha()"
              >
                Alterar senha
              </v-btn>
              <v-btn
                  small
                  outlined
                  color="primary"
                  @click="[resetValidationSenha()]"
              >
                Limpar campos
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </template>
    <Snackbar :snackbar="snackbar"></Snackbar>
  </div>
</template>
<script>
import Snackbar from '../components/Snackbar.vue'
export default ({
  template: '#Dashboard',
  components: {
    Snackbar
  },
  data: () => ({
    snackbar: {
      show : false,
      timeout: 6000,
      align: 'right'
    },
    textoTruncate: null,
    emailOld: null,
    cepOld: null,
    isTruncate: false,
    views: [
      {
        isTrue: true,
      },
      {
        isTrue: false,
      },
      {
        isTrue: false,
      }
    ],
    validSenha: true,
    validEmail: true,
    validInfo: true,
    isSelecting: false,
    files: null,
    textoBotaoImagem: 'Anexar imagem',
    password: null,
    textoTelaAtual: 'Configurações',
    show1Password: false,
    show2Password: false,
    show3Password: false,
    senhaAntiga: null,
    confirmarNovaSenha: '',
    items: [
      { title: 'Meus dados', icon: 'mdi-account' , hover: '', id: 0},
      { title: 'Alterar senha', icon: 'mdi-lock', hover: '', id: 1},
    ],
    rules: {
      required: value => !!value || 'Obrigatório',
      min: v => v.length >= 6 || 'Senha precisa ter pelo menos 6 caracteres',
      minCEP: v => v.length === 9 || 'CEP inválido',
      number: v => v.match(".*\\d.*") || 'Senha precisa ter pelo menos um número',
      specialCharacter: v => v.match("(?=.*?[#?!@$%^&*-])") || 'Necessário pelo menos um caractere especial',
    },
    dados: {
      id: null,
      name: null,
      password: null,
      email: null,
      selectedFile: null,
    },
  }),
  computed: {
    passwordConfirmationRule() {
      return () => (this.confirmarNovaSenha === this.dados.password) || 'Senhas não conferem'
    },
    buttonText() {
      return this.dados.selectedFile ? this.textoTruncate : this.textoBotaoImagem
    }
  },
  methods:{
    getDados () {
        this.loading = true
        this.axios.get(`gerencial/users/getbyid/${this.$store.state.usuario.id}`).then((res) => {
            this.dados = res.data                        
        }).finally(() => {
            this.loading = false
        })
    },
    update () {
        this.axios.put(`gerencial/users/updatePassword`, this.dados).then(() => {
            this.snackbar = {
                ...this.snackbar,
                show: true,
                text: 'A sua senha foi atualizada com sucesso',
                color: 'green'
            }
            this.viewRegistro = false
            this.getUsuarios()
        }).catch(e => {
            this.snackbar = {
                ...this.snackbar,
                show: true,
                text: e.response.data[Object.keys(e.response.data)[0]][0],
                color: 'red',
            }
        })
    },
    changeTela(item){
      this.textoTelaAtual = item.title
      for(var i=0;i<this.items.length;i++){
        this.items[i].hover = ''
        this.views[i].isTrue = false
      }
      this.views[item.id].isTrue = true
    },
    verificaTela(){
      return this.textoTelaAtual
    },
    //NOME E EMAIL
    validateEmail(){
      return this.$refs.formEmail.validate()
    },
    resetValidationEmail(){
      this.$refs.formEmail.resetValidation()
      this.$refs.uploader.value = null
      this.dados.selectedFile = null
      this.isTruncate = false
    },
    refreshImage(){
      // location.href = "/Conta"
    },
    /*updateTelaEmail(){
      if(this.dados.Email != this.emailOld){
        this.axios.put(`processo/users/${this.dados.IdPessoaFisica}/email/update`, this.dados).then(() => {
          this.getDados()
          this.dados.ConfirmacaoPendente = 1
        })
      }
      if(this.validateEmail()) {
        this.update('Email')
      }
    },*/
    //SENHA
    validateSenha(){
      return this.$refs.formSenha.validate()
    },
    resetValidationSenha(){
      this.dados.password     = null
      this.confirmarNovaSenha = null
      this.$refs.formSenha.resetValidation()
    },
    updateTelaSenha(){
      if(this.validateSenha()){
        this.dados.password   = this.confirmarNovaSenha
        this.update('Senha')
      }
    },
    //INFORMACOES
    validateInfo(){
      return this.$refs.formInfo.validate()
    },
    resetValidationInfo(){
      this.$refs.formInfo.resetValidation()
    },
    updateTelaInfo(){
      if(this.validateInfo()){
        this.update('Info')
      }
    },
    onPickFile() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })
      this.$refs.uploader.click()
    },
    //ATRIBUIR IMAGEM
    onFileChanged() {
      this.dados.selectedFile = this.$refs.uploader.files[0];
      if(this.dados.selectedFile.name.length > 15){
        this.textoTruncate = this.dados.selectedFile.name.substring(0, 15) + "...";
        this.isTruncate = true
      }else{
        this.textoTruncate = this.dados.selectedFile.name
        this.isTruncate = false
      }
    },
  },
  mounted() {
    this.getDados()
    this.changeTela(this.items[0])
  }
})
</script>
<style>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
  .bottom-gradient {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 72px);
  }

  .repeating-gradient {
    background-image: repeating-linear-gradient(-45deg,
                        rgba(255,0,0,.25),
                        rgba(255,0,0,.25) 5px,
                        rgba(0,0,255,.25) 5px,
                        rgba(0,0,255,.25) 10px
                      );
  }
</style>