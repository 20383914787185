<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-container v-show="showTableProcessos">
                <Filtros
                    ano
                    numero
                    data
                    meus_favoritos
                    processoAntigo
                    tipoprocesso
                    assunto
                    nivel
                    situacao
                    interessado
                    filterusuario
                    :itemsArr="processos"
                    :qtdFilters="4"
                    @input="applyInput"
                    @export="applyExport"
                    @pdf="applyPdf"
                    @filter="applyFilter"
                    @clear="applyClear"
                    v-if="loadedProcessos"
                />
                
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Processos no setor</h2>
                    </v-col>
                </v-row>
                <!-- <v-divider></v-divider>
                
                <v-row class="mt-2">
                    <v-col cols="12" sm="6" xs="12">
                            <v-select
                                    :items="usuarios_setor"
                                    item-text="name"
                                    item-value="id"
                                    v-model="usuario_filtro"
                                    label="Usuarios do setor"
                                    @change="[getProcessoUser($event)]"
                                    clearable
                            ></v-select>
                    </v-col>
                </v-row>         
                     -->
                <v-data-table
                    :headers="headers"
                    :items="processos"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >
                    <template v-slot:[`item.marcador_id`]="{ item }">
                        <v-icon 
                            v-if="item.marcador_id"
                            :color="(item.marcador_cor)"
                            :title="(item.marcador_nome)"
                            >mdi-tag</v-icon>
                    </template>
                    
                    <template v-slot:[`item.id`]="{ item }">
                        <v-chip
                            v-if="item.marcador_id"
                            :color="(item.marcador_cor)"
                            class="white--text"
                        >
                            {{ item.numero }}/{{ item.ano }}
                        </v-chip>

                        <v-chip
                            v-if="!item.marcador_id"
                            color="white"
                            class="black--text"
                        >
                            {{ item.numero }}/{{ item.ano }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.situacao`]="{ item }">
                        <v-chip
                            :color="colorSituacao(item.situacao_id)"
                            class="white--text"
                        >
                            {{ item.situacao }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    v-scroll-to="{
                                        el: '#personalizar',
                                        easing: [.6, .80, .30, 1.9],
                                        duration: 2000
                                    }"
                                    @click="getDocs(item)"
                                >
                                    <v-icon color="accent">mdi-folder-open</v-icon>
                                </v-btn>
                            </template>
                            <span>Gerenciar documentos</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.favoritos_id == null">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[followProcesso(item.id)]"
                                >
                                    <v-icon color="accent">mdi-star-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Seguir</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.favoritos_id">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[unfollowProcesso(item.favoritos_id)]"
                                >
                                    <v-icon color="accent">mdi-star</v-icon>
                                </v-btn>
                            </template>
                            <span>Não seguir</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[getProcesso(item.id)]"
                                >
                                    <v-icon color="accent">mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver detalhes</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="item.isJuntado"
                                    icon
                                    @click="[getProcessoModal(item.id), 
                                        dadosProcesso.id        = item.id, 
                                        dadosProcesso.numero    = item.numero, 
                                        dadosProcesso.setor_id_destino      = null,
                                        dadosProcesso.usuario_id_sugestao   = null,
                                        dadosProcesso.unidades  = [],
                                        dadosProcesso.setores   = [],
                                        dadosProcesso.usuarios  = [],
                                        dadosProcesso.show      = true]"
                                >
                                    <v-icon color="accent">mdi-arrow-right</v-icon>
                                </v-btn>
                            </template>
                            <span>Enviar processo</span>
                        </v-tooltip>
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="[pdfCapa(item.id)]"
                                    >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }" >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                            >
                                                <v-icon color="accent">mdi-printer</v-icon>
                                            </v-btn>
                                            <span>Capa do processo</span>
                                        </template>
                                    </v-tooltip>
                                </v-list-item>
                                <v-list-item
                                    :disabled="item.isJuntado"
                                    @click="
                                    [
                                        dadosArquivarProcesso.id = item.id,
                                        dadosArquivarProcesso.numero = item.numero,
                                        dadosArquivarProcesso.ano = item.ano,
                                        dadosArquivarProcesso.setor_id_origem = item.setor_id_origem,
                                        dadosArquivarProcesso.show = true
                                    ]">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled="item.isJuntado"
                                                icon
                                            >
                                                <v-icon color="accent">mdi-package-variant-closed</v-icon>
                                            </v-btn>
                                            <span>Arquivar processo</span>
                                        </template>
                                    </v-tooltip>            
                                </v-list-item>
                                <v-list-item
                                    @click="
                                    [
                                        anotacao.tramitacao = item.tramitacao_id,
                                        anotacao.processo_numero = item.numero,
                                        anotacao.processo_ano = item.ano,
                                        anotacao.show = true
                                    ]">
                                    <v-tooltip bottom v-if="item.situacao_id != 'RAS'">
                                        <template v-slot:activator="{ on, attrs }" v-if="item.tramitacao_id != null">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                            >
                                                <v-icon color="accent">mdi-pen</v-icon>
                                            </v-btn>
                                            <span>Registrar anotação</span>
                                        </template>
                                    </v-tooltip>            
                                </v-list-item>
                                <v-list-item
                                    v-if="!item.usuario_atribuido"
                                    @click="
                                    [getModalAtribuirUser(item),
                                        dadosAtribuirProcesso.id = item.id,
                                        dadosAtribuirProcesso.numero = item.numero,
                                        dadosAtribuirProcesso.ano = item.ano,
                                        dadosAtribuirProcesso.setor_id = item.setor_id_atual,
                                        dadosAtribuirProcesso.observacao = null,
                                        dadosAtribuirProcesso.usuario_id_atribuicao = null,
                                        dadosAtribuirProcesso.show = true
                                    ]">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }" >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                            >
                                                <v-icon color="accent">mdi-account-plus</v-icon>
                                            </v-btn>
                                            <span>Atribuir processo</span>
                                        </template>
                                    </v-tooltip>
                                </v-list-item>        
                                <v-list-item
                                    @click="[cancelarAtribuicao(item.id)]"
                                    v-if="item.usuario_atribuido"
                                    >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }" >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                            >
                                                <v-icon color="red">mdi-account-remove</v-icon>
                                            </v-btn>
                                            <span>Cancelar atribuição</span>
                                        </template>
                                    </v-tooltip>
                                </v-list-item>
                                <v-list-item
                                    v-if="!item.marcador_id"
                                    @click="[
                                    getModalMarcador(item),
                                        dadosMarcador.id            = item.id,
                                        dadosMarcador.numero        = item.numero,
                                        dadosMarcador.ano           = item.ano,
                                        dadosMarcador.setor_id      = item.setor_id_atual,
                                        dadosMarcador.usuario_id    = null,
                                        dadosMarcador.marcador_id   = null,
                                        dadosMarcador.show          = true
                                    ]"
                                    >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }" >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                            >
                                                <v-icon color="accent">mdi-tag-plus</v-icon>
                                            </v-btn>
                                            <span>Aplicar marcador</span>
                                        </template>
                                    </v-tooltip>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.marcador_id"
                                    @click="[removerMarcador(item.marcador_id)]"
                                    >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }" >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                            >
                                                <v-icon color="red">mdi-tag-remove</v-icon>
                                            </v-btn>
                                            <span>Remover marcador</span>
                                        </template>
                                    </v-tooltip>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-dialog width="40%" v-model="anotacao.show" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                            Registrar anotação - {{ anotacao.processo_numero }}/{{ anotacao.processo_ano }}
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                @click="[anotacao.show = false]"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-textarea
                                        label="Anotação"
                                        auto-grow
                                        v-model="anotacao.anotacoes"
                                        hint="Escreva alguma anotação para ficar registrada no processo"
                                        color="accent"
                                        rows="5"
                                        row-height="24"
                                        shaped
                                        required
                                        :rules="[rules.required]"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="accent"
                                dense
                                @click="[registrarAnotacao()]"
                            >
                                Registrar anotação
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                </v-container>
                <AtribuirModalProcesso ref="atribuirModalProcesso" :processo="dadosAtribuirProcesso" @processoAtribuir="atribuirProcesso"></AtribuirModalProcesso>
                <ArquivarModalProcesso :processo="dadosArquivarProcesso" @processo="arquivarProcesso"></ArquivarModalProcesso>
                <EnviarModalProcesso :processo="dadosProcesso" @processo="enviarProcesso"></EnviarModalProcesso>
                <MarcadorModalProcesso ref="marcadorModalProcesso" :processo="dadosMarcador" @adicionarMarcador="adicionarMarcador"></MarcadorModalProcesso>
                <VerModalProcesso :processo="verProcesso"></VerModalProcesso>
            
                <div id="personalizar">
                    <PersonalizarProcesso :processo="personalizarProcesso" @tramitarProcessoDocs="enviarProcessoDocs" @arvoreDocs="getDocs" @limparDocs="getProcessos"  @sendDoc="createDoc"></PersonalizarProcesso>
                </div>

            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import { processoMixin, filterMixin } from '../../mixins'
import AtribuirModalProcesso from '../../components/Modais/AtribuirModalProcesso.vue'
import MarcadorModalProcesso from '../../components/Modais/MarcadorModalProcesso.vue'
import ArquivarModalProcesso from '../../components/Modais/ArquivarModalProcesso.vue'
import EnviarModalProcesso from '../../components/Modais/EnviarModalProcesso.vue'
import VerModalProcesso from '../../components/Modais/VerModalProcesso.vue'
import PersonalizarProcesso from '../../components/PersonalizarProcesso.vue'
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'enviar-processo',
    mixins: [processoMixin, filterMixin],
    components: {
        Snackbar,
        AtribuirModalProcesso,
        MarcadorModalProcesso,
        ArquivarModalProcesso,
        EnviarModalProcesso,
        PersonalizarProcesso,
        VerModalProcesso,
    },
    data: () => ({
        loadedProcessos:false,
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
        anotacao: {
            show: false,
            processo_numero: null,
            processo_ano: null,
            tramitacao: null,
            usuario_id: null,
            anotacoes: null,
        },
        personalizarProcesso: {
            show: false,
            gerenciar: true,
            noSetor: true,
            id: null,
            docs: [],
            filename: null,
            numero: null,
            ano: null,
            nivel_id: null,
            setor_id_atual: null,
            enableEditor: null
        },
        verProcesso: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_origem: null,
            setor_id_atual: null,
            usuario_id: null,
            observacao: null,
            especificacao: null,
            processo_antigo: null,    
            tramitacoes: [],
            situacao_id: null,
            assunto_id: null,
            organizacao_id: null
        },
        dadosProcesso: {
            show: false,
            id: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: null,
            observacao: null,
            setores: null,
            usuarios: null,
            unidades: null
        },
        dadosAtribuirProcesso: {
            show: false,
            id: null,
            ano: null,
            usuario_id_atribuicao: null,
            usuario_id_registro: null,
            setor_id: null,
            observacao: null,
            setores: [],
            usuarios: []
        },
        dadosMarcador: {
            show: false,
            id: null,
            ano: null,
            usuario_id: null,
            marcador_id: null,
            setor_id: null
        },
        dadosArquivarProcesso: {
            show: false,
            id: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: null,
            observacao: null,
            setores: [],
        },
        favorito: {
            processo_id: null,
            usuario_id: null,
        },
        marcador: {
            processo_id: null,
            marcador_id: null,
            setor_id: null,
            usuario_id: null,
        },
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        loading: false,
        showTableProcessos : true,
        processos: [],
        id_atribuicao : null,
        usuarios_setor: [],
        usuario_filtro:null,
        QtdDocumentos:null,
        QtdAgrupamentos:null,
        headers: [
            { text: '', value: 'marcador_id' },
            { text: 'Processo', value: 'id', width: '10%' },
            { text: 'Setor atual', value: 'setor_atual.nome', width: '13%' },
            { text: 'Tipo de processo', value: 'tipo_processo.nome', width: '15%' },
            { text: 'Assunto', value: 'assunto.nome', width: '15%' },
            { text: 'Atribuído para', value: 'usuario_atribuido', width: '15%' },
            { text: 'Situação', value: 'situacao', width: '7%' },
            { text: 'Ações', value: 'actions', width: '40%', align: 'right' },
        ],
        processo_doc:[]
    }),
    watch: {
        tipoprocesso_id () {
            this.getProcessos()
        },
        assunto_id () {
            this.getProcessos()
        },
        nivel_id () {
            this.getProcessos()
        },
        situacao_id () {
            this.getProcessos()
        },
        interessado () {
            this.getProcessos()
        },
        ano () {
            this.getProcessos()
        },
        filterusuario () {
            this.getProcessos()
        },
    },
    methods: {
        applyInput (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.getProcessos()
        },
        applyExport (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.exportar()
        },
        applyPdf (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.pdf()
        },
        exportar () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
                'usuario_id': this.filterusuario
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/exportenviar`, 
                filters,
                //{file_name: 'temp.xlsx'},
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "processonosetor.xlsx")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        pdf () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/pdfenviar`, 
                filters,
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "processonosetor.pdf")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        getProcessos () {
            const filters = {
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
                'usuario_id': this.filterusuario
            }
            this.loading = true
            this.showTableProcessos = true;
            this.personalizarProcesso.show = false;
            this.axios.post(`processo/consulta/${this.$store.state.usuario.id}/enviar`,filters).then((res) => {
                this.processos = res.data;
            }).finally(() => {
                this.loading = false
                this.loadedProcessos = true;
            })
        },
        getProcessoUser(id){
            this.loading = true
            if(id){
                this.axios.get(`processo/consulta/${id}/atribuicao`).then((res) => {
                    this.processos = res.data;
                }).finally(() => {
                    this.loading = false
                })
            }else{
                this.getProcessos();
            }
        },
        enviarProcessoDocs(item){
            this.dadosProcesso.setor_id_destino = null
            this.dadosProcesso.unidades = []
            this.dadosProcesso.show = true
            this.getProcessoModal(item.id);
        },
        getProcessoModal (id) {
            this.axios.get(`processo/processo/${id}`).then((res) => {
                const p = res.data[0]
                var origem
                var destino
                if (p.tramitacoes.length > 0) {
                    origem  = p.tramitacoes[p.tramitacoes.length - 1].setor_id_origem
                    destino = p.tramitacoes[p.tramitacoes.length - 1].setor_id_destino
                } else {
                    origem  = p.setor_id_origem
                    destino = p.setor_id_atual
                }
                this.dadosProcesso = {
                    ...this.dadosProcesso,
                    id: p.id,
                    numero: p.numero,
                    ano: p.ano,
                    setor_id_origem: origem,
                    setor_id_atual: destino,
                    usuario_id: this.$store.state.usuario.id,
                    observacao: null,
                }
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        arquivarProcesso (value) {
            this.dadosArquivarProcesso = value.processo
            this.dadosArquivarProcesso.usuario_id = this.$store.state.usuario.id
            this.axios.post(`processo/processo/${this.dadosArquivarProcesso.id}/arquivar`, this.dadosArquivarProcesso).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        atribuirProcesso (value) {
            this.dadosAtribuirProcesso = value.processo
            
            const dadosUsuario = {
                "setor_id" : this.dadosAtribuirProcesso.setor_id, 
                "usuario_id_atribuicao": this.dadosAtribuirProcesso.usuario_id_atribuicao,
                "usuario_id_registro": this.$store.state.usuario.id,
                "observacao": this.dadosAtribuirProcesso.observacao,
            }

            this.axios.post(`processo/processo/${this.dadosAtribuirProcesso.id}/atribuir`,dadosUsuario).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        async getDocs(item) {
            await this.axios.get(`processo/processo/${item.id}`).then((res) => {
                item = res.data[0]
            })

            this.personalizarProcesso.docs  = []

            let qtdProcessos = item.arvore.length;
            
            for (var p = 0; p < qtdProcessos; p++){
                
                let processo = item.arvore[p]
                
                this.personalizarProcesso.docs.push({
                    id: p,
                    nome: processo.numero+'/'+processo.ano,
                    icon: 'mdi-folder',
                    children: []    
                })

                let qtdDocumentos = processo.documentos.length;

                for (var d = 0; d < qtdDocumentos; d++){

                    let documento = processo.documentos[d]

                    let icon
                    let nome = '#' + documento.numero_doc 
                        + ' ' + documento.tipo_documento.nome

                    if (documento.nome_doc_upload)
                        nome = nome + ' - ' + documento.nome_doc_upload

                    if (documento.excluido == 0){
                        if (documento.gerado == 0)
                            icon = 'mdi-download'
                        else
                            if (documento.assinado == 1)
                                icon = 'mdi-file-sign'
                            else
                                icon = 'mdi-file-document-edit'
                    } else
                        icon = 'mdi-file-remove'
                    
                    this.personalizarProcesso.docs[p].children.push({
                        id:                 documento.id,                   
                        processo_id:        documento.processo_id,
                        nome:               nome,  
                        gerado:             documento.gerado,
                        excluido:           documento.excluido,
                        body:               documento.body,
                        titulo:             documento.titulo,
                        filename:           documento.filename,
                        icon:               icon,
                        criar:              0,
                        upload:             0,
                        bloco_id:           documento.bloco_id,
                        tipodocumento_id:   documento.tipo_documento.id,                    
                    })
                }
            }
            this.personalizarProcesso.id            = item.id
            this.personalizarProcesso.numero        = item.numero
            this.personalizarProcesso.ano           = item.ano
            this.personalizarProcesso.nivel_id      = item.nivel_id
            this.showTableProcessos                 = false
            this.personalizarProcesso.show          = true
            this.personalizarProcesso.enableEditor  = false
        },
        createDoc (value) {
            this.axios.post('processo/documento/create', value.doc).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getDocs(this.processo_doc)
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        getModalAtribuirUser(value){
            this.$refs.atribuirModalProcesso.getUserSetor(value)
        },
        getModalMarcador(value){
            this.$refs.marcadorModalProcesso.getMarcadoresSetor(value)
        },
        async cancelarAtribuicao(id){
            let con = confirm('Deseja cancelar a atribuição?')
            if (con) {
                await this.axios.get(`processo/processo/${id}`).then((res) => {
                    const atribuicoes = res.data[0].atribuicoes
                    
                    for (var i = 0; i < atribuicoes.length; i++) {
                        if(atribuicoes[i].ativo == 1){
                            this.id_atribuicao = atribuicoes[i].id 
                        }
                    }
                    }).catch(e => {
                            this.snackbar = {
                                ...this.snackbar,
                                show: true,
                                text: e.response.data[Object.keys(e.response.data)[0]][0],
                                color: 'red',
                            }
                    })
            
                await this.axios.post(`processo/processo/${id}/atribuir/${this.id_atribuicao}/cancelar`).then((res) => {
                    this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                    }
                                    
                }).catch(e => {
                    this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
                }) 
                await this.getProcessos();   

            }
        },
        followProcesso(processo_id){
            this.favorito.processo_id   = processo_id
            this.favorito.usuario_id    = this.$store.state.usuario.id
            this.axios.post('processo/favoritos', this.favorito).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        unfollowProcesso(id){
            this.axios.patch('processo/favoritos/'+id).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        adicionarMarcador(item){
            this.marcador.processo_id   = item.processo.id
            this.marcador.marcador_id   = item.processo.marcador_id
            this.marcador.setor_id      = item.processo.setor_id
            this.marcador.usuario_id    = this.$store.state.usuario.id
            this.axios.post('processo/processomarcador', this.marcador).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        removerMarcador(id){
            if (confirm('Deseja remover o marcador do processo?')) {
                this.axios.patch('processo/processomarcador/'+id).then((res) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: res.data.message,
                        color: 'green'
                    }
                    this.getProcessos()
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    }
                })
            }
        },
        pdfCapa(id){
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}processo/pdfcapa/`+id, 
                {file_name: 'temp.pdf'}, 
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'capa.pdf');
                    document.body.appendChild(link);
                    link.click();
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response)[0]][0],
                        color: 'red',
                }
            })
        },
        registrarAnotacao () {
            if (!this.$refs.formDados.validate())
                return false
            this.anotacao.usuario_id = this.$store.state.usuario.id
            this.axios.post(`processo/processo/${this.anotacao.tramitacao}/anotar`, this.anotacao).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            }).finally(() => {
                this.anotacao = {
                    ...this.anotacao,
                    show: false,
                    processo: null,
                    anotacoes: null,
                }
            })
        },
        colorSituacao (value) {
            switch (value) {
                case 'ARQ':
                    return 'blue'
                case 'DEV':
                    return 'blue'
                case 'ENV':
                    return 'accent'
                case 'RAS':
                    return 'blue'
                case 'REC':
                    return 'green'
                case 'RES':
                    return 'blue'
            }
        },
        resetProcesso () {
            this.verProcesso = {
                ...this.verProcesso,
                id: null,
                ano: null,
                setor_id_origem: null,
                setor_id_atual: null,
                usuario_id: null,
                usuario_id_sugestao: null,
                observacao: null,
                especificacao: null,
                processo_antigo: null,
                situacao_id: null,
                assunto_id: null,
                organizacao_id: null,
                tramitacoes: []
            }
        }
    },
    created () {
        this.getProcessos()
    },
    mounted () {
        this.$refs.formDados.validate()
    }
}
</script>