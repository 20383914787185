import Vue from 'vue';
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi' || 'md' || 'fa'
    },
    theme: {
        themes: {
            light: {
                primary: '#1E2248',
                secondary: colors.white,
                terciary: '#1f6de1',
                accent: '#1E2248',
                error: colors.red,
            },
            dark: {
                primary: '#240c49',
                secondary: '#1f6de1',
                accent: colors.green,
            },
        },
    },
    lang:{
        locales:{pt},
        current:'pt'
    }
});
