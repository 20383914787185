<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">    
        <div class="page">
            <v-container fluid>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Unidades</h2>
                    </v-col>
                </v-row>
                <v-row class="mb-2">
                    <v-col xs="7">
                        <v-text-field v-model="search" prepend-icon="mdi-feature-search-outline" label="Pesquisar" clearable></v-text-field>
                    </v-col>
                    <v-col xs="5" sm="6" md="5" lg="4" xl="3" class="justify-end content">
                        <v-btn
                            block
                            color="accent"
                            @click="[zerarCampos(), viewRegistro = true]"
                            dark
                            class="mb-2"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            Nova unidade
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="unidades"
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >
                    <template v-slot:[`item.active`]="{ item }">
                        <v-chip v-if="item.active" class="green" dark>Ativo</v-chip>
                        <v-chip v-else class="red" dark> Inativo </v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[loadRegistro(item), viewRegistro = true]"
                                >
                                    <v-icon color="blue">mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="item.active ? false : true"
                                    icon
                                    @click="[loadRegistro(item), inativar(item)]"
                                >
                                    <v-icon color="red">mdi-minus-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Inativar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-dialog width="40%" v-model="viewRegistro" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                            Gerenciar unidade
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                @click="[viewRegistro = false]"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="registro.name"
                                        label="Nome"
                                        required
                                        :rules="[rules.required, rules.min]"
                                        counter
                                        maxlength="100"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="registro.initials"
                                        label="Sigla"
                                        required
                                        :rules="[rules.required]"
                                        counter
                                        maxlength="30"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row v-if="!registro.id" class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                            
                                    <v-autocomplete
                                        v-model="registro.idInstitution"
                                        :items="instituicoes"
                                        item-text="name"
                                        item-value="id"
                                        label="Instituição"
                                        required
                                        :rules="[rules.required]"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="registro.id==null"
                                color="primary"
                                text
                                @click="[create()]"
                            >
                                Criar
                            </v-btn>
                            <v-btn v-else
                                color="primary"
                                text
                                @click="[update()]"
                            >
                                Atualizar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'Unidade',
    components: {
        Snackbar
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        search: '',
        loading: false,
        viewRegistro: false,
        registro: {
            id: null,
            name: null,
            initials: null,
            active: true,
            idOrganization: null,
            idInstitution: null,
        },
        headers: [
            { text: 'Nome', value: 'name', width: '30%' },
            { text: 'Sigla', value: 'initials', width: '10%' },
            { text: 'Instituição', value: 'instituicao', width: '30%' },
            { text: 'Situação', value: 'active', width: '10%' },
            { text: 'Ações', value: 'actions', width: '20%', align: 'right' },
        ],
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
            },
        unidades: [],
        instituicoes: [],
    }),
    methods: {
        getOrganizacoes () {
            this.instituicoes   = []
            this.unidades       = []
            this.loading        = true
            this.axios.get('gerencial/organizations/getall').then((res) => {
                for (var o = 0; o < res.data.length; o++) {
                    var organizacao = res.data[o]
                    if (organizacao.id === this.$store.state.organizacao.id)
                        this.getInstituicoes(organizacao)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        getInstituicoes (organizacao) {
            this.axios.get(`gerencial/institutions/getall/${organizacao.id}`).then((res) => {
                for (var i = 0; i < res.data.length; i++) {
                    var instituicao         = res.data[i]
                    this.getUnidades(organizacao, instituicao)
                    if (instituicao.active === true)
                        this.instituicoes.push(instituicao)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        getUnidades (organizacao, instituicao) {
            this.axios.get(`gerencial/units/getall/${organizacao.id}/${instituicao.id}`).then((res) => {
                for (var u = 0; u < res.data.length; u++) {
                    var unidade             = res.data[u]
                    unidade.organizacao     = organizacao.name
                    unidade.instituicao     = instituicao.name
                    this.unidades.push(unidade)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        create () {
            if (!this.$refs.formDados.validate())
                return false
            this.registro.idOrganization = this.$store.state.organizacao.id
            this.axios.post('gerencial/units/create', this.registro).then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: 'Registro inserido com sucesso',
                    color: 'green'
                }
                this.viewRegistro = false
                this.sinc()
                this.getOrganizacoes()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data.message[0].error[0],
                    color: 'red',
                }
            })
        },
        loadRegistro (item) {
            this.registro.id                = item.id
            this.registro.name              = item.name
            this.registro.initials          = item.initials
            this.registro.idOrganization    = this.$store.state.organizacao.id,
            this.registro.idInstitution     = item.idInstitution,
            this.registro.active            = true
        },
        update () {
            if (!this.$refs.formDados.validate())
                return false
            this.axios.put(`gerencial/units/update`, this.registro).then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: 'Registro atualizado com sucesso',
                    color: 'green'
                }
                this.viewRegistro = false
                this.sinc()
                this.getOrganizacoes()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data.message[0].error[0],
                    color: 'red',
                }
            })
        },
        inativar (item) {
            let con = confirm("Deseja inativar essa unidade?")
            if (con) {
                this.registro.id        = item.id
                this.registro.name      = item.name
                this.registro.initials  = item.initials
                this.registro.active    = false
                this.update()    
            }
        },
        zerarCampos () {
            this.registro.id            = null
            this.registro.name          = null
            this.registro.initials      = null
            this.registro.idOrganization= null
            this.registro.active        = true
        },
        sinc () {
            this.$axios.post(`${process.env.VUE_APP_PROCESSO_URL}sincronizacao/organograma`)
                .then((res) => {
                console.log(res.data.message[0])
             })    
        }
    },
    mounted () {
        this.getOrganizacoes()
        this.$refs.formDados.validate()
    }
}
</script>