<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Usuários</h2>
                    </v-col>
                </v-row>
                <v-row class="mb-2">
                    <v-col xs="7" sm="7" md="7" lg="7" xl="7" class="justify-end content">
                        <v-text-field 
                            v-model="search" 
                            prepend-icon="mdi-feature-search-outline" 
                            label="Pesquisar" 
                            clearable>
                        </v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="2" lg="2" xl="2" class="justify-end content">
                        <v-switch
                            v-model="mostrarTodos"
                            label="Mostrar todos"
                            color="primary"
                            @click="[getUsuarios(mostrarTodos)]"
                            flat>
                        </v-switch>
                    </v-col>
                    <v-col xs="3" sm="3" md="3" lg="3" xl="3" class="justify-end content">
                        <v-btn
                            block
                            color="accent"
                            @click="[zerarCampos(), viewRegistro = true]"
                            dark
                            class="mb-2"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            Novo usuário
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="usuarios"
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >
                    <template v-slot:[`item.disable`]="{ item }">
                        <v-chip v-if="!item.disable" class="green" dark>Ativo</v-chip>
                        <v-chip v-else class="red" dark> Inativo </v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[loadRegistro(item), viewRegistro = true]"
                                >
                                    <v-icon color="blue">mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="item.disable ? true : false"
                                    icon
                                    @click="[loadAcessos(item.id), userSelecionado = item.name + ' (' + item.email + ')', configAcessos = true]"
                                >
                                    <v-icon color="blue">mdi-account-key</v-icon>
                                </v-btn>
                            </template>
                            <span>Habilitar acessos aos setores</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="item.disable ? true : false"
                                    icon
                                    @click="[loadPerfis(item.id), userSelecionado = item.name + ' (' + item.email + ')', configPerfis = true]"
                                >
                                    <v-icon color="blue">mdi-account-multiple-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Configurar perfis de acesso</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="item.disable ? true : false"
                                    icon
                                    @click="[inativar(item)]"
                                >
                                    <v-icon color="red">mdi-minus-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Inativar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-dialog width="40%" v-model="viewRegistro" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                            Gerenciar usuário
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                @click="[viewRegistro = false]"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="registro.name"
                                        label="Nome"
                                        required
                                        :rules="[rules.required]"
                                        counter
                                        maxlength="100"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="registro.email"
                                        label="E-mail"
                                        required
                                        :rules="[rules.required]"
                                        counter
                                        maxlength="50"
                                        :readonly="registro.id !== null"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="registro.code"
                                        label="Identificador"
                                        required
                                        :rules="[rules.required]"
                                        counter
                                        maxlength="30"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="registro.type"
                                        label="Cargo"
                                        required
                                        :rules="[rules.required]"
                                        counter
                                        maxlength="100"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2" v-if="registro.id === null">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="registro.secret"
                                        label="Senha"
                                        required
                                        :rules="[rules.required]"
                                        counter
                                        maxlength="30"
                                        :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showPassword1 = !showPassword1"
                                        :type="showPassword1 ? 'text' : 'password'"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2" v-if="registro.id !== null">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="registro.password"
                                        label="Nova senha (opcional)"
                                        hint="Informar somente se desejar alterar a senha"
                                        counter
                                        maxlength="30"
                                        :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showPassword1 = !showPassword1"
                                        :type="showPassword1 ? 'text' : 'password'"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="registro.id==null"
                                color="primary"
                                text
                                @click="[create()]"
                            >
                                Criar
                            </v-btn>
                            <v-btn v-else
                                color="primary"
                                text
                                @click="[update()]"
                            >
                                Atualizar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="configAcessos" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                            Habilitar acessos aos setores para {{ userSelecionado }}
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                @click="[userSelecionado = null, configAcessos = false]"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid>
                            <v-row class="mb-2">
                                <v-col xs="7">
                                    <v-text-field v-model="searchSetor" prepend-icon="mdi-feature-search-outline" label="Pesquisar" clearable></v-text-field>
                                </v-col>
                                <v-col xs="5" sm="6" md="5" lg="4" xl="3" class="justify-end content">
                                    <v-btn
                                        block
                                        color="accent"
                                        @click="[saveAcessos()]"
                                        dark
                                        class="mb-2"
                                    >
                                        <v-icon left>mdi-check</v-icon>
                                        Atualizar acessos
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-data-table
                            :headers="headersSetores"
                            :items="setores"
                            :search="searchSetor"
                            class="elevation-1"
                            :loading="loadingSetores"
                            loading-text="Carregando..."
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-switch
                                    v-model="item.enable"
                                    flat
                                    @click="[selectSetor(item.idInstitution, item.idUnit, item.id, item.enable)]">
                                </v-switch>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="configPerfis" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                            Configurar perfis de acessos para {{ userSelecionado }}
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                @click="[userSelecionado = null, configPerfis = false]"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid>
                            <v-row class="mb-2">
                                <v-col xs="7">
                                    <v-text-field v-model="searchPerfil" prepend-icon="mdi-feature-search-outline" label="Pesquisar" clearable></v-text-field>
                                </v-col>
                                <v-col xs="5" sm="6" md="5" lg="4" xl="3" class="justify-end content">
                                    <v-btn
                                        block
                                        color="accent"
                                        @click="[savePerfis()]"
                                        dark
                                        class="mb-2"
                                    >
                                        <v-icon left>mdi-check</v-icon>
                                        Atualizar perfis
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-data-table
                            :headers="headersPerfis"
                            :items="perfis"
                            :search="searchPerfil"
                            class="elevation-1"
                            :loading="loadingPerfis"
                            loading-text="Carregando..."
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-switch
                                    v-model="item.enable"
                                    flat
                                    @click="[selectPerfil(item.id, item.enable)]">
                                </v-switch>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-dialog>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'Usuario',
    components: {
        Snackbar
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        userSelecionado: null,
        configAcessos: false,
        configPerfis: false,
        mostrarTodos: false,
        loading: false,
        loadingPerfis: false,
        loadingSetores: false,
        search: '',
        searchPerfil: '',
        searchSetor: '',
        viewRegistro: false,
        showPassword1: false,
        showPassword2: false,
        registro: {
            id: null,
            name: null,
            email: null,
            code: null,
            type: null,
            disable: false,
            secret: null,
            password: null,
            profile: null,
        },
        acessos: {
            idUser:         null,
            idOrganization: null,
            idInstitution:  null,
            idUnit:         null,
            idSector:       [],
        },
        profiles: {
            idUser:         null,
            idSystem:       "0d2dacf0-a8f3-4017-b198-cdfbe74fb47a", // MobbyDocs
            idProfile:      [],
        },
        headers: [
            { text: 'Nome do usuário', value: 'name', width: '30%' },
            { text: 'E-mail', value: 'email', width: '15%' },
            { text: 'Identificador', value: 'code', width: '15%' },
            { text: 'Cargo', value: 'type', width: '20%' },
            { text: 'Ações', value: 'actions', width: '20%', align: 'right' },
        ],
        headersSetores: [
            { text: 'Unidade', value: 'unidade', width: '45%' },
            { text: 'Setor', value: 'name', width: '45%' },
            { text: 'Ações', value: 'actions', width: '5%', align: 'center' },
        ],
        headersPerfis: [
            { text: 'Nome do perfil', value: 'name', width: '90%' },
            { text: 'Ações', value: 'actions', width: '10%', align: 'center' },
        ],
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
        usuarios: [],
        unidades: [],
        instituicoes: [],
        setores: [],
        perfis: [],
    }),
    methods: {
        getUsuarios (bolAll = false) {
            this.loading = true
            if (bolAll) {
                this.axios.get('gerencial/users/getall').then((res) => {
                    this.usuarios = res.data                        
                }).finally(() => {
                    this.loading = false
                })    
            }else{
                this.axios.get('gerencial/auth/users/organization').then((res) => {
                    this.usuarios = res.data.data                        
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        create () {
            if (!this.$refs.formDados.validate())
                return false
            this.axios.post('gerencial/users/create', this.registro).then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: 'Usuário inserido com sucesso',
                    color: 'green'
                }
                this.viewRegistro = false
                this.sinc()
                this.getUsuarios()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data.message,
                    color: 'red',
                }
            })
        },
        loadRegistro (item) {
            this.registro.id                = item.id
            this.registro.name              = item.name
            this.registro.email             = item.email
            this.registro.code              = item.code
            this.registro.type              = item.type
            this.registro.profile           = item.profile
            this.registro.secret            = 'secret'
            this.registro.password          = null
            this.registro.disable           = false
        },
        update () {
            if (!this.$refs.formDados.validate())
                return false
            this.axios.put(`gerencial/users/update`, this.registro).then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: 'Usuário atualizado com sucesso',
                    color: 'green'
                }
                this.viewRegistro = false
                if (this.registro.password)
                    this.axios.put(`gerencial/users/updatePassword`, this.registro)
                this.sinc()
                this.getUsuarios()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data.message,
                    color: 'red',
                }
            })  
        },
        inativar (item) {
            let con = confirm("Deseja inativar esse usuário?")
            if (con) {
                this.registro.id        = item.id
                this.registro.disable   = true
                
                this.axios.put(`gerencial/users/disable`, this.registro).then(() => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: 'Usuário inativado com sucesso',
                        color: 'green'
                    }
                    this.getUsuarios()
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data.message,
                    color: 'red',
                    }
                })   
            }
        },
        loadAcessos (idUser) {
            this.loadingSetores     = true
            this.acessos.idSector   = []
            this.setores            = []
            if(idUser){
                this.axios.get(`gerencial/users/getbyid/${idUser}`).then((res) => {
                    var setores = res.data.sectors    
                    if (setores.length > 0)
                        for (var s = setores.length - 1; s >= 0; s--)
                            this.acessos.idSector.push(setores[s])
                }).finally(() => {
                    this.loadingSetores = false
                })
            }
            this.getOrganizacoes(this.acessos)
            this.registro.id            = idUser
            this.acessos.idUser         = idUser
            this.acessos.idOrganization = this.$store.state.organizacao.id
        },
        loadPerfis (idUser) {
            this.loadingPerfis      = true
            this.profiles.idProfile = []
            this.perfis             = []
            if(idUser){
                this.axios.get(`gerencial/users/getbyid/${idUser}`).then((res) => {
                    for (var s = 0; s < res.data.systems.length; s++) {        
                        var perfil = res.data.systems[s].profiles
                        if (perfil.length > 0)
                            for (var p = perfil.length - 1; p >= 0; p--)
                                this.profiles.idProfile.push(perfil[p])
                    }
                }).finally(() => {
                    this.loadingPerfis = false
                })
            }
            this.getPerfis(this.profiles)
            this.registro.id        = idUser
            this.profiles.idUser    = idUser
        },
        getOrganizacoes (acessos) {
            this.instituicoes   = []
            this.unidades       = []
            this.setores        = []
            this.loading        = true
            this.axios.get('gerencial/organizations/getall').then((res) => {
                for (var o = 0; o < res.data.length; o++) {
                    var organizacao = res.data[o]
                    if (organizacao.id === this.$store.state.organizacao.id)
                        this.getInstituicoes(acessos, organizacao)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        getInstituicoes (acessos, organizacao) {
            this.axios.get(`gerencial/institutions/getall/${organizacao.id}`).then((res) => {
                for (var i = 0; i < res.data.length; i++) {
                    var instituicao         = res.data[i]
                    this.getUnidades(acessos, organizacao, instituicao)
                    if (instituicao.active === true)
                        this.instituicoes.push(instituicao)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        getUnidades (acessos, organizacao, instituicao) {
            this.axios.get(`gerencial/units/getall/${organizacao.id}/${instituicao.id}`).then((res) => {
                for (var u = 0; u < res.data.length; u++) {
                    var unidade             = res.data[u]
                    this.getSetores(acessos, organizacao, instituicao, unidade)
                    if (unidade.active === true)
                        this.unidades.push(unidade)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        getSetores (acessos, organizacao, instituicao, unidade){
            this.axios.get(`gerencial/sectors/getall/${organizacao.id}/${instituicao.id}/${unidade.id}`).then((res) => {
                for (var s = 0; s < res.data.length; s++) {
                    var setor           = res.data[s]
                    setor.organizacao   = organizacao.name
                    setor.instituicao   = instituicao.name
                    setor.unidade       = unidade.name

                    if (acessos.idSector.indexOf(setor.id) >= 0){
                        setor.enable        = true 
                    }else{
                        setor.enable        = false
                    }

                    if (setor.active === true)
                        this.setores.push(setor)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        selectSetor (idInstitution, idUnit, idSector, state) {
            this.acessos.idInstitution      = idInstitution
            this.acessos.idUnit             = idUnit
            if (state){
                this.acessos.idSector.push(idSector)
            } else {
                var remove = this.acessos.idSector.indexOf(idSector);
                this.acessos.idSector.splice(remove, 1)
            }
        },
        saveAcessos () {
            this.axios.put(`gerencial/users/sectors`, this.acessos).then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: 'Acessos configurados com sucesso',
                    color: 'green'
                }
                this.viewRegistro = false
                this.configAcessos = false
                this.getUsuarios()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        selectPerfil (idProfile, state) {
            if (state){
                this.profiles.idProfile.push(idProfile)
            } else {
                var remove = this.profiles.idProfile.indexOf(idProfile);
                this.profiles.idProfile.splice(remove, 1)
            }
        },
        savePerfis () {
            this.axios.put(`gerencial/users/profiles`, this.profiles).then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: 'Acessos configurados com sucesso',
                    color: 'green'
                }
                this.viewRegistro = false
                this.configPerfis = false
                this.getUsuarios()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        zerarCampos () {
            // usuario
            this.registro.id            = null
            this.registro.name          = null
            this.registro.email         = null
            this.registro.code          = null
            this.registro.secret        = null
            this.registro.type          = null
            this.registro.password      = null
            this.registro.disable       = false
            // acessos
            this.acessos.idInstitution  = null
            this.acessos.idUnit         = null
            this.acessos.idSector       = []
            this.profiles.idProfile     = []
        },
        getPerfis (perfis) {
            this.axios.get('gerencial/profiles/getall').then((res) => {
                for (var p = 0; p < res.data.length; p++) {
                    var perfil = res.data[p]
                    if (perfil.idOrganization === this.$store.state.organizacao.id){
                        if (perfis.idProfile.indexOf(perfil.id) >= 0)
                            perfil.enable   = true 
                        else
                            perfil.enable   = false
                        
                        this.perfis.push(perfil)
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },
        sinc () {
            this.$axios.post(`${process.env.VUE_APP_PROCESSO_URL}sincronizacao/usuarios`)
                .then((res) => {
                console.log(res.data.message[0])
             })    
        }
    },
    mounted () {
        this.getUsuarios()
        this.$refs.formDados.validate()
    }
}
</script>