<template>
     <v-dialog width="40%" v-model="docs.show"  :fullscreen="$vuetify.breakpoint.xsOnly">
          <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    Solicitar assinatura do documento {{ docs.numero_doc }}
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="[docs.show = false]"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>  
                 <v-divider></v-divider>
                <v-card-text>
                    <v-row v-for="(usuario,index) in usuarioAdd" :key="index">
                        <v-col
                            cols="12"
                            sm="8"
                            xs="12"
                        >
                            <v-autocomplete
                                :items="usuarios"
                                item-text="name"
                                item-value="email"
                                v-model="usuariosList[index]"
                                label="Selecione o usuário"
                            ></v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-btn
                                @click="[(usuarioAdd += 1)]"
                                color="primary"
                                class="mt-3"
                                x-small
                                fab
                                dark
                                >
                                +
                            </v-btn> 
                            <v-btn
                            v-if="usuarioAdd > 1 && index > 0"
                            @click="[removeAssinatura()]"
                            color="red"
                            class="mt-3 ml-3"
                            x-small
                            fab
                            dark
                            >
                            -
                            </v-btn>   
                        </v-col>
                    </v-row>
                </v-card-text> 
                 <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="accent"
                        text
                        @click="[solicitarAssinatura()]"
                    >
                       Solicitar Assinatura
                    </v-btn>
                </v-card-actions>         
          </v-card>    
     </v-dialog>    
</template>
<script>
export default {
    name: 'assinatura-documento',
    props:{
        docs:{
            show: Boolean,
            iddoc: Number,
            numero_doc: String,
            usuario_assinatura: Array
        }
    },
    data: () => ({
        usuarios:[],
        usuariosList:[],
        usuarioAdd:1
    }),
    methods:{
        getUserOrganizacao(){ 
            this.usuarios = []
            this.axios.get('gerencial/auth/users/organization').then((res) => {
                for (var u = 0; u < res.data.data.length; u++){
                    var usuario   = res.data.data[u]
                    if (usuario.disable === false)
                        this.usuarios.push(usuario)
                }
            }).finally(() => {

            })
        },
        solicitarAssinatura () {
            this.docs.usuario_assinatura = this.usuariosList
            this.$emit('criarBloc', {
                docs: this.docs,
            })
            this.docs.show = false
        },
        removeAssinatura(indice) {
            if (this.usuarioAdd > 1) {
                this.usuarioAdd -= 1;
                this.usuariosList.splice(indice, 1);
            }
        },        
     },
     mounted(){
         this.getUserOrganizacao()        
     }
}
</script>