<template>
    <div class="page">
        <v-container fluid>
            <v-container v-show="showTableProcessos">                
                <Filtros
                    ano
                    numero
                    data
                    meus_favoritos
                    processoAntigo
                    tipoprocesso
                    assunto
                    nivel
                    interessado
                    :itemsArr="processos"
                    :qtdFilters="5"
                    @input="applyInput"
                    @export="applyExport"
                    @pdf="applyPdf"
                    @filter="applyFilter"
                    @clear="applyClear"
                    v-if="loadedProcessos"
                />
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Processos arquivados no setor</h2>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="processos"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >
                    <template v-slot:[`item.marcador_id`]="{ item }">
                        <v-icon 
                            v-if="item.marcador_id"
                            :color="(item.marcador_cor)"
                            :title="(item.marcador_nome)"
                            >mdi-tag</v-icon>
                    </template>
                    
                    <template v-slot:[`item.id`]="{ item }">
                        <v-chip
                            v-if="item.marcador_id"
                            :color="(item.marcador_cor)"
                            class="white--text"
                        >
                            {{ item.numero }}/{{ item.ano }}
                        </v-chip>

                        <v-chip
                            v-if="!item.marcador_id"
                            color="white"
                            class="black--text"
                        >
                            {{ item.numero }}/{{ item.ano }}
                        </v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    v-scroll-to="{
                                        el: '#personalizar',
                                        easing: [.6, .80, .30, 1.9],
                                        duration: 2000
                                    }"
                                    @click="getDocs(item)"
                                >
                                    <v-icon color="accent">mdi-folder-open</v-icon>
                                </v-btn>
                            </template>
                            <span>Gerenciar documentos</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[getProcesso(item.id)]"
                                >
                                    <v-icon color="accent">mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver detalhes</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    :disabled="item.isJuntado"
                                    @click="[getProcessoModal(item.id, 'resgatar'), 
                                    dadosProcessoResgatar.id = item.id, 
                                    dadosProcessoResgatar.numero = item.numero, 
                                    dadosProcessoResgatar.show = true
                                    ]"
                                >
                                    <v-icon color="accent">mdi-arrow-left</v-icon>
                                </v-btn>
                            </template>
                            <span>Resgatar processo</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    :disabled="item.isJuntado"
                                    @click="[getProcessoModal(item.id, 'enviar'), 
                                    dadosProcessoEnviar.id = item.id, 
                                    dadosProcessoEnviar.numero = item.numero, 
                                    dadosProcessoEnviar.show = true]"
                                >
                                    <v-icon color="accent">mdi-arrow-right</v-icon>
                                </v-btn>
                            </template>
                            <span>Enviar processo</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="!item.marcador_id">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[getModalMarcador(item),
                                        dadosMarcador.id            = item.id,
                                        dadosMarcador.numero        = item.numero,
                                        dadosMarcador.ano           = item.ano,
                                        dadosMarcador.setor_id      = item.setor_id_atual,
                                        dadosMarcador.usuario_id    = null,
                                        dadosMarcador.marcador_id   = null,
                                        dadosMarcador.show          = true
                                    ]"
                                >
                                    <v-icon color="accent">mdi-tag-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Marcadores</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.marcador_id">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[removerMarcador(item.marcador_id)]"
                                >
                                    <v-icon color="red">mdi-tag-remove</v-icon>
                                </v-btn>
                            </template>
                            <span>Remover marcador</span>
                        </v-tooltip>
                        
                    </template>
                </v-data-table>
            </v-container>

            <EnviarModalProcesso :processo="dadosProcessoEnviar" @processo="enviarProcesso"></EnviarModalProcesso>
            <ResgatarModalProcesso :processo="dadosProcessoResgatar" @processo="resgatarProcesso"></ResgatarModalProcesso>
            <MarcadorModalProcesso ref="marcadorModalProcesso" :processo="dadosMarcador" @adicionarMarcador="adicionarMarcador"></MarcadorModalProcesso>
            <VerModalProcesso :processo="verProcesso"></VerModalProcesso>
            <div id="personalizar">
                <PersonalizarProcesso 
                    :processo="personalizarProcesso" 
                    @arvoreDocs="getDocs" 
                    @limparDocs="getProcessos">
                </PersonalizarProcesso>
            </div>
        </v-container>
        <Snackbar :snackbar="snackbar"></Snackbar>
    </div>
</template>
<script>
import { processoMixin, filterMixin } from '../../mixins'
import Snackbar from '../../components/Snackbar.vue'
import EnviarModalProcesso from '../../components/Modais/EnviarModalProcesso.vue'
import MarcadorModalProcesso from '../../components/Modais/MarcadorModalProcesso.vue'
import ResgatarModalProcesso from '../../components/Modais/ResgatarModalProcesso.vue'
import VerModalProcesso from '../../components/Modais/VerModalProcesso.vue'
import PersonalizarProcesso from '../../components/PersonalizarProcesso.vue'

export default {
    name:'arquivar-processo',
    mixins: [processoMixin, filterMixin],
    components: {
        EnviarModalProcesso,
        ResgatarModalProcesso,
        MarcadorModalProcesso,
        VerModalProcesso,
        PersonalizarProcesso,
        Snackbar,
    },
    data: () => ({
        loadedProcessos: false,
        verProcesso: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_atual: null,
            usuario_id: null,
            observacao: null,
            especificacao: null,
            processo_antigo: null,    
            tramitacoes: [],
            situacao_id: null,
            assunto_id: null,
            organizacao_id: null,
        },
        personalizarProcesso: {
            show: false,
            gerenciar: false,
            arquivo: true,
            id: null,
            docs: [],
            filename: null,
            numero: null,
            ano: null,
            nivel_id: null,
            enableEditor: null
        },
        dadosProcessoEnviar: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: 1,
            observacao: null,
            setores: [],
        },
        dadosProcessoResgatar: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: null,
            observacao: null,        
        },
        dadosMarcador: {
            show: false,
            id: null,
            ano: null,
            usuario_id: null,
            marcador_id: null,
            setor_id: null
        },
        marcador: {
            processo_id: null,
            marcador_id: null,
            setor_id: null,
            usuario_id: null,
        },
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        processos: [],
        loading: false,
        showTableProcessos: true,
        headers: [
            { text: '', value: 'marcador_id', width: '5%' },
            { text: 'Processo', value: 'id', width: '10%' },
            { text: 'Setor atual', value: 'setor_atual.nome', width: '25%' },
            { text: 'Tipo de processo', value: 'tipo_processo.nome', width: '20%' },
            { text: 'Assunto', value: 'assunto.nome', width: '15%' },
            { text: 'Ações', value: 'actions', width: '25%', align: 'right' },
        ],
    }),
    watch: {
        tipoprocesso_id () {
            this.getProcessos()
        },
        assunto_id () {
            this.getProcessos()
        },
        nivel_id () {
            this.getProcessos()
        },
        situacao_id () {
            this.getProcessos()
        },
        interessado () {
            this.getProcessos()
        },
    },
    methods: {
        applyInput (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.getProcessos()
        },
        applyExport (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.exportar()
        },
        applyPdf (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.pdf()
        },
        exportar () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/exportarquivo`, 
                filters,
                //{file_name: 'temp.xlsx'},
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "arquivo.xlsx")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        pdf () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/pdfarquivo`, 
                filters,
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "arquivo.pdf")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        getProcessos () {
            const filters = {
                'ano': this.ano ,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.loading = true
            this.showTableProcessos = true;
            this.personalizarProcesso.show = false;
            this.axios.post(`processo/consulta/${this.$store.state.usuario.id}/arquivo`,filters).then((res) => {
                this.processos = res.data
            }).finally(() => {
                this.loading = false
                this.loadedProcessos = true
            })
        },
        getProcessoModal (id, operacao) {
            this.axios.get(`processo/processo/${id}`).then((res) => {
                const p = res.data[0]
                var origem
                var destino
                if (p.tramitacoes.length > 0) {
                    origem = p.tramitacoes[p.tramitacoes.length - 1].setor_id_origem
                    destino = p.tramitacoes[p.tramitacoes.length - 1].setor_id_destino
                } else {
                    origem = p.setor_id_origem
                    destino = p.setor_id_atual
                }

                if (operacao == 'enviar'){
                    // enviar processo
                    this.dadosProcessoEnviar = {
                        ...this.dadosProcessoEnviar,
                        id: p.id,
                        numero: p.numero,
                        ano: p.ano,
                        setor_id_origem: origem,
                        setor_id_atual: destino,
                        usuario_id: p.usuario_id,
                        observacao: null,
                    }
                }else{
                    // resgatar processo
                    this.dadosProcessoResgatar = {
                        ...this.dadosProcessoResgatar,
                        id: p.id,
                        numero: p.numero,
                        ano: p.ano,
                        setor_id_origem: origem,
                        setor_id_atual: destino,
                        usuario_id: p.usuario_id,
                        observacao: null,
                    }
                }
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        getDocs(item) {
            this.personalizarProcesso.docs  = []
            let qtdProcessos = item.arvore.length;
            for (var p = 0; p < qtdProcessos; p++){                
                let processo = item.arvore[p]                
                let qtdDocumentos = processo.documentos.length;
                this.personalizarProcesso.docs.push({
                    id: p,
                    nome: processo.numero+'/'+processo.ano,
                    icon: 'mdi-folder',
                    children: []    
                })
                for (var d = 0; d < qtdDocumentos; d++){
                    let documento = processo.documentos[d]
                    let icon
                    let nome = '#' + documento.numero_doc 
                        + ' ' + documento.tipo_documento.nome
                    
                    if (documento.excluido == 0){
                        if (documento.gerado == 0)
                            icon = 'mdi-download'
                        else
                            if (documento.assinado == 1)
                                icon = 'mdi-file-sign'
                            else
                                icon = 'mdi-file-document-edit'
                    } else
                        icon = 'mdi-file-remove'
                    
                    this.personalizarProcesso.docs[p].children.push({
                        id:                 documento.id,                   
                        processo_id:        documento.processo_id,
                        nome:               nome,  
                        gerado:             documento.gerado,
                        excluido:           documento.excluido,
                        body:               documento.body,
                        titulo:             documento.titulo,
                        filename:           documento.filename,
                        icon:               icon,
                        criar:              0,
                        upload:             0,
                        bloco_id:           documento.bloco_id,
                        tipodocumento_id:   documento.tipo_documento.id,                    
                    })
                }
            }
            this.personalizarProcesso.id            = item.id
            this.personalizarProcesso.numero        = item.numero
            this.personalizarProcesso.ano           = item.ano
            this.personalizarProcesso.nivel_id      = item.nivel_id
            this.showTableProcessos                 = false
            this.personalizarProcesso.show          = true
            this.personalizarProcesso.enableEditor  = false
        },
        resgatarProcesso (value) {
            this.dadosProcessoResgatar = value.processo
            this.axios.post(`processo/processo/${this.dadosProcessoResgatar.id}/resgatar`, this.dadosProcessoResgatar).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        getModalMarcador(value){
            this.$refs.marcadorModalProcesso.getMarcadoresSetor(value)
        },
        adicionarMarcador(item){
            this.marcador.processo_id   = item.processo.id
            this.marcador.marcador_id   = item.processo.marcador_id
            this.marcador.setor_id      = item.processo.setor_id
            this.marcador.usuario_id    = this.$store.state.usuario.id
            this.axios.post('processo/processomarcador', this.marcador).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        removerMarcador(id){
            if (confirm('Deseja remover o marcador do processo?')) {
                this.axios.patch('processo/processomarcador/'+id).then((res) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: res.data.message,
                        color: 'green'
                    }
                    this.getProcessos()
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    }
                })
            }
        },
        resetProcesso () {
            this.verProcesso = {
                ...this.verProcesso,
                id: null,
                ano: null,
                setor_id_origem: null,
                setor_id_atual: null,
                usuario_id: null,
                observacao: null,
                especificacao: null,
                processo_antigo: null,
                situacao_id: null,
                assunto_id: null,
                organizacao_id: null,
                tramitacoes: []
            }
        }
    },
    mounted () {
        this.getProcessos()
    }
}
</script>