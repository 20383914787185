<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div>
            <v-dialog width="40%" v-model="processo.show" :fullscreen="$vuetify.breakpoint.xsOnly">
                <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                        Gerenciar marcador do processo {{ processo.numero }}/{{ processo.ano }}
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            @click="[processo.show = false]"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row class="mt-2">
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >
                                <v-autocomplete
                                    :items="marcadores"
                                    item-text="nome"
                                    item-value="id"
                                    v-model="processo.marcador_id"
                                    required
                                    :rules="[rules.required]"
                                    label="Marcar processo como"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="accent"
                            dense
                            @click="[adicionarMarcador()]"
                        >
                            Aplicar marcador
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-form>
</template>
<script>
    export default{
    name: 'marcador-processo',
        props:{
            processo:{
                id: Number,
                ano: Number,
                numero: Number,
                usuario_id: String,
                setor_id: String,
                marcador_id: String,
                show: Boolean
            },

        },
        data: () => ({
            formValid: false,
            rules: {
                required: value => !!value || 'O preenchimento é obrigatório',
            },
            marcadores: []
        }),
        methods: {
            adicionarMarcador () {
                if (!this.$refs.formDados.validate())
                    return false
                this.$emit('adicionarMarcador', {
                    processo: this.processo,
                })
                this.processo.show = false
            },
            getMarcadoresSetor(value){
                this.marcadores = []
                this.$axios.get(`processo/marcadores/${this.$store.state.organizacao.id}/org`, {
                    params: { 'ativo': 1, "setor": value.setor_id_atual }
                }).then((res) => {
                    this.marcadores = res.data; 
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    }
                })
            }
        },
        mounted () {
            this.$refs.formDados.validate()
        }
    }
</script>