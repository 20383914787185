<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-container v-show="showTableNotificacoes">
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Notificações</h2>
                    </v-col>
                </v-row>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="12" xl="9">
                        <v-text-field v-model="search" prepend-icon="mdi-feature-search-outline" label="Pesquisar" clearable></v-text-field>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="registros"
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >

                    <template v-slot:[`item.lido`]="{ item }">
                        <v-chip v-if="item.lido" class="green" dark>Lido</v-chip>
                        <v-chip v-else class="red" dark>Não lido</v-chip>
                    </template>
                    <template v-slot:[`item.data`]="{ item }">
                        {{ $moment(item.created_at).format('DD/MM/YYYY') }}
                    </template>
                    <template v-slot:[`item.tempo`]="{ item }">
                        {{ $moment(item.created_at).startOf('minute').fromNow() }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }" left>
                        
                        <v-tooltip bottom v-if="item.lido == 0">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[read(item.id)]"
                                >
                                    <v-icon color="accent">mdi-email</v-icon>
                                </v-btn>
                            </template>
                            <span>Marcar como lida</span>
                        </v-tooltip>
                    
                        <v-tooltip bottom v-if="item.lido == 1">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[unread(item.id)]"
                                >
                                    <v-icon color="accent">mdi-email-open</v-icon>
                                </v-btn>
                            </template>
                            <span>Marcar como não lida</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.favorito_deleted == null">
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[unfollowProcesso(item.favorito_id)]"
                                >
                                    <v-icon color="accent">mdi-star</v-icon>
                                </v-btn>
                            </template>
                            <span>Deixar de receber notificações</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    v-scroll-to="{
                                        el: '#personalizar',
                                        easing: [.6, .80, .30, 1.9],
                                        duration: 2000
                                    }"
                                    @click="[getDocs(item.processo)]"
                                >
                                    <v-icon color="accent">mdi-folder-open</v-icon>
                                </v-btn>
                            </template>
                            <span>Gerenciar documentos</span>
                        </v-tooltip>
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[getProcesso(item.processo_id)]"
                                >
                                    <v-icon color="accent">mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver detalhes</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }" >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[pdf(item.processo_id)]"
                                >
                                    <v-icon color="accent">mdi-printer</v-icon>
                                </v-btn>
                            </template>
                            <span>Visualizar histórico</span>
                        </v-tooltip>
                        
                    </template>

                </v-data-table>
                </v-container>
            
                <VerModalProcesso :processo="verProcesso"></VerModalProcesso>
                <div id="personalizar">
                    <PersonalizarProcesso 
                        :processo="personalizarProcesso" 
                        @tramitarProcessoDocs="enviarProcessoDocs" 
                        @arvoreDocs="getDocs" 
                        @limparDocs="get" 
                        @sendDoc="createDoc">
                    </PersonalizarProcesso>
                </div>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import { processoMixin } from '../../mixins'
import VerModalProcesso from '../../components/Modais/VerModalProcesso.vue'
import PersonalizarProcesso from '../../components/PersonalizarProcesso.vue'
import Snackbar from '../../components/Snackbar.vue'
export default {
    mixins: [processoMixin ],
    name:'Notificacoes',
    components: {
        Snackbar,
        VerModalProcesso,
        PersonalizarProcesso
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        search: '',
        loading: false,
        showTableNotificacoes: true,
        headers: [
            { text: 'Descrição', value: 'descricao', width: '50%' },
            { text: 'Data', value: 'data', width: '10%' },
            { text: 'Tempo decorrido', value: 'tempo', width: '10%' },
            { text: 'Situação', value: 'lido', width: '10%' },
            { text: 'Ações', value: 'actions', width: '20%', align: 'right' },
        ],
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
        personalizarProcesso: {
            show: false,
            gerenciar: false,
            notificacoes: true,
            id: null,
            docs: [],
            filename: null,
            numero: null,
            ano: null,
            nivel_id: null,
            enableEditor: null
        },
        verProcesso: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_origem: null,
            setor_id_atual: null,
            usuario_id: null,
            observacao: null,
            especificacao: null,
            processo_antigo: null,    
            tramitacoes: [],
            situacao_id: null,
            assunto_id: null,
            organizacao_id: null
        },
        registros: []
    }),
    methods: {
        get() {
            this.loading = true
            this.showTableNotificacoes = true        
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}notificacao/${this.$store.state.usuario.id}`).then((res) => {
                this.registros = res.data
            }).finally(() => {
                this.loading = false
            })
        },
        read (id) {
            this.axios.put('processo/notificacoes/'+id).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.get()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        unread (id) {
            this.axios.patch('processo/notificacoes/'+id).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.get()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        unfollowProcesso(id){
            this.axios.patch('processo/favoritos/'+id).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.get()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        pdf(id){
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}processo/pdfcapa/`+id, 
                {file_name: 'temp.pdf'}, 
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'historico.pdf');
                    document.body.appendChild(link);
                    link.click();
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response)[0]][0],
                        color: 'red',
                }
            })
        },
        resetProcesso () {
            this.verProcesso = {
                ...this.verProcesso,
                id: null,
                ano: null,
                setor_id_origem: null,
                setor_id_atual: null,
                usuario_id: null,
                observacao: null,
                especificacao: null,
                processo_antigo: null,
                situacao_id: null,
                assunto_id: null,
                organizacao_id: null,
                tramitacoes: []
            }
        },
        getDocs(item) {
            this.axios.get(`processo/documento/${item.id}/documentos`).then((res) => {
                this.personalizarProcesso.docs = []
                this.QtdDocumentos = 10;
                this.QtdAgrupamentos = parseInt(res.data.length/ this.QtdDocumentos); 
                
                if (this.QtdAgrupamentos > 0){
                    for (var p = 1; p <= this.QtdAgrupamentos; p++) {
                        let QuantidadeInicio
                        let QuantidadeFinal 
                        if(p == 1){
                            QuantidadeInicio = p
                        }else{
                            QuantidadeInicio = ((p-1) * this.QtdDocumentos) + 1    
                        }

                        QuantidadeFinal = (p * this.QtdDocumentos);
                        
                        this.personalizarProcesso.docs.push({
                            id: p,
                            nome: QuantidadeInicio +"-"+ QuantidadeFinal,
                            icon: 'mdi-folder',
                            children: []
                            },);
                    }    
                }
                
                let contadorPasta = 0;
                let contadorDoc = 0;
                for (var d = 0; d < res.data.length; d++) {
                    
                    let icon
                    let nome

                    nome = '#' + res.data[d].numero_doc 
                           + ' ' + res.data[d].tipo_documento.nome

                    if (res.data[d].excluido == 0){
                        if (res.data[d].gerado == 0){
                            icon = 'mdi-download'
                            nome += ' ' + res.data[d].numero_doc_upload
                        }else
                            if (res.data[d].assinado == 1)
                                icon = 'mdi-file-sign'
                            else
                                icon = 'mdi-file-document-edit'
                    } else
                        icon = 'mdi-file-remove'

                    nome += ' (' + res.data[d].id + ')'

                    if(this.QtdAgrupamentos > 0 && contadorPasta != this.QtdAgrupamentos){
                         contadorDoc++;

                        this.personalizarProcesso.docs[contadorPasta].children.push({
                            id: res.data[d].id,                     // codigo do documento
                            processo_id: res.data[d].processo_id,   // codigo do processo
                            nome: nome,
                            gerado: res.data[d].gerado,
                            excluido: res.data[d].excluido,
                            body: res.data[d].body,
                            titulo: res.data[d].titulo,
                            filename: res.data[d].filename,
                            icon: icon,
                            criar: 0,
                            upload: 0,
                            bloco_id: res.data[d].bloco_id,
                            tipodocumento_id:res.data[d].tipo_documento.id,    
                        })
                       
                        if(contadorDoc == this.QtdDocumentos){
                            contadorDoc = 0;
                            contadorPasta++;
                        }

                    }else{
                    
                        this.personalizarProcesso.docs.push({
                            id: res.data[d].id,                     // codigo do documento
                            processo_id: res.data[d].processo_id,   // codigo do processo
                            nome: nome,
                            gerado: res.data[d].gerado,
                            excluido: res.data[d].excluido,
                            body: res.data[d].body,
                            titulo: res.data[d].titulo,
                            filename: res.data[d].filename,
                            icon: icon,
                            criar: 0,
                            upload: 0,
                            bloco_id: res.data[d].bloco_id,
                            tipodocumento_id:res.data[d].tipo_documento.id,                    
                        })
                    }
                }
                this.personalizarProcesso.id        = item.id
                this.personalizarProcesso.numero    = item.numero
                this.personalizarProcesso.ano       = item.ano
                this.personalizarProcesso.nivel_id  = item.nivel_id
                this.showTableProcessos             = false
            })
            this.personalizarProcesso.show = true
            this.showTableNotificacoes = false
            this.personalizarProcesso.enableEditor = false
        },
    },
    mounted () {
        this.get()
        this.$refs.formDados.validate()
    }
}
</script>