<template>
  <div class="page">
    <v-container fluid>
      <v-container v-show="showTableProcessos">
        <Filtros
          ano
          numero
          processoAntigo
          data
          meus_favoritos
          tipoprocesso
          assunto
          situacao
          interessado
          :itemsArr="processos"
          :qtdFilters="4"
          @input="applyInput"
          @export="applyExport"
          @pdf="applyPdf"
          @filter="applyFilter"
          @clear="applyClear"
          v-if="loadedProcessos"
        />
        <v-row class="mb-2">
          <v-col xs="12" sm="6" md="7" lg="8" xl="9">
            <h2 class="mb-0 accent--text">Consultar processos</h2>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="processos"
          class="elevation-1"
          :loading="loading"
          loading-text="Carregando..."
        >
          <template v-slot:[`item.id`]="{ item }"
            >{{ item.numero }}/{{ item.ano }}</template
          >
          <template v-slot:[`item.situacao`]="{ item }">
            <v-chip
              :color="colorSituacao(item.situacao_id)"
              class="white--text"
            >
              {{ item.situacao }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  v-scroll-to="{
                    el: '#personalizar',
                    easing: [0.6, 0.8, 0.3, 1.9],
                    duration: 2000,
                  }"
                  @click="getDocs(item)"
                >
                  <v-icon color="accent">mdi-folder-open</v-icon>
                </v-btn>
              </template>
              <span>Gerenciar documentos</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="[getProcesso(item.id)]"
                >
                  <v-icon color="accent">mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver detalhes</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-container>
      <VerModalProcesso :processo="verProcesso"> </VerModalProcesso>
      <div id="personalizar">
        <PersonalizarProcesso
          :processo="personalizarProcesso"
          @arvoreDocs="getDocs"
          @limparDocs="getProcessos"
        >
        </PersonalizarProcesso>
      </div>
    </v-container>
    <Snackbar :snackbar="snackbar"></Snackbar>
  </div>
</template>
<script>
import { processoMixin, filterMixin } from '../../mixins';
import VerModalProcesso from '../../components/Modais/VerModalProcesso.vue';
import PersonalizarProcesso from '../../components/PersonalizarProcesso.vue';
import Snackbar from '../../components/Snackbar.vue';

export default {
  name: 'consultar-processos',
  mixins: [processoMixin, filterMixin],
  components: {
    Snackbar,
    VerModalProcesso,
    PersonalizarProcesso,
  },
  data: () => ({
    loadedProcessos: false,
    personalizarProcesso: {
      show: false,
      gerenciar: false,
      consultar: true,
      id: null,
      docs: [],
      filename: null,
      numero: null,
      ano: null,
      nivel_id: null,
      enableEditor: null,
    },
    verProcesso: {
      show: false,
      id: null,
      numero: null,
      ano: null,
      setor_id_origem: null,
      setor_id_atual: null,
      usuario_id: null,
      observacao: null,
      especificacao: null,
      processo_antigo: null,
      tramitacoes: [],
    },
    dadosProcesso: {
      show: false,
      id: null,
      ano: null,
      setor_id_origem: null,
      setor_id_destino: null,
      usuario_id: null,
      observacao: null,
    },
    snackbar: {
      show: false,
      timeout: 6000,
      align: 'right',
    },
    loading: false,
    showTableProcessos: true,
    processos: [],
    headers: [
      { text: 'Processo', value: 'id', width: '12%' },
      { text: 'Setor atual', value: 'setorAtual', width: '20%' },
      { text: 'Tipo de processo', value: 'tipoProcesso', width: '25%' },
      { text: 'Assunto', value: 'assunto', width: '20%' },
      { text: 'Situação', value: 'situacao', width: '8%' },
      { text: 'Ações', value: 'actions', width: '15%', align: 'right' },
    ],
  }),
  watch: {
    tipoprocesso_id() {
      this.getProcessos();
    },
    assunto_id() {
      this.getProcessos();
    },
    nivel_id() {
      this.getProcessos();
    },
    situacao_id() {
      this.getProcessos();
    },
    interessado() {
      this.getProcessos();
    },
    ano() {
      this.getProcessos();
    },
  },
  methods: {
    applyInput(inputed) {
      if (inputed.ano) this.ano = inputed.ano;
      else this.ano = null;
      if (inputed.numero) this.numero = inputed.numero;
      else this.numero = null;
      if (inputed.data) this.data = inputed.data;
      else this.data = null;
      if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos;
      else this.meus_favoritos = null;
      if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo;
      else this.processoAntigo = null;
      this.getProcessos();
    },
    applyExport(inputed) {
      if (inputed.ano) this.ano = inputed.ano;
      else this.ano = null;
      if (inputed.numero) this.numero = inputed.numero;
      else this.numero = null;
      if (inputed.data) this.data = inputed.data;
      else this.data = null;
      if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos;
      else this.meus_favoritos = null;
      if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo;
      else this.processoAntigo = null;
      this.exportar();
    },
    applyPdf(inputed) {
      if (inputed.ano) this.ano = inputed.ano;
      else this.ano = null;
      if (inputed.numero) this.numero = inputed.numero;
      else this.numero = null;
      if (inputed.data) this.data = inputed.data;
      else this.data = null;
      if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos;
      else this.meus_favoritos = null;
      if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo;
      else this.processoAntigo = null;
      this.pdf();
    },
    getProcessos() {
      const filters = {
        ano: this.ano,
        numero: this.numero,
        data: this.data,
        meus_favoritos: this.meus_favoritos,
        usuario_id: this.$store.state.usuario.id,
        processo_antigo: this.processoAntigo,
        tipoprocesso_id: this.tipoprocesso_id,
        assunto_id: this.assunto_id,
        nivel_id: this.nivel_id,
        situacao_id: this.situacao_id,
        interessado: this.interessado,
      };
      this.loading = true;
      this.showTableProcessos = true;
      this.personalizarProcesso.show = false;
      this.axios
        .post(
          `processo/consulta/${this.$store.state.organizacao.id}/consultar`,
          filters
        )
        .then((res) => {
          this.processos = res.data;
        })
        .finally(() => {
          this.loading = false;
          this.loadedProcessos = true;
        });
    },
    exportar() {
      const filters = {
        ano: this.ano,
        numero: this.numero,
        data: this.data,
        meus_favoritos: this.meus_favoritos,
        processo_antigo: this.processoAntigo,
        tipoprocesso_id: this.tipoprocesso_id,
        assunto_id: this.assunto_id,
        nivel_id: this.nivel_id,
        situacao_id: this.situacao_id,
        interessado: this.interessado,
      };
      this.axios
        .post(
          `${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.organizacao.id}/exportconsultar`,
          filters,
          //{file_name: 'temp.xlsx'},
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'processos.xlsx');
          document.body.appendChild(link);
          link.click();
        });
    },
    pdf() {
      const filters = {
        ano: this.ano,
        numero: this.numero,
        data: this.data,
        meus_favoritos: this.meus_favoritos,
        processo_antigo: this.processoAntigo,
        tipoprocesso_id: this.tipoprocesso_id,
        assunto_id: this.assunto_id,
        nivel_id: this.nivel_id,
        situacao_id: this.situacao_id,
        interessado: this.interessado,
      };
      this.axios
        .post(
          `${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.organizacao.id}/pdfconsultar`,
          filters,
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/pdf',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'processos.pdf');
          document.body.appendChild(link);
          link.click();
        });
    },
    getProcessoModal(id) {
      this.axios
        .get(`processo/processo/${id}`)
        .then((res) => {
          const p = res.data[0];
          var origem;
          var destino;
          if (p.tramitacoes.length > 0) {
            origem = p.tramitacoes[p.tramitacoes.length - 1].setor_id_origem;
            destino = p.tramitacoes[p.tramitacoes.length - 1].setor_id_destino;
          } else {
            origem = p.setor_id_origem;
            destino = p.setor_id_atual;
          }
          this.dadosProcesso = {
            ...this.dadosProcesso,
            id: p.id,
            numero: p.numero,
            ano: p.ano,
            setor_id_origem: origem,
            setor_id_atual: destino,
            usuario_id: p.usuario_id,
            observacao: p.observacao,
          };
        })
        .catch((e) => {
          this.snackbar = {
            ...this.snackbar,
            show: true,
            text: e.response.data[Object.keys(e.response.data)[0]][0],
            color: 'red',
          };
        });
    },
    getDocs(item) {
      this.personalizarProcesso.docs  = []
      let qtdProcessos = item.arvore.length;
      for (var p = 0; p < qtdProcessos; p++){                
          let processo = item.arvore[p]                
          let qtdDocumentos = processo.documentos.length;
          this.personalizarProcesso.docs.push({
              id: p,
              nome: processo.numero+'/'+processo.ano,
              icon: 'mdi-folder',
              children: []    
          })
          for (var d = 0; d < qtdDocumentos; d++){
              let documento = processo.documentos[d]
              let icon
              let nome = '#' + documento.numero_doc 
                  + ' ' + documento.tipo_documento.nome
              
              if (documento.excluido == 0){
                  if (documento.gerado == 0)
                      icon = 'mdi-download'
                  else
                      if (documento.assinado == 1)
                          icon = 'mdi-file-sign'
                      else
                          icon = 'mdi-file-document-edit'
              } else
                  icon = 'mdi-file-remove'
              
              this.personalizarProcesso.docs[p].children.push({
                  id:                 documento.id,                   
                  processo_id:        documento.processo_id,
                  nome:               nome,  
                  gerado:             documento.gerado,
                  excluido:           documento.excluido,
                  body:               documento.body,
                  titulo:             documento.titulo,
                  filename:           documento.filename,
                  icon:               icon,
                  criar:              0,
                  upload:             0,
                  bloco_id:           documento.bloco_id,
                  tipodocumento_id:   documento.tipo_documento.id,                    
              })
          }
      }
      this.personalizarProcesso.id            = item.id
      this.personalizarProcesso.numero        = item.numero
      this.personalizarProcesso.ano           = item.ano
      this.personalizarProcesso.nivel_id      = item.nivel_id
      this.showTableProcessos                 = false
      this.personalizarProcesso.show          = true
      this.personalizarProcesso.enableEditor  = false
    },
    colorSituacao(value) {
      switch (value) {
        case 'ARQ':
          return 'blue';
        case 'DEV':
          return 'blue';
        case 'ENV':
          return 'accent';
        case 'RAS':
          return 'blue';
        case 'REC':
          return 'green';
        case 'RES':
          return 'blue';
      }
    },
    resetProcesso() {
      this.verProcesso = {
        ...this.verProcesso,
        id: null,
        ano: null,
        setor_id_origem: null,
        setor_id_atual: null,
        usuario_id: null,
        observacao: null,
        especificacao: null,
        processo_antigo: null,
        tramitacoes: [],
      };
    },
  },
  mounted() {
    this.getProcessos();
  },
};
</script>
