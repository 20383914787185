var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formDados",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"page"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"7","lg":"8","xl":"9"}},[_c('h2',{staticClass:"mb-0 accent--text"},[_vm._v("Solicitações de acessos externos")])])],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{staticClass:"justify-end content",attrs:{"xs":"10","sm":"10","md":"10","lg":"10","xl":"10"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-feature-search-outline","label":"Pesquisar","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"justify-end content",attrs:{"xs":"2","sm":"2","md":"2","lg":"2","xl":"2"}},[_c('v-switch',{attrs:{"label":"Mostrar todos","color":"primary","flat":""},on:{"click":function($event){[_vm.getSolicitacoes(_vm.mostrarTodos)]}},model:{value:(_vm.mostrarTodos),callback:function ($$v) {_vm.mostrarTodos=$$v},expression:"mostrarTodos"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.solicitacoes,"search":_vm.search,"loading":_vm.loading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.analise",fn:function(ref){
var item = ref.item;
return [(item.analise == 0)?_c('v-chip',{staticClass:"red",attrs:{"dark":""}},[_vm._v("Indeferido")]):(item.analise == 1)?_c('v-chip',{staticClass:"green",attrs:{"dark":""}},[_vm._v("Deferido")]):_c('v-chip',{staticClass:"orange",attrs:{"dark":""}},[_vm._v("Em análise")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.analise == null)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.getModal(item, 1), _vm.dadosAnalise.show = true]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Deferir solicitação")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.analise == null)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.analise},on:{"click":function($event){[_vm.getModal(item, 0), _vm.dadosAnalise.show = true]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Indeferir solicitação")])])]}}],null,true)}),_c('AnalisarSolicitacaoModalProcesso',{attrs:{"analise":_vm.dadosAnalise},on:{"registrarAnalise":_vm.registrarAnalise}})],1),_c('Snackbar',{attrs:{"snackbar":_vm.snackbar}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }