import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import EditarPerfil from '../views/EditarPerfil.vue'
import Assinatura from '../views/Assinatura.vue'
import provedor from '../store/index'
import routerAdm from './administrativo'
import routerProcessos from './processos'
import routerAssinatura from './assinatura'
import routerGerencial from './gerencial'
import routerPublic from './public'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            publica: true
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue')
    },
    {
        path: '/perfil/editar',
        name: 'editarperfil',
        component: EditarPerfil
    },
    {
        path: '/processos/assinar',
        name: 'assinatura',
        component: Assinatura
    },
    ...routerAdm,
    ...routerProcessos,
    ...routerAssinatura,
    ...routerGerencial,
    ...routerPublic,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((routeTo, routeFrom, next) => {
    if (!routeTo.meta.publica && !provedor.state.token) {
        return next({ name: 'login' })
    } else if(routeTo.name == 'login' && provedor.state.token) {
        return next({ name: 'dashboard' })
    }
    next()
})

export default router