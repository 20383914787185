<template>
    <div class="page">
        <v-container fluid>
            <Filtros
                ano
                numero
                data
                meus_favoritos
                processoAntigo
                tipoprocesso
                assunto
                filterusuario
                interessado
                :itemsArr="processos"
                :qtdFilters="5"
                @input="applyInput"
                @export="applyExport"
                @pdf="applyPdf"
                @filter="applyFilter"
                @clear="applyClear"
                v-if="loadedProcessos"
            />
            <v-row class="mb-2">
                <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                    <h2 class="mb-0 accent--text">Processos à receber</h2>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="processos"
                class="elevation-1"
                :loading="loading"
                loading-text="Carregando..."
            >
                <template v-slot:[`item.id`]="{ item }">
                    {{ item.numero }}/{{ item.ano }}
                </template>
                <template v-slot:[`item.especificacao`]="{ item }">
                    <v-chip v-if="item.especificacao.length > 50" :title=item.especificacao class="white">{{ item.especificacao.slice(0, 30) }} {{ "..." }}</v-chip>
                    <v-chip v-else class="white">{{ item.especificacao }}</v-chip>
                </template>
                <template v-slot:[`item.dataEnvio`]="{ item }">
                    {{ $moment(item.dataEnvio).format('DD/MM/YYYY') }}
                </template>
                <template v-slot:[`item.tempo`]="{ item }">
                    {{ $moment(item.dataEnvio).startOf('minute').fromNow() }}
                </template>
                <template v-slot:[`item.actions`]="{ item}">
                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                v-scroll-to="{
                                    el: '#personalizar',
                                    easing: [.6, .80, .30, 1.9],
                                    duration: 2000
                                }"
                                @click="getDocs(item)"
                            >
                                <v-icon color="accent">mdi-folder-open</v-icon>
                            </v-btn>
                        </template>
                        <span>Gerenciar documentos</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="[getProcesso(item.id)]"
                            >
                                <v-icon color="accent">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver detalhes</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="
                                    [
                                        getProcessoModal(item.id, 'receber', item.usuarioSugestao),
                                        dadosProcesso.show = true
                                    ]"
                            >
                                <v-icon color="accent">mdi-archive-arrow-down</v-icon>
                            </v-btn>
                        </template>
                        <span>Receber processo</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="
                                    [
                                        getProcessoModal(item.id, 'devolver', null),
                                        dadosProcessoDevolver.show = true
                                    ]"
                            >
                                <v-icon color="accent">mdi-undo</v-icon>
                            </v-btn>
                        </template>
                        <span>Devolver processo</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <VerModalProcesso :processo="verProcesso"></VerModalProcesso>
            <ReceberModalProcesso :processo="dadosProcesso" @processo="receberProcesso"></ReceberModalProcesso>
            <DevolverModalProcesso :processo="dadosProcessoDevolver" @processo="devolverProcesso"></DevolverModalProcesso>
            <div id="personalizar">
                <PersonalizarProcesso :processo="personalizarProcesso" @tramitarProcessoDocs="enviarProcessoDocs" @arvoreDocs="getDocs" @limparDocs="getProcessos"  @sendDoc="createDoc"></PersonalizarProcesso>
            </div>
        </v-container>
        <Snackbar :snackbar="snackbar"></Snackbar>
    </div>
</template>
<script>
import { processoMixin, filterMixin } from '../../mixins'
import VerModalProcesso from '../../components/Modais/VerModalProcesso.vue'
import ReceberModalProcesso from '../../components/Modais/ReceberModalProcesso.vue'
import DevolverModalProcesso from '../../components/Modais/DevolverModalProcesso.vue'
import PersonalizarProcesso from '../../components/PersonalizarProcesso.vue'
import Snackbar from '../../components/Snackbar.vue'

export default {
    name:'receber-processo',
    mixins: [processoMixin, filterMixin],
    components: {
        Snackbar,
        VerModalProcesso,
        ReceberModalProcesso,
        DevolverModalProcesso,
        PersonalizarProcesso
    },
    data: () => ({
        loadedProcessos: false,
        anotacao: {
            show: false,
            tramitacao: null,
            usuario_id: null,
            anotacoes: null,
        },
        dadosProcesso: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: null,
            usuario_id_sugestao: null,
            usuarioSugestao: null,
            observacao: null,
            setores: [],
        },
        dadosProcessoDevolver: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: null,
            observacao: null,
            setores: [],
        },
        personalizarProcesso: {
            show: false,
            gerenciar: false,
            receber: true,
            id: null,
            docs: [],
            filename: null,
            numero: null,
            ano: null,
            nivel_id: null,
            enableEditor: null
        },
        verProcesso: {
            show: false,
            id: null,
            ano: null,
            setor_id_origem: null,
            setor_id_atual: null,
            usuario_id: null,
            observacao: null,
            especificacao: null,
            processo_antigo: null,
            tramitacoes: [],
            situacao_id: null,
            assunto_id: null,
            organizacao_id: null,
        },
        snackbar: {
            show: false,
            timeout: 6000,
            align: 'right'
        },
        loading: false,
        processos: [],
        headers: [
            { text: 'Processo', value: 'id', width: '10%' },
            { text: 'Setor origem', value: 'setorOrigem', width: '10%' },
            { text: 'Setor destino', value: 'setorDestino', width: '10%' },
            { text: 'Tipo', value: 'tipoProcesso', width: '10%' },
            { text: 'Especificação', value: 'especificacao', width: '10%' },
            { text: 'Assunto', value: 'assunto', width: '10%' },
            { text: 'Usuário sugerido', value: 'usuarioSugestao', width: '10%' },
            { text: 'Tempo decorrido', value: 'tempo', width: '10%' },
            { text: 'Ações', value: 'actions', width: '10%', align: 'right' },
        ],
    }),
    watch: {
        tipoprocesso_id () {
            this.getProcessos()
        },
        assunto_id () {
            this.getProcessos()
        },
        nivel_id () {
            this.getProcessos()
        },
        situacao_id () {
            this.getProcessos()
        },
        interessado () {
            this.getProcessos()
        },
        ano () {
            this.getProcessos()
        },
        filterusuario () {
            this.getProcessos()
        },
    },
    methods: {
        applyInput (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.getProcessos()
        },
        applyExport (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.exportar()
        },
        applyPdf (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.data) this.data = inputed.data
                else this.data = null
            if (inputed.meus_favoritos) this.meus_favoritos = inputed.meus_favoritos
                else this.meus_favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.pdf()
        },
        exportar () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/exportreceber`, 
                filters,
                //{file_name: 'temp.xlsx'},
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "receber.xlsx")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        pdf () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/pdfreceber`, 
                filters,
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "receber.pdf")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        getProcessos () {
            const filters = {
                'ano': this.ano ,
                'numero': this.numero,
                'data': this.data,
                'meus_favoritos': this.meus_favoritos,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
                'usuario_id_sugestao': this.filterusuario
            }
            this.loading = true
            this.personalizarProcesso.show = false;
            this.axios.post(`processo/consulta/${this.$store.state.usuario.id}/receber`,filters).then((res) => {
                this.processos = res.data
            }).finally(() => {
                this.loading = false
                this.loadedProcessos = true;
            })
        },
        getDocs(item) {
            this.personalizarProcesso.docs  = []

            let qtdProcessos = item.arvore.length;
            
            for (var p = 0; p < qtdProcessos; p++){
                
                let processo = item.arvore[p]
                
                this.personalizarProcesso.docs.push({
                    id: p,
                    nome: processo.numero+'/'+processo.ano,
                    icon: 'mdi-folder',
                    children: []    
                })

                let qtdDocumentos = processo.documentos.length;

                for (var d = 0; d < qtdDocumentos; d++){

                    let documento = processo.documentos[d]

                    let icon
                    let nome = '#' + documento.numero_doc 
                        + ' ' + documento.tipo_documento.nome

                    if (documento.excluido == 0){
                        if (documento.gerado == 0)
                            icon = 'mdi-download'
                        else
                            if (documento.assinado == 1)
                                icon = 'mdi-file-sign'
                            else
                                icon = 'mdi-file-document-edit'
                    } else
                        icon = 'mdi-file-remove'
                    
                    this.personalizarProcesso.docs[p].children.push({
                        id:                 documento.id,                   
                        processo_id:        documento.processo_id,
                        nome:               nome,  
                        gerado:             documento.gerado,
                        excluido:           documento.excluido,
                        body:               documento.body,
                        titulo:             documento.titulo,
                        filename:           documento.filename,
                        icon:               icon,
                        criar:              0,
                        upload:             0,
                        bloco_id:           documento.bloco_id,
                        tipodocumento_id:   documento.tipo_documento.id,                    
                    })
                }
            }
            this.personalizarProcesso.id            = item.id
            this.personalizarProcesso.numero        = item.numero
            this.personalizarProcesso.ano           = item.ano
            this.personalizarProcesso.nivel_id      = item.nivel_id
            this.showTableProcessos                 = false
            this.personalizarProcesso.show          = true
            this.personalizarProcesso.enableEditor  = false
        },
        receberProcesso (value) {
            this.dadosProcesso = value.processo
            this.axios.post(`processo/processo/${this.dadosProcesso.id}/receber`, this.dadosProcesso).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        devolverProcesso (value) {
            this.dadosProcessoDevolver = value.processo
            this.dadosProcessoDevolver.setor_id_destino = value.processo.setor_id_origem
            this.dadosProcessoDevolver.setor_id_origem = value.processo.setor_id_destino
            this.axios.post(`processo/processo/${this.dadosProcessoDevolver.id}/devolver`, this.dadosProcessoDevolver).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        getProcessoModal (id, identifier, usuarioSugestao) {
            this.axios.get(`processo/processo/${id}`).then((res) => {
                const p = res.data[0]
                var origem
                var destino
                var usuario_id_sugestao
                if (p.tramitacoes.length > 0) {
                    origem  = p.tramitacoes[p.tramitacoes.length - 1].setor_id_origem
                    destino = p.tramitacoes[p.tramitacoes.length - 1].setor_id_destino
                    usuario_id_sugestao = p.tramitacoes[p.tramitacoes.length - 1].usuario_id_sugestao
                } else {
                    origem  = p.setor_id_origem
                    destino = p.setor_id_atual
                }
                switch (identifier) {
                    case 'receber':
                        this.dadosProcesso = {
                            ...this.dadosProcesso,
                            id: p.id,
                            numero: p.numero,
                            ano: p.ano,
                            setor_id_origem: origem,
                            setor_id_destino: destino,
                            usuario_id: this.$store.state.usuario.id,
                            usuario_id_sugestao: usuario_id_sugestao,
                            usuarioSugestao: usuarioSugestao,
                            observacao: null,
                        }
                    break
                    case 'devolver':
                        this.dadosProcessoDevolver = {
                            ...this.dadosProcessoDevolver,
                            id: p.id,
                            numero: p.numero,
                            ano: p.ano,
                            setor_id_origem: origem,
                            setor_id_destino: destino,
                            usuario_id: this.$store.state.usuario.id,
                            observacao: null,
                        }
                    break
                }
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        resetProcesso () {
            this.verProcesso = {
                ...this.verProcesso,
                id: null,
                ano: null,
                setor_id_origem: null,
                setor_id_atual: null,
                usuario_id: null,
                usuario_id_sugestao: null,
                observacao: null,
                especificacao: null,
                processo_antigo: null,
                situacao_id: null,
                assunto_id: null,
                organizacao_id: null,
                tramitacoes: []
            }
        }
    },
    mounted () {
        this.getProcessos()
    }
}
</script>