<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="9" xl="9">
                        <h2 class="mb-0 accent--text">Modelos</h2>
                    </v-col>
                </v-row>
                
                <v-row class="mb-2">
                    <v-col
                        sm="5"
                        xs="5"
                    >
                        <v-autocomplete
                            label="Informe o setor"
                            v-model="setor_filter"
                            :items="setores"
                            item-value="id"
                            item-text="name"
                            required
                            :rules="[rules.required]"
                            @change="[getModelos()]"
                        />
                    </v-col>
                    <v-col sm="5"
                            xs="5">
                        <v-text-field v-model="search" prepend-icon="mdi-feature-search-outline" label="Pesquisar" clearable></v-text-field>
                    </v-col>
                    <v-col sm="2"
                        xs="2" class="justify-end content">
                        <v-btn
                            :disabled="!setor_filter"
                            block
                            color="accent"
                            @click="[zerarCampos(), viewModelo = true]"
                            class="mb-2"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            Novo
                        </v-btn>

                    </v-col>
                </v-row>
                
                <v-data-table
                    :headers="headers"
                    :items="modelos"
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >

                    <template v-slot:[`item.ativo`]="{ item }">
                        <v-chip v-if="item.ativo" class="green" dark>Ativo</v-chip>
                        <v-chip v-else class="red" dark>Inativo</v-chip>
                    </template>

                    <template v-slot:[`item.privado`]="{ item }">
                        <v-chip v-if="item.privado" class="green" dark>Privado</v-chip>
                        <v-chip v-else class="orange white--text">Público</v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[load(item), viewModelo = true]"
                                >
                                    <v-icon color="blue">mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="item.ativo ? false : true"
                                    icon
                                    @click="[inactive(item.id)]"
                                >
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Inativar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>

                <v-dialog width="40%" v-model="viewModelo" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                            Gerenciar modelo
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                @click="[modeloAtual = null, viewModelo = false]"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                <v-autocomplete v-if="modelo.id==null"
                                    label="Tipo de documento"
                                    :items="tipodocs"
                                    item-text="nome"
                                    item-value="id"
                                    v-model="modelo.tipodocumento_id"
                                />
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">                            
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-autocomplete v-if="modelo.id==null"
                                        label="Informe o setor"
                                        v-model="modelo.setor_id"
                                        :items="setores"
                                        item-value="id"
                                        item-text="name"
                                        required
                                        :rules="[rules.required]"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col
                                    cols="12"
                                    sm="9"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="modelo.titulo"
                                        label="Título"
                                        required
                                        :rules="[rules.required]"
                                        counter
                                        maxlength="100"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="3"
                                    xs="12"
                                >
                                    <v-checkbox
                                        v-model="modelo.privado"
                                        label="Privado"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                            <ckeditor
                                v-model="modelo.body"
                                required
                                :rules="[rules.required]"
                                :config="editorConfig">
                            </ckeditor>

                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="modelo.id==null"
                                color="primary"
                                text
                                @click="[create()]"
                            >
                                Criar
                            </v-btn>
                            <v-btn v-else
                                color="primary"
                                text
                                @click="[update()]"
                            >
                                Atualizar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'Modelo',
    components: {
        Snackbar
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        modeloAtual: null,
        search: '',
        loading: false,
        viewModelo: false,
        organizacao_id: null,
        setor_filter: null,
        showbotao: false,
        modelo: {
            id: null,
            organizacao_id: null,
            setor_id: null,
            usuario_id: null,
            titulo: null,
            tipodocumento_id:null,
            body: null,
            privado: 1,
        },
        headers: [
            { text: 'Tipo de documento', value: 'tipo_documento.nome', width: '30%' },
            //{ text: 'Setor', value: 'setor.nome', width: '25%' },
            { text: 'Título', value: 'titulo', width: '25%' },
            { text: 'Situação', value: 'ativo', width: '14%' },
            { text: 'Privado', value: 'privado', width: '10%' },
            { text: 'Ações', value: 'actions', width: '15%', align: 'right' },
        ],
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
        modelos: [],
        setores: [],
        editorConfig: {
            extraPlugins: "language,font,image2,justify,exportpdf",
            allowedContent: true,
            editorDisabled: false,
            filebrowserImageBrowseLinkUrl:
                "https://onedrive.live.com/?id=59FFBD6D7046F78D%21105&cid=59FFBD6D7046F78D",
            font_names:
                    "Arial/Arial, Helvetica, sans-serif;" +
                    "Times New Roman/Times New Roman, Times, serif;" +
                    "Verdana",
            toolbar: [
                { name: 'document', items: [ 'Print', 'exportpdf' ] },
                { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
                { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting' ] },
                { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                { name: 'links', items: [ 'Link', 'Unlink' ] },
                { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
                { name: 'insert', items: [ 'Image', 'Table' ] },
                { name: 'tools', items: [ 'Maximize' ] },
                { name: 'editing', items: [ 'Scayt' ] }
            ],
            removePlugins: 'autoembed,embedsemantic,image2,sourcedialog,showborders',
            disallowedContent: 'img{width,height,float}',
            extraAllowedContent: 'img[width,height,align]'
        },
        tipodocs:[],
       
    }),
    methods: {
        getSetores () {
            this.setores = []
            this.axios.get('gerencial/auth/sectors/user').then((res) => {
                for (var s = 0; s < res.data.length; s++){
                    var setor   = res.data[s]
                    if (setor.active === true)
                        this.setores.push(setor)
                }
                if (this.setores.length === 1)
                    this.modelo.setor_id = this.setores[0].id
            })
        },
        getModelos () {
            this.showbotao=true
            this.loading = true
            this.$axios.get(`processo/modelo/${this.organizacao_id}/org`, {
                params: { 'ativo': 1, "setor": this.setor_filter }
                }).then((res) => {
                this.modelos = res.data
            }).finally(() => {
                this.loading = false
            })
        },
        create () {
            if (!this.$refs.formDados.validate())
                return false
            this.modelo.organizacao_id  = this.$store.state.organizacao.id
            this.modelo.usuario_id      = this.$store.state.usuario.id
            this.axios.post('processo/modelo', this.modelo).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.viewModelo = false
                this.getModelos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        load (item) {
            this.modelo.id          = item.id
            this.modelo.titulo      = item.titulo
            this.modelo.body        = item.body
            this.modelo.privado     = item.privado
        },
        update () {
            if (!this.$refs.formDados.validate())
                return false
            this.axios.put(`processo/modelo/${this.modelo.id}`, this.modelo).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.viewModelo = false
                this.getModelos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        inactive (id) {
            let con = confirm("Deseja inativar esse modelo?")
                if (con) {
                this.axios.patch(`processo/modelo/${id}`).then((res) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: res.data.message,
                        color: 'green'
                    }
                    this.getModelos()
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    }
                })
            }
        },
        zerarCampos () {
            this.modelo.id                = null
            this.modelo.titulo            = null
            this.modelo.setor_id          = null
            this.modelo.body              = null
            this.modelo.tipodocumento_id  = null
            this.modelo.privado           = 1
        },
        getTipoDocs(){
            this.axios.get(`processo/tipodocumento/${this.$store.state.organizacao.id}/org`, {
                params: { 'ativo': 1 },
            }).then((res) => {
                this.tipodocs = res.data
            })
        },
    },
    mounted () {
        this.organizacao_id = this.$store.state.organizacao.id;
        this.getTipoDocs()
        this.getSetores()
        this.$refs.formDados.validate()
    }
}
</script>