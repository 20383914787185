<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Organizações</h2>
                    </v-col>
                </v-row>
                <v-row class="mb-2">
                    <v-col xs="7">
                        <v-text-field v-model="search" prepend-icon="mdi-feature-search-outline" label="Pesquisar" clearable></v-text-field>
                    </v-col>
                    <!-- <v-col xs="5" sm="6" md="5" lg="4" xl="3" class="justify-end content">
                        <v-btn
                            block
                            color="accent"
                            @click="[zerarCampos(), viewOrganizacoes = true]"
                            dark
                            class="mb-2"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            Nova organização
                        </v-btn>
                    </v-col> -->
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="organizacoes"
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >
                    <template v-slot:[`item.active`]="{ item }">
                        <v-chip v-if="item.active" class="green" dark>Ativo</v-chip>
                        <v-chip v-else class="red" dark> Inativo </v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[copyUrl(1, item.id)]">
                                    <v-icon color="blue">mdi-file-find</v-icon>
                                </v-btn>
                            </template>
                            <span>Link da consulta pública</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[copyUrl(2, item.id)]">
                                    <v-icon color="blue">mdi-clipboard-account</v-icon>
                                </v-btn>
                            </template>
                            <span>Link do cadastro de usuário externo</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[loadOrganizacao(item), viewOrganizacoes = true]"
                                >
                                    <v-icon color="blue">mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="item.active ? false : true"
                                    icon
                                    @click="[inativar(item)]"
                                >
                                    <v-icon color="red">mdi-minus-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Inativar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-dialog width="40%" v-model="viewOrganizacoes" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                            Gerenciar organização
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                @click="[viewOrganizacoes = false]"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="organizacao.name"
                                        label="Nome"
                                        required
                                        :rules="[rules.required]"
                                        counter
                                        maxlength="100"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="organizacao.id==null"
                                color="primary"
                                text
                                @click="[create()]"
                            >
                                Criar
                            </v-btn>
                            <v-btn v-else
                                color="primary"
                                text
                                @click="[update()]"
                            >
                                Atualizar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'Organizacao',
    components: {
        Snackbar
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        search: '',
        loading: false,
        viewOrganizacoes: false,
        url: null,
        msg: null,
        organizacao: {
            id: null,
            name: null,
            active: true,
        },
        headers: [
            { text: 'Nome', value: 'name', width: '60%' },
            { text: 'Situação', value: 'active', width: '20%' },
            { text: 'Ações', value: 'actions', width: '20%', align: 'right' },
        ],
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
        organizacoes: [],
    }),
    methods: {
        getOrganizacoes () {
            this.loading = true
            this.axios.get('gerencial/organizations/getall').then((res) => {
                this.organizacoes = res.data
            }).finally(() => {
                this.loading = false
            })
        },
        create () {
            if (!this.$refs.formDados.validate())
                return false
            this.axios.post('gerencial/organizations/create', this.organizacao).then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: 'Registro inserido com sucesso',
                    color: 'green'
                }
                this.viewOrganizacoes = false
                this.getOrganizacoes()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data.message[0].error[0],
                    color: 'red',
                }
            })
        },
        loadOrganizacao (item) {
            this.organizacao.id     = item.id
            this.organizacao.name   = item.name
            this.organizacao.active = true
        },
        copyUrl (tipo, id) {
            try {
                if (tipo == 1){
                    this.url = process.env.VUE_APP_PUBLIC_URL+"consultapublica?idorganization="+id
                    this.msg = "Endereço da consulta pública copiado"
                }else{
                    this.url = process.env.VUE_APP_PUBLIC_URL+"usuarioexterno?idorganization="+id
                    this.msg = "Endereço do cadastro de usuário externo"
                }
                alert(this.url);
                navigator.clipboard.writeText(this.url);
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: this.msg,
                    color: 'green'
                }                
            } catch (error) {
                console.log(error)
            }
            
        },
        update () {
            if (!this.$refs.formDados.validate())
                return false
            this.axios.put(`gerencial/organizations/update`, this.organizacao).then(() => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: 'Registro atualizado com sucesso',
                    color: 'green'
                }
                this.viewOrganizacoes = false
                this.getOrganizacoes()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data.message[0].error[0],
                    color: 'red',
                }
            })
        },
        inativar (item) {
            let con = confirm("Deseja inativar essa organização?")
            if (con) {
                this.organizacao.id     = item.id
                this.organizacao.name   = item.name
                this.organizacao.active = false
                this.update()    
            }
        },
        zerarCampos () {
            this.organizacao.id     = null
            this.organizacao.name   = null
            this.organizacao.active = true
        }
    },
    mounted () {
        this.getOrganizacoes()
        this.$refs.formDados.validate()
    }
}
</script>