export const logoutMixin = {
    methods: {
        logout () {
            localStorage.clear()
            this.$store.commit('DESLOGAR_USUARIO')
            this.$router.push({ name: 'login' })
        },
    }
}

export const processoMixin = {
    methods: {
        enviarProcesso (value) {
            this.dadosProcesso = value.processo
            this.axios.post(`processo/processo/${this.dadosProcesso.id}/enviar`, this.dadosProcesso).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getProcessos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        getProcesso (id) {
            this.loading = true
            this.resetProcesso()
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}processo/${id}`).then((res) => {
                const p = res.data[0]
                this.verProcesso = {
                    ...this.verProcesso,
                    id: p.id,
                    numero: p.numero,
                    ano: p.ano,
                    setor_id_origem: p.setor_origem.nome,
                    setor_id_atual: p.setor_atual.nome,
                    usuario_id: null, //p.usuario_criacao.nome,
                    observacao: p.observacao,
                    situacao: p.situacao_id,
                    assunto: p.assunto.nome,
                    organizacao: null, //p.organizacao.nome,
                    tipo: p.tipo_processo.nome,
                    nivel: p.nivel.nome,
                    interessado: p.interessado,
                    especificacao: p.especificacao,
                    processo_antigo: p.processo_antigo,
                    processosRelacionados: p.processosRelacionados,
                    processosJuntados: p.processosJuntados
                }

                for (var i = 0; i < p.tramitacoes.length; i++) {
                    // let nameUser;

                    // this.axios.get(`${process.env.VUE_APP_BASE_URL}gerencial/users/getbyid/${p.tramitacoes[i].usuario_id}`).then((resUser) => {
                    //     nameUser = resUser.data.name;
                    // });
    

                    this.verProcesso.tramitacoes.push({
                        id: p.tramitacoes[i].id,
                        situacao_id: p.tramitacoes[i].situacao_id,
                        situacao: p.tramitacoes[i].situacao.nome,
                        setor_id_origem: p.tramitacoes[i].setor_origem.nome,
                        setor_id_destino: p.tramitacoes[i].setor_destino.nome,
                        nome: p.tramitacoes[i].situacao.nome,
                        usuario: p.tramitacoes[i].usuario.nome,
                        observacao: p.tramitacoes[i].observacao,
                        data: p.tramitacoes[i].created_at,
                        anotacoes: [],
                    })
                    if (p.tramitacoes[i].anotacoes.length > 0) {
                        for (var j = 0; j < p.tramitacoes[i].anotacoes.length; j++) {
                            this.verProcesso.tramitacoes[i].anotacoes.push({
                                anotacao: p.tramitacoes[i].anotacoes[j].anotacoes,
                                usuario: p.tramitacoes[i].anotacoes[j].usuario.nome,
                                data: p.tramitacoes[i].anotacoes[j].created_at
                            })
                        }
                    }
                }
                this.verProcesso.show = true
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            }).finally(() => {
                this.loading = false
            })
        }
    }
}

export const filterMixin = {
    components: {
        Filtros: () => import('./components/custom/Filtros')
    },
    data: () => ({
        ano: null,
        numero: null,
        numero_doc_upload: null,
        origem_doc_upload: null,
        body: null,
        processoAntigo: null,
        organizacao_id: null,
        tipodocumento_id: null,
        tipoprocesso_id: null,
        assunto_id: null,
        nivel_id: null,
        situacao_id: null,
        interessado: null,
        filterusuario: null
    }),
    watch: {
        //sobrescrever métodos abaixo em caso de métodos diferentes
        /*
        tipodocumento_id () {
            this.getProcessos()
        },
        assunto_id () {
            this.getProcessos()
        },
        nivel_id () {
            this.getProcessos()
        },
        situacao_id () {
            this.getProcessos()
        },
        interessado () {
            this.getProcessos()
        },
        */
    },
    methods:{
        //sobrescrever função abaixo em caso de métodos diferentes
        /*
        applyInput (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.getProcessos()
        },
        */
		applyFilter (filtered) {
			switch (filtered.identifier) {
                case 'organizacao':
					this.organizacao_id = filtered.item.id
                break
				case 'tipodoc':
					this.tipodocumento_id = filtered.item.id
                break
                case 'tipoprocesso':
					this.tipoprocesso_id = filtered.item.id
                break
				case 'assunto':
					this.assunto_id = filtered.item.id
					break
                case 'nivel':
					this.nivel_id = filtered.item.id
					break
                case 'situacao':
                    this.situacao_id = filtered.item.id
                    break
                case 'interessado':
                    this.interessado = filtered.item.nome
                break
                case 'filterusuario':
                    this.filterusuario = filtered.item.id
                break
				default:
                break
			}
		},
		applyClear (filtered) {
			switch (filtered.identifier) {
				case 'tipodoc':
					this.tipodocumento_id = null
                break
                case 'tipoprocesso':
					this.tipoprocesso_id = null
                break
				case 'assunto':
					this.assunto_id = null
                break
                case 'nivel':
					this.nivel_id = null
                break
                case 'situacao':
                    this.situacao_id = null
                break
                case 'interessado':
                    this.interessado = null
                break
                case 'filterusuario':
                    this.filterusuario = null
                break
                case 'organizacao':
                    this.organizacao_id = null
                break    
				default:
                break
			}
		},
	}
}