<template>
    <div class="page">
        <v-container fluid >
            <v-container v-show="showTableProcessos">
            <Filtros v-show="!showCardCreate"
                ano
                numero
                data
                favorito
                processoAntigo
                tipoprocesso
                assunto
                nivel
                interessado
                :itemsArr="rascunhos"
                :qtdFilters="5"
                @input="applyInput"
                @export="applyExport"
                @pdf="applyPdf"
                @filter="applyFilter"
                @clear="applyClear"
                v-if="loadedProcessos"
            />
            <div class="meus-processos" 
                v-show="!showCardCreate">
                <v-row class="mb-2">
                    <v-col xs="12" sm="12" md="8" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Meus rascunhos</h2>
                    </v-col>
                    <v-col xs="12" sm="12" md="4" lg="4" xl="3" class="justify-end content">
                        <v-btn
                            v-bind:disabled="visibleButtonOnCreate"
                            block
                            color="accent"
                            @click="[submit(step), showCardCreate = true, criandoProcesso = true]"
                            :loading="loading"
                            class="mb-2"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            Criar processo
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <v-card v-show="showCardCreate"
                class="mx-auto mb-8"
                max-width="750px"
                width="100%"
            >
                <v-row class="mb-2">
                    <v-col xs="12" sm="12" md="8" lg="8" xl="9">
                        <h2 v-if="processo.id == null" class="mb-0 accent--text">Criar novo processo</h2>
                        <h2 v-else class="mb-0 accent--text">Editar processo {{processo.numero}}/{{processo.ano}}</h2>
                    </v-col>
                </v-row>
                <v-progress-linear :value="((step-1)/(Object.keys(STEPS).length - 1))*100"></v-progress-linear>
                <v-card-title class="title font-weight-regular justify-space-between">
                    <span>{{ titleCard }}</span>
                    <v-avatar
                        color="primary lighten-2"
                        class="subheading white--text"
                        size="24"
                        v-text="step"
                    ></v-avatar>
                </v-card-title>

                <v-window v-model="step">

                    <v-form ref="formDados1"
                        lazy-validation
                        v-model="formValid1">
                        <v-window-item :value="1">
                            <v-card-text>

                                <v-autocomplete
                                    label="Informe o setor de origem"
                                    v-model="processo.setor_id_origem"
                                    :items="setores"
                                    item-value="id"
                                    item-text="name"
                                    required
                                    :rules="[rules.required]"
                                />

                                <v-autocomplete
                                    label="Informe o tipo de processo"
                                    v-model="processo.tipoprocesso_id"
                                    :items="tipos"
                                    item-value="id"
                                    item-text="nome"
                                    required
                                    :rules="[rules.required]"
                                />

                                <v-textarea
                                    label="Especificação"
                                    auto-grow
                                    v-model="processo.especificacao"
                                    hint="Se desejar, escreva alguma especificação do processo"
                                    color="accent"
                                    rows="3"
                                    row-height="25"
                                />
                                
                                <v-text-field
                                    label="Número do processo antigo (se existir)"
                                    v-model="processo.processo_antigo"
                                    type="text"
                                    color="accent"
                                    counter=30
                                    maxlength="30"
                                />

                            </v-card-text>
                        </v-window-item>
                    </v-form>

                    <v-form ref="formDados2"
                        lazy-validation
                        v-model="formValid2">
                        <v-window-item :value="2">
                        <v-card-text>

                            <span class="caption grey--text text--darken-1">
                                Informe o assunto do seu processo
                            </span>
                            <v-autocomplete
                                label="Assunto"
                                v-model="processo.assunto_id"
                                :items="assuntos"
                                item-value="id"
                                item-text="nome"
                                required
                                :rules="[rules.required]"
                            />

                            <v-row v-for="(usuario,index) in interessadoAdd" :key="index">
                                <v-col
                                    cols="11"
                                    sm="11"
                                    xs="11"
                                >
                                    <v-autocomplete
                                        :items="usuarios"
                                        item-text="name"
                                        item-value="id"
                                        v-model="interessados[index]"
                                        label="Selecione os interessados"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col>
                                    <v-btn
                                        @click="[(interessadoAdd += 1)]"
                                        color="primary"
                                        class="mt-3"
                                        x-small
                                        fab
                                        dark
                                        >
                                        +
                                    </v-btn> 
                                    <v-btn
                                    v-if="interessadoAdd > 1 && index > 0"
                                    @click="[removeInteressado()]"
                                    color="red"
                                    class="mt-3 ml-3"
                                    x-small
                                    fab
                                    dark
                                    >
                                    -
                                    </v-btn>   
                                </v-col>
                            </v-row>

                            <span class="caption grey--text text--darken-1">
                                Informe o nível de acesso do processo
                            </span>
                            <div>
                                <v-layout wrap>
                                    <v-flex md3 sm3  v-for="item in niveis" :key="item.id">
                                        <v-radio-group
                                            v-model="processo.nivel_id"
                                            column>
                                            <v-radio
                                                :label="item.nome"
                                                :value="item.id"
                                                hide-details
                                            />
                                        </v-radio-group>
                                    </v-flex>
                                </v-layout>
                            </div>

                            <span class="caption grey--text text--darken-1">
                                Informe a fundamentação legal
                            </span>
                            <v-autocomplete
                                label="Fundamentação legal"
                                v-model="processo.fundamentacao_id"
                                :items="fundamentacoes"
                                item-value="id"
                                item-text="nome"
                            />

                        </v-card-text>
                        </v-window-item>
                    </v-form>

                    <v-window-item :value="3">
                        <v-card-text>
                            <v-textarea
                                label="Observação"
                                auto-grow
                                v-model="processo.observacao"
                                hint="Caso preferir, escreva alguma observação do processo"
                                color="accent"
                                rows="3"
                                row-height="25"
                            />
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="4">
                        <div class="pa-4 text-center">
                            <v-img
                                class="mb-4"
                                contain
                                height="80"
                                :src="require('../../assets/logo_dark.png')"
                            ></v-img>
                            <h3 class="title font-weight-light mb-2"><span class="accent--text"> {{ titleProcesso }} </span></h3>
                            <span class="caption grey--text">Obrigado por usar o Mobby Docs!</span>
                        </div>
                    </v-window-item>
                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        :disabled="step === 1 || processoCreated"
                        color="primary"
                        text
                        @click="step--"
                    >
                        Voltar
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="titleCard != 'Processo criado!' && titleCard != 'Processo atualizado!'"
                        color="primary"
                        text
                        @click="fechar()"
                    >
                        Cancelar
                    </v-btn>

                    <v-btn
                        :disabled="step === 4"
                        color="primary"
                        depressed
                        @click="submit(step+1)"
                    >
                        Avançar
                    </v-btn>

                    <v-btn
                        v-if="titleCard === 'Processo criado!' || titleCard === 'Processo atualizado!'"
                        color="primary"
                        depressed
                        @click="fechar()"
                    >
                        Listar rascunhos
                    </v-btn>

                </v-card-actions>
            </v-card>

            <!--
            <div class="mt-0 mb-4">
                <h2 class="accent--text">Rascunhos</h2>
            </div>
            -->

            <v-data-table
                v-show="!showCardCreate"
                :headers="headers"
                :items="rascunhos"
                :items-per-page="5"
                class="elevation-1"
                :loading="loading"
                loading-text="Carregando..."
            >
                <template v-slot:[`item.id`]="{ item }">
                    {{ item.numero }}/{{ item.ano }}
                </template>
                <template v-slot:[`item.especificacao`]="{ item }">
                    <v-chip v-if="item.especificacao.length > 50" class="white" :title=item.especificacao>{{ item.especificacao.slice(0,40) }} {{ "..." }}</v-chip>
                    <v-chip v-else class="white">{{ item.especificacao }}</v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="[loadRegistro(item), submit(step), showCardCreate = true, criandoProcesso = true]"
                            >
                                <v-icon color="primary">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                v-scroll-to="{
                                    el: '#personalizar',
                                    easing: [.6, .80, .30, 1.9],
                                    duration: 2000
                                }"
                                @click="getDocs(item)"
                            >
                                <v-icon color="accent">mdi-folder-open</v-icon>
                            </v-btn>
                        </template>
                        <span>Gerenciar documentos</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="[getProcessoModal(item.id), 
                                dadosProcesso.setor_id_destino = null,
                                dadosProcesso.unidades = [],
                                dadosProcesso.show = true]"
                            >
                                <v-icon color="accent">mdi-arrow-right</v-icon>
                            </v-btn>
                        </template>
                        <span>Enviar processo</span>
                    </v-tooltip>
                    <v-menu
                        bottom
                        left
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click="[excluirRascunho(item.id)]"
                                >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                        >
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                        <span>Excluir rascunho</span>
                                    </template>
                                </v-tooltip>
                            </v-list-item>
                            <v-list-item
                                @click="[pdfCapa(item.id)]"
                                >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }" >
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                        >
                                            <v-icon color="accent">mdi-printer</v-icon>
                                        </v-btn>
                                        <span>Capa do processo</span>
                                    </template>
                                </v-tooltip>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                </template>
            </v-data-table>
            </v-container>
            <VerModalProcesso :processo="verProcesso"></VerModalProcesso>
            <EnviarModalProcesso :processo="dadosProcesso" @processo="enviarProcesso"></EnviarModalProcesso>
            <div id="personalizar">
                <PersonalizarProcesso :processo="personalizarProcesso" @tramitarProcessoDocs="enviarProcessoDocs" @arvoreDocs="getDocs" @limparDocs="getRascunhos" @sendDoc="createDoc"></PersonalizarProcesso>
            </div>
        </v-container>
        <v-btn
            v-scroll="onScroll"
            v-show="fab"
            class="mx-2"
            fab
            dark
            fixed
            bottom
            small
            right
            color="primary"
            @click="toTop"
        >
            <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <Snackbar :snackbar="snackbar"></Snackbar>
    </div>
</template>
<script>
const STEPS = {
    step1: 1,
    step2: 2,
    step3: 3,
    step4: 4
}
import { filterMixin } from '../../mixins'
import VerModalProcesso from '../../components/Modais/VerModalProcesso.vue'
import EnviarModalProcesso from '../../components/Modais/EnviarModalProcesso.vue'
import PersonalizarProcesso from '../../components/PersonalizarProcesso.vue'
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'meus-processo',
    mixins: [
        filterMixin
    ],
    components: {
        VerModalProcesso,
        EnviarModalProcesso,
        PersonalizarProcesso,
        Snackbar,
    },
    props: {
        itemsPerPage: Number,
    },
    data: () => ({
        fab: false,
        loadedProcessos: false,
        personalizarProcesso: {
            show: false,
            gerenciar: true,
            rascunho: true,
            id: null,
            docs: [],
            filename: null,
            ano: null,
            numero: null,
            nivel_id: null,
            setor_id_atual: null,
            enableEditor: false
        },
        anotacao: {
            show: false,
            tramitacao: null,
            usuario_id: null,
            anotacoes: null,
        },
        verProcesso: {
            show: false,
            id: null,
            ano: null,
            numero: null,
            setor_id_origem: null,
            setor_id_atual: null,
            usuario_id: null,
            observacao: null,
            tramitacoes: [],
            situacao_id: null,
            assunto_id: null,
            organizacao_id: null,
        },
        dadosProcesso: {
            show: false,
            id: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: null,
            organizacao: null,
            observacao: null,
            processo_antigo: null,
            unidades: []
        },
        STEPS: STEPS,
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        showCardCreate: false,
        showTableProcessos : true,
        processo_doc: [],
        titleCard: "",
        titleProcesso: "",
        loading: false,
        processoCreated: false,
        processo: {
            id: null,
            ano: null,
            numero: null,
            organizacao_id: null,
            tipoprocesso_id: null,
            assunto_id: null,
            setor_id_origem: null,
            observacao: null,
            interessado: null,
            especificacao: null,
            processo_antigo: null,
            usuario_id: null,
            nivel_id: null,
            fundamentacao_id: null,
        },
        step: null,
        organizacoes: [],
        tipos: [],
        unidades: [],
        setores: [],
        assuntos: [],
        usuarios: [],
        interessados: [],
        interessadoAdd: 1,
        niveis: [],
        fundamentacoes: [],
        rascunhos: [],
        QtdDocumentos:null,
        QtdAgrupamentos:null,
        headers: [
            { text: 'Processo', value: 'id', width: '10%' },
            { text: 'Setor', value: 'setor_origem.nome', width: '10%' },
            { text: 'Tipo de processo', value: 'tipo_processo.nome', width: '20%' },
            { text: 'Especificação', value: 'especificacao', width: '20%' },
            { text: 'Assunto', value: 'assunto.nome', width: '20%' },
            { text: 'Ações', value: 'actions', width: '18%', align: 'right' },
        ],
        formValid1: false,
        formValid2: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
    }),
    watch: {
        tipoprocesso_id () {
            this.getRascunhos()
        },
        assunto_id () {
            this.getRascunhos()
        },
        nivel_id () {
            this.getRascunhos()
        },
        situacao_id () {
            this.getRascunhos()
        },
        interessado () {
            this.getRascunhos()
        },
    },
    computed: {
        visibleButtonOnCreate () {
            return this.showCardCreate
        }
    },
    methods: {
        applyInput (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.favorito) this.favoritos = inputed.favorito
                else this.favoritos = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.getRascunhos()
        },
        applyExport (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.exportar()
        },
        applyPdf (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.pdf()
        },
        onScroll (e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset ||   e.target.scrollTop || 0
            this.fab = top > 20
        },
        toTop () {
            this.$vuetify.goTo(0)
        },
        fechar () {
            window.location.reload()
        },
        exportar () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/exportrascunho`, 
                filters,
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "rascunho.xlsx")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        pdfCapa(id){
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}processo/pdfcapa/`+id, 
                {file_name: 'temp.pdf'}, 
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'capa.pdf');
                    document.body.appendChild(link);
                    link.click();
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response)[0]][0],
                        color: 'red',
                }
            })
        },
        pdf () {
            const filters = {
                'sectors': this.$store.state.setores,
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$store.state.usuario.id}/pdfrascunho`, 
                filters,
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/pdf'
                    }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "rascunho.pdf")
                    document.body.appendChild(link)
                    link.click()
            })
        },
        enviarProcessoDocs(item){
            this.dadosProcesso.setor_id_destino = null
            this.dadosProcesso.unidades = []
            this.dadosProcesso.show = true
            this.getProcessoModal(item.id);
        },
        getDocs(item) {
            this.processo_doc = item
            this.axios.get(`processo/documento/${item.id}/documentos`).then((res) => {
                this.personalizarProcesso.docs = []

                this.QtdDocumentos = 10;
                this.QtdAgrupamentos = parseInt(res.data.length/ this.QtdDocumentos); 
                
                if(this.QtdAgrupamentos > 0){
                    for (var p = 1; p <= this.QtdAgrupamentos; p++) {
                        let QuantidadeInicio
                        let QuantidadeFinal 
                        if(p == 1){
                            QuantidadeInicio = p
                        }else{
                            QuantidadeInicio = ((p-1) * this.QtdDocumentos) + 1   
                        }

                        QuantidadeFinal = (p * this.QtdDocumentos);

                        this.personalizarProcesso.docs.push({
                            id: p,
                            nome: QuantidadeInicio +"-"+QuantidadeFinal,
                            children: []
                            },);
                    }    
                }
                
                let contadorPasta = 0;
                let contadorDoc = 0;
                for (var d = 0; d < res.data.length; d++) {
                    
                    let icon
                    if (res.data[d].excluido == 0){
                        if (res.data[d].gerado == 0)
                            icon = 'mdi-download'
                        else
                            if (res.data[d].assinado == 1)
                                icon = 'mdi-file-sign'
                            else
                                icon = 'mdi-file-document-edit'
                    } else
                        icon = 'mdi-file-remove'

                    let nome = '#' + res.data[d].numero_doc 
                        + ' ' + res.data[d].tipo_documento.nome

                    if (res.data[d].nome_doc_upload)
                        nome = nome + ' - ' + res.data[d].nome_doc_upload
                    
                    if(this.QtdAgrupamentos > 0 && contadorPasta != this.QtdAgrupamentos){
                         contadorDoc++;

                        this.personalizarProcesso.docs[contadorPasta].children.push({
                            id: res.data[d].id,                     // codigo do documento
                            processo_id: res.data[d].processo_id,   // codigo do processo
                            nome: nome,
                            gerado: res.data[d].gerado,
                            excluido: res.data[d].excluido,
                            body: res.data[d].body,
                            titulo: res.data[d].titulo,
                            filename: res.data[d].filename,
                            icon: icon,
                            criar: 0,
                            upload: 0,
                            bloco_id: res.data[d].bloco_id,
                            tipodocumento_id:res.data[d].tipo_documento.id,    
                        })
                       
                        if(contadorDoc == this.QtdDocumentos){
                            contadorDoc = 0;
                            contadorPasta++;
                        }

                    }else{

                        let nome = '#' + res.data[d].numero_doc 
                            + ' ' + res.data[d].tipo_documento.nome

                        if (res.data[d].nome_doc_upload)
                            nome = nome + ' - ' + res.data[d].nome_doc_upload
                    
                        this.personalizarProcesso.docs.push({
                            id: res.data[d].id,                     // codigo do documento
                            processo_id: res.data[d].processo_id,   // codigo do processo
                            nome: nome,
                            gerado: res.data[d].gerado,
                            excluido: res.data[d].excluido,
                            body: res.data[d].body,
                            titulo: res.data[d].titulo,
                            filename: res.data[d].filename,
                            icon: icon,
                            criar: 0,
                            upload: 0,
                            bloco_id: res.data[d].bloco_id,
                            tipodocumento_id:res.data[d].tipo_documento.id,                    
                        })
                    }
                }

                this.personalizarProcesso.id            = item.id
                this.personalizarProcesso.ano           = item.ano
                this.personalizarProcesso.numero        = item.numero
                this.personalizarProcesso.nivel_id      = item.nivel_id
                this.personalizarProcesso.setor_id_atual= item.setor_id_atual
                
            })
            this.personalizarProcesso.show          = true
            this.personalizarProcesso.enableEditor  = false
            this.showTableProcessos                 = false
            
        },
        createDoc (value) {
            this.axios.post('processo/documento/create', value.doc).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getDocs(this.processo_doc)
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        submit (step) {
            switch (step) {
                case 1:
                    this.showCardCreate             = true
                    this.processo.organizacao_id    = this.$store.state.organizacao.id
                    this.processo.usuario_id        = this.$store.state.usuario.id
                    this.getTipos()
                    this.getSetores()
                    this.titleCard  = 'Informe os dados do processo!'
                    this.step = STEPS.step1
                    break
                case 2:
                    if (!this.$refs.formDados1.validate())
                        break
                    this.getAssuntos()
                    this.getUsuarios()
                    this.getNiveis()
                    this.getFundamentacoes()
                    this.step = STEPS.step2
                    break
                case 3:
                    if (!this.$refs.formDados2.validate())
                        break
                    this.processo.interessados    = this.interessados
                    this.step = STEPS.step3
                    break
                case 4:
                    if (this.processo.id == null)
                        this.createProcesso()
                    else   
                        this.updateProcesso()
                    this.step = STEPS.step4
                    break
                default:
                    this.step = step
            }
        },
        getRascunhos () {
            const filters = {
                'ano': this.ano,
                'numero': this.numero,
                'data': this.data,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.loading = true
            this.showTableProcessos = true;
            this.personalizarProcesso.show = false;
            this.axios.post(`processo/consulta/${this.$store.state.usuario.id}/rascunho`, filters).then((res) => {
                this.rascunhos = res.data
            }).finally(() => {
                this.loading = false
                this.loadedProcessos = true
            })
        },
        createProcesso () {
            this.axios.post('processo/processo', this.processo).then((res) => {
                let p = res.data.data
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.titleCard = 'Processo criado!'
                this.titleProcesso = 'Processo criado: ' + p.numero + '/' + p.ano
                this.processoCreated = true
                this.getRascunhos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
                this.step--
            })
            return 'Account created'
        },
        updateProcesso () {
            this.axios.put(`processo/processo/${this.processo.id}`, this.processo).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.titleCard = 'Processo atualizado!'
                this.titleProcesso = res.data.message
                this.processoCreated = true
                this.getRascunhos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
                this.step--
            })
            return 'Account created'
        },
        getSetores () {
            this.setores = []
            this.axios.get('gerencial/auth/sectors/user').then((res) => {
                for (var s = 0; s < res.data.length; s++){
                    var setor   = res.data[s]
                    if (setor.active === true)
                        this.setores.push(setor)
                }
                if (this.setores.length === 1)
                    this.processo.setor_id_origem = this.setores[0].id
            })
        },
        getTipos () {            
            this.axios.get(`processo/tipoprocesso/${this.$store.state.organizacao.id}/org`, {
                params: { 'ativo': 1 },
            }).then((res) => {
                this.tipos = res.data
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
                this.step--
            })
        },
        getAssuntos () {
            this.axios.get(`processo/assunto/${this.$store.state.organizacao.id}/org`, {
                params: { 'ativo': 1 },
            }).then((res) => {
                this.assuntos = res.data.map((elem) => {
                    return {
                      id: elem.id,
                      nome: `${elem.nome} (${elem.grupo_assunto.nome})`,
                    };
                  });

            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
                this.step--
            })
        },
        getUsuarios () {
            this.usuarios = []
            this.axios.get('gerencial/auth/users/organization').then((res) => {
                for (var u = 0; u < res.data.data.length; u++){
                    var usuario   = res.data.data[u]
                    if (usuario.disable === false)
                        this.usuarios.push(usuario)
                }
            }).finally(() => {
            })
        },
        getNiveis () {
            this.axios.get('processo/nivel').then((res) => {
                this.niveis = res.data
                this.processo.nivel_id = 1;
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
                this.step--
            })
        },
        getFundamentacoes () {
            this.axios.get(`processo/fundamentacaolegal/${this.$store.state.organizacao.id}/org`, {
                params: { 'ativo': 1 },
            }).then((res) => {
                this.fundamentacoes = res.data
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
                this.step--
            })
        },
        enviarProcesso (value) {
            this.dadosProcesso = value.processo
            this.axios.post(`processo/processo/${this.dadosProcesso.id}/enviar`, this.dadosProcesso).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.getRascunhos()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        loadRegistro(item) {
            this.processo.id                = item.id
            this.processo.ano               = item.ano
            this.processo.numero            = item.numero
            this.processo.setor_id_origem   = item.setor_id_origem
            this.processo.tipoprocesso_id   = item.tipoprocesso_id
            this.processo.especificacao     = item.especificacao
            this.processo.processo_antigo   = item.processo_antigo
            this.processo.assunto_id        = item.assunto_id
            this.processo.interessado       = item.interessado
            this.processo.nivel_id          = item.nivel_id
            this.processo.fundamentacao_id  = item.fundamentacao_id
            this.processo.observacao        = item.observacao
        },
        excluirRascunho (id) {
            let con = confirm('Deseja excluir esse rascunho?')
            if (con) {
                this.axios.patch(`processo/processo/${id}`).then((res) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: res.data.message,
                        color: 'green'
                    }
                    this.getRascunhos()
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    }
                })
            }
        },
        getProcessoModal (id) {
            this.axios.get(`processo/processo/${id}`).then((res) => {
                const p = res.data[0]
                this.dadosProcesso = {
                    ...this.dadosProcesso,
                    id: p.id,
                    numero: p.numero,
                    ano: p.ano,
                    processo_antigo: p.processo_antigo,
                    setor_id_origem: p.setor_id_origem,
                    setor_id_atual: p.setor_id_atual,
                    usuario_id: this.$store.state.usuario.id,
                    organizacao: this.$store.state.organizacao.id,
                    observacao: null,
                }
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        getProcesso (id) {
            this.loading = true
            this.resetProcesso()
            this.axios.get(`processo/processo/${id}`).then((res) => {
                const p = res.data[0]
                this.verProcesso = {
                    ...this.verProcesso,
                    id: p.id,
                    numero: p.numero,
                    ano: p.ano,
                    setor_id_origem: p.setor_origem.nome,
                    setor_id_atual: p.setor_atual.nome,
                    usuario_id: null, //p.usuario_criacao.nome,
                    observacao: p.observacao,
                    situacao: p.situacao_id,
                    assunto: p.assunto.nome,
                    organizacao: null, //p.organizacao.nome,
                    tipo: p.tipo_processo.nome,
                    nivel: p.nivel.nome,
                    interessado: p.interessado,
                    especificacao: p.especificacao,
                    processo_antigo: p.processo_antigo,
                }
                for (var i = 0; i < p.tramitacoes.length; i++) {
                    this.verProcesso.tramitacoes.push({
                        id: p.tramitacoes[i].id,
                        situacao_id: p.tramitacoes[i].situacao_id,
                        situacao: p.tramitacoes[i].situacao.nome,
                        setor_id_origem: p.tramitacoes[i].setor_origem.nome,
                        setor_id_destino: p.tramitacoes[i].setor_destino.nome,
                        nome: p.tramitacoes[i].situacao.nome,
                        usuario: p.tramitacoes[i].usuario.nome,
                        data: p.tramitacoes[i].created_at,
                        anotacoes: [],
                    })
                    if (p.tramitacoes[i].anotacoes.length > 0) {
                        for (var j = 0; j < p.tramitacoes[i].anotacoes.length; j++) {
                            this.verProcesso.tramitacoes[i].anotacoes.push({
                                anotacao: p.tramitacoes[i].anotacoes[j].anotacoes,
                                usuario_id: p.tramitacoes[i].anotacoes[j].usuario_id,
                                data: p.tramitacoes[i].anotacoes[j].created_at
                            })
                        }
                    }
                }
                this.verProcesso.show = true
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            }).finally(() => {
                this.loading = false
            })
        },
        removeInteressado(indice) {
            if (this.interessadoAdd > 1) {
                this.interessadoAdd -= 1;
                this.interessados.splice(indice, 1);
            }
        },
        resetProcesso () {
            this.verProcesso = {
                ...this.verProcesso,
                id: null,
                numero: null,
                ano: null,
                setor_id_origem: null,
                setor_id_atual: null,
                usuario_id: null,
                observacao: null,
                situacao_id: null,
                assunto_id: null,
                organizacao_id: null,
                tramitacoes: []
            }
        }
    },
    mounted () {
        this.getRascunhos()
        this.$refs.formDados1.validate()
        this.$refs.formDados2.validate()
    }
}
</script>
<style scoped>
.v-stepper--vertical .v-stepper__step {
            background: #f1f1f1;
            border: 1px solid #efefef;
        }
        .v-stepper {
            border-radius: 0px;
        }
        .v-stepper, .v-stepper__header {
            box-shadow: none;
        }
        .v-stepper__label{
            font-size: 16px;
            line-height: 1.2;
            font-family: Medium,Arial,Helvetica,sans-sefir;
            font-weight: 500;
        }
        .v-stepper__label small {
            padding-top: 10px;
            font-size: 12px;
            color: #999 !important;
        }
</style>