<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Documentos aguardando a sua assinatura</h2>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="documentos"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >
                    <template v-slot:[`item.documento.processo.id`]="{ item }">
                        {{ item.documento.processo.numero }}/{{ item.documento.processo.ano }}
                    </template>

                    <template v-slot:[`item.documento.id`]="{ item }">
                        {{ item.documento.processo.numero }}/{{ item.documento.processo.ano }}-{{ item.documento.numero_doc }}
                    </template>

                    <template v-slot:[`item.dataSolicitacao`]="{ item }">
                        {{ $moment(item.created_at).format('DD/MM/YYYY') }}
                    </template>
                    <template v-slot:[`item.tempo`]="{ item }">
                        {{ $moment(item.created_at).startOf('minute').fromNow() }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[loadDocumento(item), viewPDF = true]"
                                >
                                    <v-icon color="blue">mdi-draw</v-icon>
                                </v-btn>
                            </template>
                            <span>Visualizar documento</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-dialog width="60%" v-model="viewPDF" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                            Visualizar documento {{ bloco.numero_doc }}
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                @click="[viewPDF = false]"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <div>
                                <input type="number" min="1" :max="numPages" v-model.number="page"  style="width: 3em"> de {{numPages}}
                                <v-btn
                                    small
                                    color="primary"
                                    class="ml-2 white--text"
                                    @click="[downloadDocumento()]">
                                    <v-icon left>mdi-download</v-icon>
                                      Download do documento
                                </v-btn>
                            </div>
                            <pdfview ref="docpdf" :src="src" :page="page"  @num-pages="numPages = $event">
                            </pdfview>
                            <input type="number" v-model.number="page" min="1" :max="numPages" style="width: 3em"> de {{numPages}}
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-text-field
                                id="password"
                                label="Favor, confirme sua senha"
                                name="password"
                                prepend-icon="lock"
                                type="password"
                                color="accent"
                                required
                                :rules="[rules.required]"
                                v-model="password"
                            />
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="[signDocumento()]"
                            >
                                <v-icon>mdi-draw</v-icon>
                                Assinar documento
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import jwt_decode from "jwt-decode";
import Snackbar from '../../components/Snackbar.vue'
import pdfview from 'vue-pdf'
export default {
    name:'Pendente',
    components: {
        Snackbar,
        pdfview
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        loading: false,
        viewPDF: false,
        src: null,
        page: null,
        numPages: null,
        bloco: {
            bloco_id:           null,
            usuario_email:      null,
        },
        headers: [
            { text: 'Processo', value: 'documento.processo.id', width: '12%' },
            { text: 'Documento', value: 'documento.id', width: '15%' },
            { text: 'Tipo de documento', value: 'documento.tipo_documento.nome', width: '15%' },
            { text: 'Setor solicitante', value: 'documento.setor.nome', width: '20%' },
            { text: 'Data solicitação', value: 'dataSolicitacao', width: '15%' },
            { text: 'Tempo decorrido', value: 'tempo', width: '10%' },
            { text: 'Ações', value: 'actions', width: '20%', align: 'right' },
        ],
        documentos: [],
        documento: [],
        pendingBlocks: {
            userCode: null,
        },
        toSign: {
            blocos: null,
        },
        password: null,
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
    }),
    methods: {
        getDocumentos () {
            this.loading    = true
            this.documento  = []
            this.documentos = []
            // Prencher campo com o email do usuario logado
            this.pendingBlocks.userCode = this.$store.state.usuario.email
            // Buscar os blocos de assinatura pendente para o usuario
            this.axios.post('subscription/blocks/pendingBlocks', this.pendingBlocks).then((resSign) => {

                // Prencher os blocos pendentes retornados pelo servico de assinatura
                this.toSign.blocos = resSign.data.data

                this.axios.post('processo/assinatura/tosign', this.toSign).then((resDocs) => {
                    this.documentos = resDocs.data
                }).finally(() => {
                    this.loading = false
                })
            }).finally(() => {
                this.loading = false
            })
        },
        signDocumento () {
            if (!this.$refs.formDados.validate())
                return false
            this.bloco.usuario_email = this.$store.state.usuario.email
            // 1. Confirmar se a senha esta correta
            this.axios.post('gerencial/auth/login', {
                email:      this.bloco.usuario_email,
                password:   this.password
            }).then((resAuth) => {
                localStorage.setItem('temporaryToken', resAuth.data.token)
                // 2. Proceder com a segunda etapa da autenticacao
                this.axios.post('gerencial/auth', {
                    idOrganization: this.$store.state.organizacao.id,
                    token:          localStorage.getItem('temporaryToken')
                }).then((resLogin) => {
                    localStorage.setItem('expToken', jwt_decode(resLogin.data.token).exp)
                    localStorage.setItem('token', resLogin.data.token)

                    // 3. Registrar assinatura do documento
                    this.axios.post(`processo/assinatura/${this.bloco.documento_id}/assinar`, this.bloco).then((resSign) => {
                        this.snackbar = {
                            ...this.snackbar,
                            show: true,
                            text: resSign.data.message,
                            color: 'green'
                        }
                        this.viewPDF = false
                        this.getDocumentos()
                    }).catch(e => {
                        this.snackbar = {
                            ...this.snackbar,
                            show: true,
                            text: e.response.data[Object.keys(e.response.data)[0]][0],
                            color: 'red',
                        }
                    })
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data.message,
                        color: 'red',
                    }
                }).finally(() => {

                })
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data.message[0].error[0],
                    color: 'red',
                }
            }).finally(() => {

            })
        },
        loadDocumento (item) {
            this.documento          = item.documento
            this.password           = ''
            this.bloco.bloco_id     = item.id
            this.bloco.documento_id = item.documento_id
            this.filename           = item.documento.filename
            this.bloco.numero_doc   = item.documento.processo.numero
                                        +'/'+item.documento.processo.ano
                                        +'-'+item.documento.numero_doc
            // Arquivo importado para o docs
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}documento/download/${item.documento.processo_id}/${this.filename}`, {
                responseType: 'blob'
            }).then((res) => {
                this.viewPDF= true;
                const url   =  window.URL.createObjectURL(new Blob([res.data]))
                this.src    = url
                this.page   = 1
            })

        },
        downloadDocumento(){
            this.viewPDF  = null
            this.src      = null
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}documento/download/`+this.documento.processo_id+`/`+this.documento.filename,
                {responseType: 'blob'}
                ).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a');
                    const filename = 'processo'
                                        +'_'+this.documento.processo.numero
                                        +'-'+this.documento.processo.ano
                                        +'_documento_#'+this.documento.numero_doc
                                        +'_tipo_'+this.documento.tipo_documento.nome
                                        +'.pdf'
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response)[0]][0],
                        color: 'red',
                }
            })
        },
    },
    mounted () {
        this.getDocumentos()
        this.$refs.formDados.validate()
    }
}
</script>
