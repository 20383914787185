<template>
    <div class="sidebar">
        <v-app-bar app dark class="accent" elevation="0">
            <v-app-bar-nav-icon @click.stop="sidebarMenu = !sidebarMenu">
            </v-app-bar-nav-icon>
            <v-toolbar-title>{{organizacao_nome}}</v-toolbar-title>
            <v-spacer></v-spacer>
<!--            <v-text-field-->
<!--                @focus="searchClosed = false"-->
<!--                @blur="searchClosed = true"-->
<!--                v-model="search"-->
<!--                placeholder="Pesquisar"-->
<!--                prepend-inner-icon="mdi-magnify"-->
<!--                class="expanding-search mt-6 pr-2 hidden-xs-only"-->
<!--                :class="{ 'closed' : searchClosed && !search }"-->
<!--                filled-->
<!--                dense-->
<!--                clearable-->
<!--            >-->
<!--            </v-text-field>-->
        <div style="text-align: right; padding-right: 10px;">
            <v-list-item-content>
                <v-list-item-title>{{usuario_nome}}</v-list-item-title>
            </v-list-item-content>
        </div>
        <v-menu
                transition="scroll-y-transition"
                v-model="menuAccount"
                :close-on-content-click="false"
            >

            <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        @click="menuAccount = !menuAccount"
                        color="white"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                        <!--
                            <v-img style="border: 1px solid white" src="htt0ps://cdn.vuetifyjs.com/images/john.jpg">

                            </v-img>
                        -->
                    </v-avatar>
                </template>
                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <!--
                                    <img
                                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                                    alt="John">
                                -->
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{usuario_nome}}</v-list-item-title>
                                <v-list-item-subtitle> {{ organizacao_nome }} </v-list-item-subtitle>
                            </v-list-item-content>

                                <v-list-item-action>
                                <v-btn
                                    :class="fav ? 'red--text' : ''"
                                    icon
                                    @click="fav = !fav"
                                >
                                    <v-icon>mdi-alarm-light</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <!--
                    <v-list>
                        <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                    v-model="message"
                                    color="primary"
                                ></v-switch>
                            </v-list-item-action>
                            <v-list-item-title>Ativar notificações</v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                    v-model="hints"
                                    color="primary"
                                ></v-switch>
                            </v-list-item-action>
                            <v-list-item-title>Ativar dicas</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    -->

                    <v-divider></v-divider>

                    <v-list shaped>
                        <v-subheader>MEU PERFIL</v-subheader>
                        <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                        >
                            <v-list-item
                                to="/perfil/editar">
                                <v-list-item-icon>
                                    <v-icon>mdi-cog</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Editar dados</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                                to="/processos/rascunhos">
                                <v-list-item-icon>
                                    <v-icon>mdi-file-document-edit-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Meus rascunhos</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                                to="/assinatura/pendente">
                                <v-list-item-icon>
                                    <v-icon>mdi-draw</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Aguardando assinatura</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider></v-divider>

                            <v-list-item
                                @click="logout()">
                                <v-list-item-icon>
                                    <v-icon>mdi-logout</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Sair</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-item-group>
                    </v-list>

                    <!--
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            text
                            @click="menuAccount = false"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            color="primary"
                            text
                            @click="menuAccount = false"
                        >
                            Salvar
                        </v-btn>
                    </v-card-actions>
                    -->

                </v-card>
            </v-menu>
        </v-app-bar>
        <v-navigation-drawer
            v-model="sidebarMenu"
            app
            fixed
            absolute
            dark
            class="accent"
            height="100%"
            :permanent="sidebarMenu"
            mini-variant-width="80"
            :mini-variant.sync="mini">
            <v-list>
                <v-list-item class="px-0 align-center justify-center fill-height">
                    <a href="/dashboard">
                        <v-img
                            :src="require('../assets/logo_light.png')"
                            contain
                            height="40"
                        />
                    </a>
                </v-list-item>
            </v-list>
<!--            <v-list dense>-->
<!--                <v-list-item class="px-2" @click="toggleMini = !toggleMini">-->
<!--                    <v-list-item-avatar>-->
<!--                        <v-icon>-->
<!--                            mdi-account-outline-->
<!--                        </v-icon>-->
<!--                    </v-list-item-avatar>-->
<!--                    <v-list-item-content class="text-truncate">-->
<!--                        Marcos Antônio-->
<!--                    </v-list-item-content>-->
<!--                    <v-btn icon small>-->
<!--                        <v-icon color="secondary">mdi-chevron-left</v-icon>-->
<!--                    </v-btn>-->
<!--                </v-list-item>-->
<!--            </v-list>-->
            <v-divider></v-divider>
            <v-list>
            <!-- ENTIRE list is wrapped in a template -->
                <template v-for="(item, i) in drawerItems">
            <!-- use v-if to determine if 2nd level nesting is needed -->
            <!-- if it's a menu item with no children -->
                    <v-list-item
                        style="text-decoration: none"
                        v-if="!item.children && (item.permission=='public' || checkPermission(item.name, item.permission)==true)"
                        color="white"
                        :key="`subheader-${i}`"
                        :to="item.path"
                    >
                        <v-list-item-icon>
                        <v-icon>{{ `mdi-${item.meta.icon}` }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>
                <!-- else if it has children -->
                    <v-list-group
                        :key="`subheader-${i}`"
                        v-else-if="item.permission=='public' || checkPermission(item.name, item.permission)==true || !item.permission"
                        :group="item.path"
                        color="white"
                    >
                    <!-- this template is for the title of top-level items with children -->
                        <template #activator>
                            <v-list-item-content>
                                <v-list-item-title>
                                <v-icon>{{ `mdi-${item.meta.icon}` }}</v-icon>
                                {{ item.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    <!-- this template is for the children/sub-items (2nd level) -->
                        <template v-for="(subItem, j) in item.children">
                        <!-- another v-if to determine if there's a 3rd level -->
                        <!-- if there is NOT a 3rd level -->
                            <v-list-item
                                style="text-decoration: none"
                                v-if="!subItem.children && (subItem.permission=='public' || checkPermission(subItem.name, subItem.permission)==true || !subItem.permission)"
                                :key="`subheader-${j}`"
                                :to="item.path + subItem.path"
                            >
                                <v-list-item-icon class="mr-4">
                                    <v-icon v-text="`mdi-${subItem.meta.icon}`" />
                                    </v-list-item-icon>
                                <v-list-item-title class="ml-0">
                                    {{ subItem.name }}
                                </v-list-item-title>
                            </v-list-item>
                        <!-- if there is a 3rd level -->
                            <v-list-group
                                :key="`subheader-${j}`"
                                v-else-if="subItem.children && (subItem.permission=='public' || checkPermission(subItem.name, subItem.permission)==true || !subItem.permission)"
                                color="white"
                                :group="subItem.path"
                                sub-group
                            >
                                <template #activator>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                        <v-icon>{{ `mdi-${subItem.meta.icon}` }}</v-icon>
                                        {{ subItem.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <template v-for="(subSubItem, k) in subItem.children">
                                    <v-list-item
                                        style="text-decoration: none"
                                        :key="`subheader-${k}`"
                                        v-if="subSubItem.permission=='public' || checkPermission(subSubItem.name, subSubItem.permission)==true || !subSubItem.permission"
                                        color="white"
                                        :value="true"
                                        :to="item.path + subItem.path + subSubItem.path"
                                    >
                                        <v-list-item-title>{{ subSubItem.name }}</v-list-item-title>
                                        <v-list-item-icon>
                                        <v-icon>{{ `mdi-${subSubItem.meta.icon}` }}</v-icon>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </template>
                            </v-list-group>
                        </template>
                    </v-list-group>
                </template>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { logoutMixin } from '../mixins'
export default {
    name: 'Sidebar',
    mixins: [logoutMixin],
    components: {
    },
    props: {
        state: Boolean
    },
    data: () => ({
    selectedItem: 1,
    organizacao_nome: null,
    sidebarMenu: true,
    toggleMini: false,
    menuAccount: false,
    fav: true,
    message: false,
    hints: true,
    search: null,
    searchClosed: true,
    usuario_nome: null,
    drawerItems: [
            {
                name: 'Dashboards', path: '/dashboard', meta: { icon: 'chart-sankey' }, permission: 'public'
            },
            {
                name: 'Processos', path: '', meta: { icon: ''}, permission: 'public',
                children: [
                    {
                        name: 'Meus rascunhos', path: '/processos/rascunhos', meta: { icon: 'file-document-edit-outline'},
                    },
                    {
                        name: 'Receber processo', path: '/processos/receber', meta: { icon: 'archive-arrow-down-outline'},
                    },
                    {
                        name: 'Processos no setor', path: '/processos/enviar', meta: { icon: 'archive-arrow-up-outline'},
                    },
                    {
                        name: 'Arquivo do setor', path: '/processos/arquivos', meta: { icon: 'archive-outline'},
                    },
                    {
                        name: 'Tramitação pendente', path: '/processos/recebimento/pendente', meta: { icon: 'archive-remove-outline'},
                    },
                    {
                        name: 'Relacionar processos', path: '/processos/relacionar', meta: { icon: 'ray-start-end'},
                    },
                    {
                        name: 'Juntada de processos', path: '/processos/juntar', meta: { icon: 'source-merge'},
                    },
                    {
                        name: 'Consultar processos', path: '/processos/consultar', meta: { icon: 'feature-search-outline'},
                    },
                    
                ]
            },
            {
                name: 'Documentos', path: '', meta: { icon: ''}, permission: 'public',
                children: [
                    {
                        name: 'Aguardando assinar', path: '/assinatura/pendente', meta: { icon: 'draw'},
                    },
                    {
                        name: 'Validar autenticidade', path: '/assinatura/validar', meta: { icon: 'spellcheck'},
                    },
                    {
                        name: 'Consultar documentos', path: '/processos/documentos', meta: { icon: 'feature-search-outline'},
                    },
                ]
            },
            {
                name: 'Administrativo', path: '', meta: { icon: ''}, permission: 'public',
                children:
                [
                    {
                        name: 'Modelos', path: '/modelos', meta: { icon: 'content-copy'},
                    },
                    {
                        name: 'Marcadores', path: '/marcadores', meta: { icon: 'more'},
                    },
                    {
                        name: 'Grupo de assuntos', path: '/grupoassuntos', meta: { icon: 'text-box-multiple'},
                    },
                    {
                        name: 'Assuntos', path: '/assuntos', meta: { icon: 'text-box'},
                    },
                    {
                        name: 'Tipos de processos', path: '/processos', meta: { icon: 'file-document-edit-outline'},
                    },
                    {
                        name: 'Tipos de documentos', path: '/documentos', meta: { icon: 'file-cog'},
                    },
                    {
                        name: 'Fundamentação legal', path: '/fundamentacao', meta: { icon: 'file-cog'},
                    },
                ]
            },
            {
                name: 'Gerencial', path: '', meta: { icon: ''}, permission: 'public',
                children:
                [
                    {
                        name: 'Usuários', path: '/gerencial/usuario', meta: { icon: 'account-settings'},
                    },
                    {
                        name: 'Analisar solicitações', path: '/gerencial/solicitacao', meta: { icon: 'account-settings'},
                    },
                    {
                        name: 'Perfis de acesso', path: '/gerencial/perfil', meta: { icon: 'account-check'},
                    },
                    {
                       name: 'Organizações', path: '/gerencial/organizacao', meta: { icon: 'ungroup'},
                    },
                    {
                        name: 'Instituições', path: '/gerencial/instituicao', meta: { icon: 'group'},
                    },
                    {
                        name: 'Unidades', path: '/gerencial/unidade', meta: { icon: 'sitemap'},
                    },
                    {
                        name: 'Setores', path: '/gerencial/setor', meta: { icon: 'family-tree'},
                    },                ]
            },
        ]
    }),
    computed: {
        mini() {
            return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
        },
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        checkPermission(name, permission){
            return true
        },
    },
    mounted () {
        this.organizacao_nome = this.$store.state.organizacao.name
        this.usuario_nome = this.$store.state.usuario.name
    }
};
</script>

<style lang="scss">
.no-background-hover::before {
background-color: transparent !important;
}
</style>
<style lang="sass">
    .v-input.expanding-search
        transition: max-width 0.3s
        .v-input__slot
            cursor: pointer !important
            &:before, &:after
                border-color: transparent !important
            &.closed
                max-width: 30px
                .v-input__slot
                    background: transparent !important
</style>