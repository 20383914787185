<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Solicitação de cadastro para acesso externo</h2>
                    </v-col>
                </v-row>
                <v-row class="mt-2">
                    <v-col
                        cols="3"
                        sm="3"
                        xs="3"
                    >
                        <v-text-field
                            v-model="registro.cpf"
                            label="CPF"
                            required
                            :rules="[rules.required]"
                            v-mask="['###.###.###-##']"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="9"
                        sm="9"
                        xs="9"
                    >
                        <v-text-field
                            v-model="registro.nome"
                            label="Nome completo"
                            required
                            :rules="[rules.required]"
                            counter
                            maxlength="100"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-2">
                    <v-col
                        cols="3"
                        sm="3"
                        xs="3"
                    >
                        <v-text-field
                            v-model="registro.datanascimentoMask"
                            label="Data nascimento"
                            v-mask="['##/##/####']"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="9"
                        sm="9"
                        xs="9"
                    >
                        <v-text-field
                            v-model="registro.email"
                            label="E-mail"
                            required
                            :rules="[rules.required]"
                            counter
                            maxlength="50"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col
                        cols="3"
                        sm="3"
                        xs="3"
                    >
                        <v-text-field
                            v-model="registro.telefone"
                            label="Telefone fixo"
                            v-mask="['(##) #####-####']"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="3"
                        sm="3"
                        xs="3"
                    >
                        <v-text-field
                            v-model="registro.celular"
                            label="Telefone celular"
                            v-mask="['(##) #####-####']"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                    >
                    <v-text-field
                            v-model="registro.cargo"
                            label="Cargo/função"
                            required
                            :rules="[rules.required]"
                            counter
                            maxlength="50"
                        ></v-text-field>
                    </v-col>
                </v-row>
                    
                <v-row class="mt-2">
                    <v-col
                        cols="3"
                        sm="3"
                        xs="3"
                    >
                        <v-text-field
                            v-model="registro.cep"
                            label="CEP"
                            @keyup="searchCep()"
                            required
                            :rules="[rules.required]"
                            v-mask="['##.###-###']"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="8"
                        sm="8"
                        xs="8"
                    >
                        <v-text-field
                            v-model="registro.logradouro"
                            label="Logradouro"
                            required
                            :rules="[rules.required]"
                            counter
                            maxlength="100"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="1"
                        sm="1"
                        xs="1"
                    >
                        <v-text-field
                            v-model="registro.numero"
                            label="Número"
                            required
                            :rules="[rules.required]"
                            counter
                            maxlength="10"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-2">
                    <v-col
                        cols="12"
                        sm="4"
                        xs="12"
                    >
                        <v-text-field
                            v-model="registro.bairro"
                            label="Bairro"
                            required
                            :rules="[rules.required]"
                            counter
                            maxlength="100"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="2"
                        xs="12"
                    >
                        <v-text-field
                            v-model="registro.complemento"
                            label="Complemento"
                            counter
                            maxlength="100"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="5"
                        xs="12"
                    >
                        <v-text-field
                            v-model="registro.cidade"
                            label="Cidade"
                            required
                            :rules="[rules.required]"
                            counter
                            maxlength="100"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="1"
                        xs="12"
                    >
                        <v-text-field
                            v-model="registro.uf"
                            label="UF"
                            required
                            :rules="[rules.required]"
                            counter
                            maxlength="2"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-2">
                    <v-col
                        cols="2"
                        sm="2"
                        xs="2"
                    >
                        <v-btn
                            color="primary"
                            append
                            @click="[create()]"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            Solicitar cadastro
                        </v-btn>
                    </v-col>
                    <v-col
                        cols="2"
                        sm="2"
                        xs="2"
                    >
                    <v-btn
                        append
                        color="primary"
                        @click="[zerarCampos()]"
                        >
                            <v-icon left>mdi-cancel</v-icon>
                            Limpar formulário
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'Usuario',
    components: {
        Snackbar
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        organizacao_id: null,
        registro: {
            id: null,
            organizacao_id: null,
            nome: null,
            cpf: null,
            email: null,
            datanascimento: null,
            datanascimentoMask: null,
            telefone: null,
            celular: null,
            cargo: null,
            cep: null,
            logradouro: null,
            numero: null,
            complemento: null,
            bairro: null,
            cidade: null,
            uf: null
        },
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        }
    }),
    methods: {
        create () {
            if (!this.$refs.formDados.validate()) return false;
            let datanascimento  = this.registro.datanascimentoMask
            let aData           = datanascimento.split('/')
            this.registro.datanascimento = aData[2] + '-' + aData[1] + '-' + aData[0]
            this.axios
                .post(`${process.env.VUE_APP_PROCESSO_URL}solicitacaoacesso`, this.registro)
                .then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: "green",
                };
                this;
                })
            .catch(e => {
                console.log(e)
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            });
        },
        searchCep() {
            if(this.registro.cep === null) return false
            if(this.registro.cep.length === 10) {
                this.$axios.get(`https://viacep.com.br/ws/${ this.registro.cep.replace(/\D/g, '') }/json/`).then((res) => {
                    this.registro.logradouro    = res.data.logradouro
                    this.registro.bairro        = res.data.bairro
                    this.registro.cidade        = res.data.localidade
                    this.registro.uf            = res.data.uf
                }).catch(e => {
                    console.log(e)
                    this.registro.logradouro    = ''
                    this.registro.bairro        = ''
                    this.registro.cidade        = ''
                    this.registro.uf            = ''
                })
            }
        },
        zerarCampos () {
            this.registro.organizacao_id    = this.organizacao_id
            this.registro.cpf               = null
            this.registro.nome              = null
            this.registro.datanascimento    = null
            this.registro.datanascimentoMask= null
            this.registro.telefone          = null
            this.registro.celular           = null
            this.registro.email             = null
            this.registro.cargo             = null
            this.registro.cep               = null
            this.registro.logradouro        = null
            this.registro.numero            = null
            this.registro.complemento       = null
            this.registro.bairro            = null
            this.registro.cidade            = null
            this.registro.uf                = null

        }
    },
    mounted () {
        this.organizacao_id = this.$router.currentRoute.query.idorganization
        this.zerarCampos()
        this.$refs.formDados.validate()
    }
}
</script>