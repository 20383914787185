<template>
    <div>
        <v-dialog width="40%" v-model="analise.show" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    {{ analise.acao == 1 ? 'Deferir' : 'Indeferir' }} acesso para {{ analise.cpf }} - {{ analise.nome }}
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="[analise.show = false]"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col
                            cols="12"
                            sm="12"
                            xs="12"
                        >
                            <v-textarea
                                label="Observação"
                                auto-grow
                                v-model="analise.observacao"
                                color="accent"
                                rows="1"
                                row-height="12"
                                shaped
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="accent"
                        dense
                        @click="[registrarAnalise()]"
                    >
                    {{ analise.acao == 1 ? 'Deferir' : 'Indeferir' }} solicitação
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default{
    name: 'analise',
        props:{
            analise:{
                id: Number,
                cpf: Number,
                nome: Number,
                usuario_id: String,
                observacao: String,
                show: Boolean
            },
        },
        methods: {
            registrarAnalise () {
                this.$emit('registrarAnalise', {
                    analise: this.analise,
                })
                this.analise.show = false
            },
        },
    }
</script>