<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="9" xl="9">
                        <h2 class="mb-0 accent--text">Marcadores</h2>
                    </v-col>
                </v-row>
                
                <v-row class="mb-2">
                    <v-col
                        sm="5"
                        xs="5"
                    >
                        <v-autocomplete
                            label="Informe o setor"
                            v-model="setor_filter"
                            :items="setores"
                            item-value="id"
                            item-text="name"
                            required
                            :rules="[rules.required]"
                            @change="[getMarcadores()]"
                        />
                    </v-col>
                    <v-col sm="5"
                            xs="5">
                        <v-text-field v-model="search" prepend-icon="mdi-feature-search-outline" label="Pesquisar" clearable></v-text-field>
                    </v-col>
                    <v-col sm="2"
                        xs="2" class="justify-end content">
                        <v-btn
                            :disabled="!setor_filter"
                            block
                            color="accent"
                            @click="[zerarCampos(), view = true]"
                            class="mb-2"
                        >
                            <v-icon left>mdi-plus</v-icon>
                            Novo
                        </v-btn>

                    </v-col>
                </v-row>
                
                <v-data-table
                    :headers="headers"
                    :items="marcadores"
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >

                    <template v-slot:[`item.cor`]="{ item }">
                        <v-chip 
                            dark 
                            :color="(item.cor)">
                            <v-icon>mdi-label</v-icon>
                        </v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[load(item), view = true]"
                                >
                                    <v-icon color="blue">mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="item.ativo ? false : true"
                                    icon
                                    @click="[inactive(item.id)]"
                                >
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Inativar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>

                <v-dialog width="40%" v-model="view" :fullscreen="$vuetify.breakpoint.xsOnly">
                    <v-card>
                        <v-card-title class="text-h6 grey lighten-2">
                            Gerenciar marcador
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                                @click="[view = false]"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row class="mt-2">                            
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-autocomplete v-if="registro.id==null"
                                        label="Informe o setor"
                                        v-model="registro.setor_id"
                                        :items="setores"
                                        item-value="id"
                                        item-text="name"
                                        required
                                        :rules="[rules.required]"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">                            
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-text-field
                                        v-model="registro.nome"
                                        label="Nome"
                                        required
                                        :rules="[rules.required]"
                                        counter
                                        maxlength="100"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">                            
                                <v-col
                                    cols="12"
                                    sm="12"
                                    xs="12"
                                >
                                    <v-field-label>Cor</v-field-label>
                                    <v-color-picker
                                        hide-inputs
                                        v-model="registro.cor"
                                        required
                                        :rules="[rules.required]"
                                    ></v-color-picker>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="registro.id==null"
                                color="primary"
                                text
                                @click="[create()]"
                            >
                                Criar
                            </v-btn>
                            <v-btn v-else
                                color="primary"
                                text
                                @click="[update()]"
                            >
                                Atualizar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'Marcador',
    components: {
        Snackbar
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        search: '',
        loading: false,
        view: false,
        organizacao_id: null,
        setor_filter: null,
        showbotao: false,
        registro: {
            id: null,
            organizacao_id: null,
            setor_id: null,
            usuario_id: null,
            nome: null,
            cor: null
        },
        headers: [
            { text: 'Setor', value: 'setor.nome', width: '25%' },
            { text: 'Nome', value: 'nome', width: '25%' },
            { text: 'Cor', value: 'cor', width: '14%' },
            { text: 'Ações', value: 'actions', width: '15%', align: 'right' },
        ],
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
        marcadores: [],
        setores: []
    }),
    methods: {
        getSetores () {
            this.setores = []
            this.axios.get('gerencial/auth/sectors/user').then((res) => {
                for (var s = 0; s < res.data.length; s++){
                    var setor   = res.data[s]
                    if (setor.active === true)
                        this.setores.push(setor)
                }
                if (this.setores.length === 1)
                    this.registro.setor_id = this.setores[0].id
            })
        },
        getMarcadores () {
            this.showbotao=true
            this.loading = true
            this.$axios.get(`processo/marcadores/${this.organizacao_id}/org`, {
                params: { 'ativo': 1, "setor": this.setor_filter }
                }).then((res) => {
                this.marcadores = res.data
            }).finally(() => {
                this.loading = false
            })
        },
        create () {
            if (!this.$refs.formDados.validate())
                return false
            this.registro.organizacao_id    = this.$store.state.organizacao.id
            this.registro.usuario_id        = this.$store.state.usuario.id
            this.registro.cor               = this.registro.cor.hex
            this.axios.post('processo/marcadores', this.registro).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.view = false
                this.getMarcadores()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        load (item) {
            this.registro.id            = item.id
            this.registro.nome          = item.nome
            this.registro.cor           = item.cor
        },
        update () {
            if (!this.$refs.formDados.validate())
                return false
            this.axios.put(`processo/marcadores/${this.registro.id}`, this.registro).then((res) => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: res.data.message,
                    color: 'green'
                }
                this.view = false
                this.getMarcadores()
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        inactive (id) {
            let con = confirm("Deseja inativar esse marcador?")
                if (con) {
                this.axios.patch(`processo/marcadores/${id}`).then((res) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: res.data.message,
                        color: 'green'
                    }
                    this.getMarcadores()
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    }
                })
            }
        },
        zerarCampos () {
            this.registro.id                = null
            this.registro.setor_id          = null
            this.registro.nome              = null
            this.registro.cor               = null
        },
    },
    mounted () {
        this.organizacao_id = this.$store.state.organizacao.id;
        this.getSetores()
        this.$refs.formDados.validate()
    }
}
</script>