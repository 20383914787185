var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formDados",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"page"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTableNotificacoes),expression:"showTableNotificacoes"}]},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"7","lg":"8","xl":"9"}},[_c('h2',{staticClass:"mb-0 accent--text"},[_vm._v("Notificações")])])],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"7","lg":"12","xl":"9"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-feature-search-outline","label":"Pesquisar","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.registros,"search":_vm.search,"loading":_vm.loading,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.lido",fn:function(ref){
var item = ref.item;
return [(item.lido)?_c('v-chip',{staticClass:"green",attrs:{"dark":""}},[_vm._v("Lido")]):_c('v-chip',{staticClass:"red",attrs:{"dark":""}},[_vm._v("Não lido")])]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('DD/MM/YYYY'))+" ")]}},{key:"item.tempo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).startOf('minute').fromNow())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.lido == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.read(item.id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-email")])],1)]}}],null,true)},[_c('span',[_vm._v("Marcar como lida")])]):_vm._e(),(item.lido == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.unread(item.id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-email-open")])],1)]}}],null,true)},[_c('span',[_vm._v("Marcar como não lida")])]):_vm._e(),(item.favorito_deleted == null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.unfollowProcesso(item.favorito_id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-star")])],1)]}}],null,true)},[_c('span',[_vm._v("Deixar de receber notificações")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                                    el: '#personalizar',
                                    easing: [.6, .80, .30, 1.9],
                                    duration: 2000
                                }),expression:"{\n                                    el: '#personalizar',\n                                    easing: [.6, .80, .30, 1.9],\n                                    duration: 2000\n                                }"}],attrs:{"icon":""},on:{"click":function($event){[_vm.getDocs(item.processo)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-folder-open")])],1)]}}],null,true)},[_c('span',[_vm._v("Gerenciar documentos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.getProcesso(item.processo_id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalhes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){[_vm.pdf(item.processo_id)]}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar histórico")])])]}}],null,true)})],1),_c('VerModalProcesso',{attrs:{"processo":_vm.verProcesso}}),_c('div',{attrs:{"id":"personalizar"}},[_c('PersonalizarProcesso',{attrs:{"processo":_vm.personalizarProcesso},on:{"tramitarProcessoDocs":_vm.enviarProcessoDocs,"arvoreDocs":_vm.getDocs,"limparDocs":_vm.get,"sendDoc":_vm.createDoc}})],1)],1),_c('Snackbar',{attrs:{"snackbar":_vm.snackbar}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }