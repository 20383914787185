<template>
    <div class="page">
        <v-container fluid>
            <v-container v-show="showTableProcessos">
                <Filtros
                    ano
                    numero
                    processoAntigo
                    tipoprocesso
                    assunto
                    situacao
                    interessado
                    :itemsArr="processos"
                    :qtdFilters="5"
                    @input="applyInput"
                    @filter="applyFilter"
                    @clear="applyClear"
                    v-if="loadedProcessos"
                />
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Consulta pública de processos</h2>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="processos"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >
                    <template v-slot:[`item.id`]="{ item }">{{ item.numero }}/{{ item.ano }}</template>
                    <template v-slot:[`item.situacao`]="{ item }">
                        <v-chip
                            :color="colorSituacao(item.situacao_id)"
                            class="white--text"
                        >
                            {{ item.situacao }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    v-scroll-to="{
                                        el: '#personalizar',
                                        easing: [.6, .80, .30, 1.9],
                                        duration: 2000
                                    }"
                                    @click="getDocs(item)"
                                >
                                    <v-icon color="accent">mdi-folder-open</v-icon>
                                </v-btn>
                            </template>
                            <span>Gerenciar documentos</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[getProcesso(item.id)]"
                                >
                                    <v-icon color="accent">mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver detalhes</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-container>
            <VerModalProcesso 
                :processo="verProcesso">
            </VerModalProcesso>
            <div id="personalizar">
                <PersonalizarProcesso 
                    :processo="personalizarProcesso" 
                    @arvoreDocs="getDocs" 
                    @limparDocs="getProcessos">
                </PersonalizarProcesso>
            </div>
        </v-container>
        <Snackbar :snackbar="snackbar"></Snackbar>
    </div>
</template>
<script>
import { processoMixin, filterMixin } from '../../mixins'
import VerModalProcesso from '../../components/Modais/VerModalProcesso.vue'
import PersonalizarProcesso from '../../components/PersonalizarProcesso.vue'
import Snackbar from '../../components/Snackbar.vue'

export default {
    name:'consultar-publica',
    mixins: [
        processoMixin,
        filterMixin
    ],
    components: {
        Snackbar,
        VerModalProcesso,
        PersonalizarProcesso
    },
    data: () => ({
        loadedProcessos: false,
        personalizarProcesso: {
            show: false,
            gerenciar: false,
            consultaPublica: true,
            id: null,
            docs: [],
            filename: null,
            numero: null,
            ano: null,
            nivel_id: null,
            enableEditor: null
        },
        verProcesso: {
            show: false,
            id: null,
            numero: null,
            ano: null,
            setor_id_origem: null,
            setor_id_atual: null,
            usuario_id: null,
            observacao: null,
            especificacao: null,
            processo_antigo: null,    
            tramitacoes: []
        },
        dadosProcesso: {
            show: false,
            id: null,
            ano: null,
            setor_id_origem: null,
            setor_id_destino: null,
            usuario_id: null,
            observacao: null
        },
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        loading: false,
        showTableProcessos: true,
        processos: [],
        documentos:[],
        headers: [
            { text: 'Processo', value: 'id', width: '12%' },
            { text: 'Setor atual', value: 'setorAtual', width: '20%' },
            { text: 'Tipo de processo', value: 'tipoProcesso', width: '25%' },
            { text: 'Assunto', value: 'assunto', width: '20%' },
            { text: 'Situação', value: 'situacao', width: '8%' },
            { text: 'Ações', value: 'actions', width: '15%', align: 'right' },
        ],
    }),
    watch: {
        tipoprocesso_id () {
            this.getProcessos()
        },
        assunto_id () {
            this.getProcessos()
        },
        nivel_id () {
            this.getProcessos()
        },
        situacao_id () {
            this.getProcessos()
        },
        interessado () {
            this.getProcessos()
        },
        ano () {
            this.getProcessos()
        },
    },
    methods: {
        applyInput (inputed) {
            if (inputed.ano) this.ano = inputed.ano
                else this.ano = null
            if (inputed.numero) this.numero = inputed.numero
                else this.numero = null
            if (inputed.processoAntigo) this.processoAntigo = inputed.processoAntigo
                else this.processoAntigo = null
            this.getProcessos()
        },
        getProcessos () {
            const filters = {
                'ano': this.ano,
                'numero': this.numero,
                'processo_antigo': this.processoAntigo,
                'tipoprocesso_id': this.tipoprocesso_id,
                'assunto_id': this.assunto_id,
                'nivel_id': this.nivel_id,
                'situacao_id': this.situacao_id,
                'interessado': this.interessado,
            }
            this.processos = []
            let filtros = 0;
            if (this.ano) filtros++
            if (this.numero) filtros++
            if (this.processoAntigo) filtros++
            if (this.tipoprocesso_id) filtros++
            if (this.assunto_id) filtros++
            if (this.nivel_id) filtros++
            if (this.situacao_id) filtros++
            if (this.interessado) filtros++
            
            this.loading = true
            this.showTableProcessos = true;
            this.personalizarProcesso.show = false;
            if (filtros >= 2) {
                this.axios.post(`${process.env.VUE_APP_PROCESSO_URL}consulta/${this.$router.currentRoute.query.idorganization}/consultar`, filters).then((res) => {
                    this.processos = res.data
                }).finally(() => {
                    this.loading = false
                    this.loadedProcessos = true
                })
            }else{
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: 'Informe ao menos 2 campos para realizar a pesquisa',
                    color: 'orange',
                }
                this.loading = false
                this.loadedProcessos = true
            }
        },
        getProcessoModal (id) {
            this.axios.get(`processo/processo/${id}`).then((res) => {
                const p = res.data[0]
                var origem
                var destino
                if (p.tramitacoes.length > 0) {
                    origem = p.tramitacoes[p.tramitacoes.length - 1].setor_id_origem
                    destino = p.tramitacoes[p.tramitacoes.length - 1].setor_id_destino
                } else {
                    origem = p.setor_id_origem
                    destino = p.setor_id_atual
                }
                this.dadosProcesso = {
                    ...this.dadosProcesso,
                    id: p.id,
                    numero: p.numero,
                    ano: p.ano,
                    setor_id_origem: origem,
                    setor_id_atual: destino,
                    usuario_id: p.usuario_id,
                    observacao: p.observacao,
                }
            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data[Object.keys(e.response.data)[0]][0],
                    color: 'red',
                }
            })
        },
        getDocs(item) {
            this.documentos = [];
            this.processo_doc = item
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}processo/${item.id}/documentos`).then((res) => {
                // filter documentos publicos, assinados e com arquivo em pdf
                for (var documentos = 0; documentos < res.data.length; documentos++) {
                    if(res.data[documentos].nivel_id == 1){
                        if(res.data[documentos].gerado == 0 || (res.data[documentos].gerado == 1 && res.data[documentos].filename !== null && res.data[documentos].assinado == 1)){
                            this.documentos.push ({
                                ...res.data[documentos]
                            })
                        }
                    }
                }
                
                this.personalizarProcesso.docs = []
                this.QtdDocumentos = 10;
                this.QtdAgrupamentos = parseInt(this.documentos.length/ this.QtdDocumentos); 
                
                if (this.QtdAgrupamentos > 0){
                    for (var p = 1; p <= this.QtdAgrupamentos; p++) {
                        let QuantidadeInicio
                        let QuantidadeFinal 
                        if(p == 1){
                            QuantidadeInicio = p
                        }else{
                            QuantidadeInicio = ((p-1) * this.QtdDocumentos) + 1    
                        }

                        QuantidadeFinal = (p * this.QtdDocumentos);
                        
                        this.personalizarProcesso.docs.push({
                            id: p,
                            nome: QuantidadeInicio +"-"+ QuantidadeFinal,
                            icon: 'mdi-folder',
                            children: []
                            },);
                    }    
                }
                
                let contadorPasta = 0;
                let contadorDoc = 0;
                for (var d = 0; d < this.documentos.length; d++) {
                    
                    let icon
                    if (this.documentos[d].excluido == 0){
                        if (this.documentos[d].gerado == 0)
                            icon = 'mdi-download'
                        else
                            if (this.documentos[d].assinado == 1)
                                icon = 'mdi-file-sign'
                            else
                                icon = 'mdi-file-document-edit'
                    } else
                        icon = 'mdi-file-remove'
                    
                    if(this.QtdAgrupamentos > 0 && contadorPasta != this.QtdAgrupamentos){
                         contadorDoc++;

                        this.personalizarProcesso.docs[contadorPasta].children.push({
                            id: this.documentos[d].id,                     // codigo do documento
                            processo_id: this.documentos[d].processo_id,   // codigo do processo
                            nome: this.documentos[d].numero_doc 
                                    + ' ' + this.documentos[d].tipo_documento.nome
                                    + ' (' + this.documentos[d].id + ')',
                            gerado: this.documentos[d].gerado,
                            excluido: this.documentos[d].excluido,
                            body: this.documentos[d].body,
                            titulo: this.documentos[d].titulo,
                            filename: this.documentos[d].filename,
                            icon: icon,
                            criar: 0,
                            upload: 0,
                            bloco_id: this.documentos[d].bloco_id,
                            tipodocumento_id:this.documentos[d].tipo_documento.id,    
                        })
                       
                        if(contadorDoc == this.QtdDocumentos){
                            contadorDoc = 0;
                            contadorPasta++;
                        }

                    }else{
                    
                        this.personalizarProcesso.docs.push({
                            id: this.documentos[d].id,                     // codigo do documento
                            processo_id: this.documentos[d].processo_id,   // codigo do processo
                            nome: this.documentos[d].numero_doc 
                                    + ' ' + this.documentos[d].tipo_documento.nome
                                    + ' (' + this.documentos[d].id + ')',
                            gerado: this.documentos[d].gerado,
                            excluido: this.documentos[d].excluido,
                            body: this.documentos[d].body,
                            titulo: this.documentos[d].titulo,
                            filename: this.documentos[d].filename,
                            icon: icon,
                            criar: 0,
                            upload: 0,
                            bloco_id: this.documentos[d].bloco_id,
                            tipodocumento_id:this.documentos[d].tipo_documento.id,                    
                        })
                    }
                }
                this.personalizarProcesso.id        = item.id
                this.personalizarProcesso.numero    = item.numero
                this.personalizarProcesso.ano       = item.ano
                this.personalizarProcesso.nivel_id  = item.nivel_id
                this.showTableProcessos             = false
            })
            this.personalizarProcesso.show          = true
            this.personalizarProcesso.enableEditor  = false
        },
        colorSituacao (value) {
            switch (value) {
                case 'ARQ':
                    return 'blue'
                case 'DEV':
                    return 'blue'
                case 'ENV':
                    return 'accent'
                case 'RAS':
                    return 'blue'
                case 'REC':
                    return 'green'
                case 'RES':
                    return 'blue'
            }
        },
        resetProcesso () {
            this.verProcesso = {
                ...this.verProcesso,
                id: null,
                ano: null,
                setor_id_origem: null,
                setor_id_atual: null,
                usuario_id: null,
                observacao: null,
                especificacao: null,
                processo_antigo: null,
                tramitacoes: []
            }
        }
    },
    mounted () {
        this.getProcessos()
    }
}
</script>