<template>
    <v-app class="login">
        <v-main>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="8">
                        <v-card class="elevation-12">
                            <v-window v-model="step">
                                <v-window-item :value="1">
                                    <v-row>
                                        <v-col cols="12" md="8">
                                            <v-card-text class="mt-12">
                                                <v-img
                                                    class="mb-4"
                                                    contain
                                                    height="80"
                                                    :src="require('../assets/logo_dark.png')"
                                                ></v-img>
                                                <h4 class="text-center mt-4">Informe seus dados cadastrais</h4>
                                                <v-form
                                                    ref="formLogin"
                                                    lazy-validation
                                                    v-model="formValid">
                                                    <v-text-field
                                                        class="mt-1"
                                                        label="Email"
                                                        name="Email"
                                                        required
                                                        :rules="[rules.required]"
                                                        counter
                                                        maxlength="50"
                                                        prepend-icon="email"
                                                        type="text"
                                                        color="accent"
                                                        v-model="email"
                                                    />
                                                    <v-text-field
                                                        id="password"
                                                        label="Senha"
                                                        name="password"
                                                        required
                                                        :rules="[rules.required]"
                                                        counter
                                                        maxlength="30"
                                                        prepend-icon="lock"
                                                        type="password"
                                                        color="accent"
                                                        v-model="password"
                                                    />
                                                </v-form>
                                                <!--<h4 class="text-center mt-2">Esqueci minha senha</h4>-->
                                            </v-card-text>
                                            <div class="text-center mb-6">
                                                <v-btn
                                                    rounded
                                                    color="accent"
                                                    dark
                                                    @click="[iniciarLogin()]">
                                                    Realizar login
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="4" class="accent">
                                            <v-card-text
                                                class="white--text"
                                                :class="{'mt-0': $vuetify.breakpoint.smAndDown, 'mt-12': $vuetify.breakpoint.mdAndUp}">
                                                <h1 class="text-center display-1">Bem-vindo!</h1>
                                                <h4 class="text-center mt-2">
                                                    Acompanhe o MobbyDocs nas redes sociais
                                                </h4>
                                            </v-card-text>
                                            <div class="text-center">
                                                <v-btn class="mx-2" fab color="white" outlined
                                                    href="http://www.facebook.com/appsmobby/" target="_blank">
                                                    <v-icon>fab fa-facebook-f</v-icon>
                                                </v-btn>
                                                <v-btn class="mx-2" fab color="white" outlined
                                                    href="http://www.instagram.com/mobbyapps/" target="_blank">
                                                    <v-icon>fab fa-instagram</v-icon>
                                                </v-btn>
                                                <v-btn class="mx-2" fab color="white" outlined
                                                    href="https://br.linkedin.com/company/grupogrt8" target="_blank">
                                                    <v-icon>fab fa-linkedin-in</v-icon>
                                                </v-btn>
                                            </div>
                                            <!--<div class="text-center mt-6 mb-6">
                                                <v-btn rounded outlined dark>Cadastre-se</v-btn>
                                            </div>-->
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-row>
                                        <v-col cols="12" md="4" class="accent">
                                            <v-card-text
                                                class="white--text"
                                                :class="{'mt-0': $vuetify.breakpoint.smAndDown, 'mt-12': $vuetify.breakpoint.mdAndUp}">
                                                <h1 class="text-center display-1">Bem-vindo ao nosso sistema!</h1>
                                                <h4 class="text-center mt-2">
                                                    Já possui uma conta conosco?
                                                </h4>
                                            </v-card-text>
                                            <div class="text-center">
                                                <v-btn rounded outlined dark @click="step--">Faça o login</v-btn>
                                            </div>
                                            <v-card-text
                                                class="white--text">
                                                <h4 class="text-center">
                                                    Fique por dentro de nossas redes sociais
                                                </h4>
                                            </v-card-text>
                                            <div class="text-center">
                                                <v-btn class="mx-2" fab color="white" outlined>
                                                    <v-icon>fab fa-facebook-f</v-icon>
                                                </v-btn>
                                                <v-btn class="mx-2" fab color="white" outlined>
                                                    <v-icon>fab fa-google-plus-g</v-icon>
                                                </v-btn>
                                                <v-btn class="mx-2" fab color="white" outlined>
                                                    <v-icon>fab fa-linkedin-in</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <v-card-text class="mt-12">
                                                <v-img
                                                    class="mb-4"
                                                    contain
                                                    height="80"
                                                    :src="require('../assets/logo_dark.png')"
                                                ></v-img>
                                                <h4 class="text-center mt-4">Informe seus dados para iniciar o cadastro</h4>
                                                <v-form>
                                                    <v-text-field
                                                        label="Email"
                                                        name="Email"
                                                        prepend-icon="person"
                                                        type="text"
                                                        color="teal accent-3"
                                                    />
                                                    <v-text-field
                                                        label="Senha"
                                                        name="Senha"
                                                        prepend-icon="lock"
                                                        type="password"
                                                        color="accent"
                                                    />
                                                </v-form>
                                                <h4 class="text-center mt-4">Embarque em nossos serviços</h4>
                                            </v-card-text>
                                            <div class="text-center mb-6">
                                                <v-btn rounded color="accent" dark>Iniciar cadastro</v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                                <v-window-item :value="3">
                                    <v-row>
                                        <v-col cols="12" md="8">
                                            <v-card-text class="mt-12">
                                                <v-img
                                                    class="mb-4"
                                                    contain
                                                    height="80"
                                                    :src="require('../assets/logo_dark.png')"
                                                ></v-img>
                                                <h4 class="text-center mt-4">Escolha a organização que você quer se logar</h4>
                                                <v-form>
                                                    <v-autocomplete
                                                        class="mt-4"
                                                        label="Organização"
                                                        v-model="organizacao_id"
                                                        :items="organizacoes"
                                                        item-value="id"
                                                        item-text="name"
                                                        @change="getOrgName"
                                                        return-object
                                                    />
                                                </v-form>
                                            </v-card-text>
                                            <div class="text-center mb-6">
                                                <v-btn
                                                    :disabled="organizacao_id==null"
                                                    rounded
                                                    color="accent"
                                                    @click="[login()]">
                                                    Selecionar organização
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="4" class="accent">
                                            <v-card-text
                                                class="white--text"
                                                :class="{'mt-0': $vuetify.breakpoint.smAndDown, 'mt-12': $vuetify.breakpoint.mdAndUp}">
                                                <h1 class="text-center display-1">Bem-vindo!</h1>
                                                <h4 class="text-center mt-2">
                                                    Acompanhe o MobbyDocs nas redes sociais
                                                </h4>
                                            </v-card-text>
                                            <div class="text-center">
                                                <v-btn class="mx-2" fab color="white" outlined
                                                    href="http://www.facebook.com/appsmobby/" target="_blank">
                                                    <v-icon>fab fa-facebook-f</v-icon>
                                                </v-btn>
                                                <v-btn class="mx-2" fab color="white" outlined
                                                    href="http://www.instagram.com/mobbyapps/" target="_blank">
                                                    <v-icon>fab fa-instagram</v-icon>
                                                </v-btn>
                                                <v-btn class="mx-2" fab color="white" outlined
                                                    href="https://br.linkedin.com/company/grupogrt8" target="_blank">
                                                    <v-icon>fab fa-linkedin-in</v-icon>
                                                </v-btn>
                                            </div>
                                            <!--<div class="text-center mt-6 mb-6">
                                                <v-btn rounded outlined dark>Cadastre-se</v-btn>
                                            </div>-->
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                            </v-window>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </v-main>
    </v-app>
</template>
<script>
import jwt_decode from "jwt-decode";
import Snackbar from '../components/Snackbar.vue'
export default {
    name:'login',
    components: {
        Snackbar,
    },
    data: () => ({
        step: 1,
        email: null,
        password: null,
        loading: false,
        organizacao: null,
        organizacao_id: null,
        organizacoes: [],
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
    }),
    methods: {
        iniciarLogin () {
            if (!this.$refs.formLogin.validate())
                return false
            localStorage.clear()
            this.axios.post('gerencial/auth/login', {
                email: this.email,
                password: this.password
            }).then((res) => {
                localStorage.setItem('temporaryToken', res.data.token)
                localStorage.setItem('usuario', JSON.stringify(res.data.data.user))
                this.organizacoes = res.data.data.organizations
                if(this.organizacoes.length == 1){
                    this.getOrgName(this.organizacoes[0])
                    this.login()
                }else{
                    this.step = 3
                }

            }).catch(e => {
                this.snackbar = {
                    ...this.snackbar,
                    show: true,
                    text: e.response.data.message[0].error[0],
                    color: 'red',
                }
            }).finally(() => {
            })
        },
        getOrgName (item) {
            this.organizacao = item
        },
        login () {
            this.axios.post('gerencial/auth', {
                idOrganization: this.organizacao.id,
                token: localStorage.getItem('temporaryToken')
            }).then((res) => {
                localStorage.setItem('expToken', jwt_decode(res.data.token).exp)
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('setores', JSON.stringify(res.data.data.user.sectors))
                localStorage.setItem('organizacao', JSON.stringify(this.organizacao))
                this.$store.commit('DEFINIR_USUARIO_LOGADO', {
                    token: localStorage.getItem('token'),
                    organizacao: JSON.parse(localStorage.getItem('organizacao')),
                    usuario: JSON.parse(localStorage.getItem('usuario')),
                    setores: JSON.parse(localStorage.getItem('setores')),
                    expToken: localStorage.getItem('expToken')
                })
                this.$nextTick(() => {
                    this.$router.push({ name: 'dashboard' })
                })
                // location.href = '/dashboard'
                localStorage.removeItem('temporaryToken')
            }).catch(e => {
            this.snackbar = {
                ...this.snackbar,
                show: true,
                text: e.response.data.message,
                color: 'red',
            }
            }).finally(() => {
            })
        },
    },
    mounted () {
        this.$refs.formLogin.validate()
    }
};
</script>
<style>
	@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
	.v-application {
        font-family: Inter !important;
	}
</style>