<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div>
            <v-dialog width="40%" v-model="processo.show" :fullscreen="$vuetify.breakpoint.xsOnly">
                <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                        Enviar processo {{ processo.numero }}/{{ processo.ano }}
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            @click="[processo.show = false]"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>

                        <v-row class="mt-2">
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >
                                <v-autocomplete
                                    label="Selecione a unidade de destino"
                                    v-model="processo.unidades"
                                    :items="unidades"
                                    item-value="id"
                                    item-text="name"
                                    @change="[getSetoresUnit($event)]"
                                    required
                                    :rules="[rules.required]"    
                                />

                            </v-col>
                            
                        </v-row>
                        
                        <v-row class="mt-2">
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >
                                <v-autocomplete
                                    label="Selecione o setor de destino"
                                    v-model="processo.setor_id_destino"
                                    :items="setores"
                                    item-value="id"
                                    item-text="name"
                                    @change="[getUsuariosSetor($event)]"
                                    required
                                    :rules="[rules.required]"    
                                />

                            </v-col>
                            
                        </v-row>

                        <v-row class="mt-2">
                            
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >
                                <v-text-field
                                    v-model="processo.observacao"
                                    label="Observação"
                                ></v-text-field>
                            </v-col>

                        </v-row>

                        <v-row class="mt-2">
                            <v-col
                                cols="12"
                                sm="12"
                                xs="12"
                            >
                                <v-autocomplete
                                    label="Usuário a ser atribuído no setor destino"
                                    v-model="processo.usuario_id_sugestao"
                                    :items="usuarios"
                                    item-value="id"
                                    item-text="name"
                                />

                            </v-col>
                            
                        </v-row>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="accent"
                            dense
                            @click="[enviarProcesso()]"
                        >
                            Enviar processo
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-form>
</template>
<script>
    export default{
    name: 'enviar-processo',
        props:{
            processo:{
                id: Number,
                numero: Number,
                setor_id_origem: String,
                setor_id_destino: String,
                organizacao: String,
                usuario_id: String,
                usuario_id_sugestao: String, 
                observacao: String,
                show: Boolean,
                unidades: Array,
                setores: Array,
                usuarios: Array
            },
        },
        data: () => ({
            formValid: false,
            rules: {
                required: value => !!value || 'O preenchimento é obrigatório',
            },
            unidades: [],
            setores: [],
            usuarios: [],
        }),
        methods: {
            enviarProcesso () {
                if (!this.$refs.formDados.validate())
                    return false
                this.$emit('processo', {
                    processo: this.processo,
                })
                this.processo.show = false
            },
            getUnidades () {
                this.unidades = []
                this.setores = []
                this.usuarios = []
                this.usuario_id_sugestao = null
                this.axios.get('gerencial/auth/units').then((res) => {
                    for (var u = 0; u < res.data.length; u++){
                        var unidade   = res.data[u]
                        if (unidade.active === true)
                            this.unidades.push(unidade)
                    }
                }).finally(() => {
                })
            },
            getSetoresUnit(idunidade){
                this.setores = []
                this.usuarios = []
                this.axios.get(`gerencial/auth/sectors?idunit=${idunidade}`).then((res) => {
                    for (var s = 0; s < res.data.length; s++){
                        var setor   = res.data[s]
                        if (setor.active === true && setor.id != this.processo.setor_id_atual)
                            this.setores.push(setor)
                    }
                }).finally(() => {
                })
            },
            getUsuariosSetor(idsetor){
                this.usuarios = []
                this.axios.get(`gerencial/users/sector/${idsetor}`).then((res) => {
                    const usuarios = res.data; 
                    for(var j = 0 ; j < usuarios.length; j++){
                        if (usuarios[j].active)
                            this.usuarios.push(usuarios[j]);
                    }
                }).finally(() => {
                })
            }
        },
        mounted () {
            this.getUnidades()
            this.$refs.formDados.validate()
        }
    }
</script>