<template>
    <v-form ref="formDados"
        lazy-validation
        v-model="formValid">
        <div class="page">
            <v-container fluid>
                <v-row class="mb-2">
                    <v-col xs="12" sm="6" md="7" lg="8" xl="9">
                        <h2 class="mb-0 accent--text">Solicitações de acessos externos</h2>
                    </v-col>
                </v-row>
                <v-row class="mb-2">
                    <v-col xs="10" sm="10" md="10" lg="10" xl="10" class="justify-end content">
                        <v-text-field 
                            v-model="search" 
                            prepend-icon="mdi-feature-search-outline" 
                            label="Pesquisar" 
                            clearable>
                        </v-text-field>
                    </v-col>
                    <v-col xs="2" sm="2" md="2" lg="2" xl="2" class="justify-end content">
                        <v-switch
                            v-model="mostrarTodos"
                            label="Mostrar todos"
                            color="primary"
                            @click="[getSolicitacoes(mostrarTodos)]"
                            flat>
                        </v-switch>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="solicitacoes"
                    :search="search"
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Carregando..."
                >
                    <template v-slot:[`item.analise`]="{ item }">
                        <v-chip v-if="item.analise == 0" class="red" dark>Indeferido</v-chip>
                        <v-chip v-else-if="item.analise == 1" class="green" dark>Deferido</v-chip>
                        <v-chip v-else class="orange" dark>Em análise</v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item}">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="item.analise == null"
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    @click="[getModal(item, 1), dadosAnalise.show = true]"
                                >
                                    <v-icon color="green">mdi-check</v-icon>
                                </v-btn>
                            </template>
                            <span>Deferir solicitação</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="item.analise == null"
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    :disabled="item.analise"
                                    @click="[getModal(item, 0), dadosAnalise.show = true]"
                                >
                                    <v-icon color="red">mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Indeferir solicitação</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <AnalisarSolicitacaoModalProcesso :analise="dadosAnalise" @registrarAnalise="registrarAnalise"></AnalisarSolicitacaoModalProcesso>
            </v-container>
            <Snackbar :snackbar="snackbar"></Snackbar>
        </div>
    </v-form>
</template>
<script>
import AnalisarSolicitacaoModalProcesso from '../../components/Modais/AnalisarSolicitacaoModalProcesso.vue'
import Snackbar from '../../components/Snackbar.vue'
export default {
    name:'Usuario',
    components: {
        Snackbar,
        AnalisarSolicitacaoModalProcesso
    },
    data: () => ({
        snackbar: {
            show : false,
            timeout: 6000,
            align: 'right'
        },
        mostrarTodos: false,
        loading: false,
        search: '',
        registro: {
            id: null,
            name: null,
            email: null
        },
        dadosAnalise: {
            show: false,
            acao: null,
            id: null,
            cpf: null,
            nome: null,
            usuario_id: null,
            observacao: null
        },
        headers: [
            { text: 'CPF', value: 'cpf', width: '15%' },
            { text: 'Nome', value: 'nome' },
            { text: 'E-mail', value: 'email' },
            { text: 'Telefone', value: 'telefone' },
            { text: 'Situação', value: 'analise' },
            { text: 'Ações', value: 'actions', width: '10%', align: 'right' },
        ],
        formValid: false,
        rules: {
            required: value => !!value || 'O preenchimento é obrigatório',
        },
        solicitacoes: [],
    }),
    methods: {
        getSolicitacoes (mostrarTodos = false) {
            this.loading = true
            this.$axios.get(`processo/solicitacaoacesso/${this.$store.state.organizacao.id}`, {
                params: { 'all': mostrarTodos == false ? 0 : 1 }
                }).then((res) => {            
                this.solicitacoes = res.data                        
            }).finally(() => {
                this.loading = false
            })
        },
        getModal (registro, acao) {
            this.dadosAnalise = {
                ...this.dadosAnalise,
                id: registro.id,
                acao: acao,
                cpf: registro.cpf,
                nome: registro.nome,
                usuario_id: this.$store.state.usuario.id,
                observacao: null,
            }
        },
        registrarAnalise () {
            if(this.dadosAnalise.acao == 1){
                this.axios.patch(`processo/solicitacaoacesso/${this.dadosAnalise.id}`, this.dadosAnalise).then((res) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: res.data.message,
                        color: 'green'
                    }
                    this.getSolicitacoes()
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    }
                })
            }else{
                this.axios.put(`processo/solicitacaoacesso/${this.dadosAnalise.id}`, this.dadosAnalise).then((res) => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: res.data.message,
                        color: 'green'
                    }
                    this.getSolicitacoes()
                }).catch(e => {
                    this.snackbar = {
                        ...this.snackbar,
                        show: true,
                        text: e.response.data[Object.keys(e.response.data)[0]][0],
                        color: 'red',
                    }
                })
            }
        },
        zerarCampos () {
            this.registro.id            = null
            this.registro.name          = null
            this.registro.email         = null
        },
    },
    mounted () {
        this.getSolicitacoes()
        this.$refs.formDados.validate()
    }
}
</script>