import MeusProcessos    from '../views/processos/MeusProcessos.vue'
import ReceberProcesso  from '../views/processos/ReceberProcesso.vue'
import EnviarProcesso   from '../views/processos/EnviarProcesso.vue'
import RelacionarProcesso from '../views/processos/RelacionarProcesso.vue'
import JuntarProcesso from '../views/processos/JuntarProcesso.vue'
import ArquivosSetor    from '../views/processos/ArquivosSetor.vue'
import TramitacaoRecebimentoPendente from '../views/processos/TramitacaoRecebimentoPendente.vue'
import ConsultarProcessos from '../views/processos/ConsultarProcesso.vue'
import ConsultarDocumentos from '../views/processos/ConsultarDocumento.vue'
import Notificacoes   from '../views/processos/Notificacoes.vue'

const routes = [
    {
        path: '/processos/rascunhos',
        name: 'meusprocessos',
        component: MeusProcessos
    },
    {
        path: '/processos/receber',
        name: 'receberprocesso',
        component: ReceberProcesso
    },
    {
        path: '/processos/enviar',
        name: 'enviarprocesso',
        component: EnviarProcesso
    },
    {
        path: '/processos/relacionar',
        name: 'relacionarprocesso',
        component: RelacionarProcesso
    },
    {
        path: '/processos/juntar',
        name: 'juntarprocesso',
        component: JuntarProcesso
    },
    {
        path: '/processos/arquivos',
        name: 'arquivossetor',
        component: ArquivosSetor
    },
    {
        path: '/processos/recebimento/pendente',
        name: 'tramitacaorecebimentopendente',
        component: TramitacaoRecebimentoPendente
    },
    {
        path: '/processos/consultar',
        name: 'consultarprocessos',
        component: ConsultarProcessos
    },
    {
        path: '/processos/documentos',
        name: 'consultardocumentos',
        component: ConsultarDocumentos
    },
    {
        path: '/processos/notificacoes',
        name: 'notificacoes',
        component: Notificacoes
    },
]

export default routes